import React from 'react';

export function itemRender(current, type, originalElement) {
  if (type === "prev") {
    return <a style={{ padding: '0 10px' }} className='d-flex align-items-center'><i className='fa fa-angle-left mr-2'></i> Back</a>;
  }
  if (type === "next") {
    return <a style={{ padding: '0 10px' }} className='d-flex align-items-center'>Next <i className='fa fa-angle-right ml-2'></i></a>;
  }
  return originalElement;
}

export function onShowSizeChange(current, pageSize) {

}