import axios from "axios";
import { ERROR_MESSAGE, REGEX } from "./ConstatntData";

const ALPHABET = `ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789#@+_`;
const prefix = "alchemy";
const secret = "faq#umfcny@ekdgy+ri_osdu";

let resetTimeout;

const leftTrim = (string) => {
  return (string ?? "").toString().trimStart();
};

const trim = (string) => {
  return (string ?? "").trim();
};

const setItem = (keyName, data, isEncriptionRequired = true) => {
  localStorage.setItem(
    `${prefix}-${keyName}`,
    isEncriptionRequired ? encrypt(JSON.stringify(data)) : data
  );
};

const getItem = (keyName, isEncriptionRequired = true) => {
  try {
    const data = localStorage.getItem(`${prefix}-${keyName}`);
    return data
      ? isEncriptionRequired
        ? JSON.parse(decrypt(data))
        : data
      : "";
  } catch (error) {
    localStorage.setItem("error_with", JSON.stringify(error));
  }
};

const getItemFromCookie = (keyName) => {
  try {
    const name = `${prefix}-${keyName}=`;
    const allData = decodeURIComponent(document.cookie);
    const values = allData.split("; ");
    let res;
    values.forEach((val) => {
      if (val.indexOf(name) === 0) res = val.substring(name.length);
    });
    return res ? JSON.parse(decrypt(res)) : "";
  } catch (error) {
    localStorage.setItem("error_with_cookie", JSON.stringify(error));
  }
};

const setItemToCookie = (keyName, data, date) => {
  document.cookie = `${prefix}-${keyName}=${encrypt(JSON.stringify(data))};${date ? `expires=${date}` : ""
    } ;path=/`;
};

const deleteItem = (keyName) => {
  localStorage.removeItem(`${prefix}-${keyName}`);
};

const clearAllItem = (isDecryptionError = false) => {
  var keys = Object.keys(localStorage),
    i = keys.length;
  while (i--) {
    if (
      keys[i].startsWith(prefix) &&
      (keys[i] !== `${prefix}-rememberMe` ||
        (isDecryptionError && keys[i] === `${prefix}-rememberMe`))
    )
      localStorage.removeItem(keys[i]);
  }
};

function encrypt(plaintext) {
  let cipherText = "";
  let j = 0;
  for (let i = 0; i < plaintext.length; i++) {
    const inputCharacter = plaintext[i];
    const inputCharacterIndex = ALPHABET.indexOf(inputCharacter);
    if (inputCharacterIndex === -1) {
      cipherText += inputCharacter;
      continue;
    }
    const keywordCharacter = secret[j];
    const keywordCharacterIndex = ALPHABET.indexOf(keywordCharacter);
    const indicesSum = inputCharacterIndex + keywordCharacterIndex;
    const cipherCharacterIndex = indicesSum % 66;
    const cipherTextCharacter = ALPHABET[cipherCharacterIndex];
    j = j === secret.length - 1 ? 0 : j + 1;
    cipherText += cipherTextCharacter;
  }
  return cipherText;
}

const decrypt = (ciphertext) => {
  let plaintext = "";
  let j = 0;
  for (let i = 0; i < ciphertext.length; i++) {
    const ciphertextCharacter = ciphertext[i];
    const ciphertextCharacterIndex = ALPHABET.indexOf(ciphertextCharacter);
    if (ciphertextCharacterIndex === -1) {
      plaintext += ciphertextCharacter;
      continue;
    }
    const keyCharacter = secret[j];
    const keyCharacterIndex = ALPHABET.indexOf(keyCharacter);
    const indicesSum = ciphertextCharacterIndex - keyCharacterIndex + 66;
    const cipherCharacterIndex = indicesSum % 66;
    const cipherCharacter = ALPHABET[cipherCharacterIndex];
    j = j === secret.length - 1 ? 0 : j + 1;
    plaintext += cipherCharacter;
  }
  return plaintext;
};

const markAllAsTouched = (object) => {
  return Object.keys(object).reduce((accumulator, key) => {
    return { ...accumulator, [key]: true };
  }, {});
};

const leftTrimAllValue = (object, exceptLeftTrimValues = []) => {
  if (object) {
    return Object?.keys(object).reduce((accumulator, key) => {
      return {
        ...accumulator,
        [key]: exceptLeftTrimValues.find((element) => element === key)
          ? object[key]
          : leftTrim(object[key]),
      };
    }, {});
  } else return null;
};

const isFormValid = (formValue, optionalFields) => {
  for (var key in formValue) {
    if (formValue[key] === null) {
      formValue[key] = "";
    }
    if (formValue[key] && formValue[key].toString().match(REGEX[key])) continue;
    else if (
      optionalFields &&
      optionalFields[key] &&
      formValue[key].toString().match(REGEX[key])
    ) {
      continue;
    } else return false;
  }
  return true;
};

const getErrorMessage = (key, value, isOptional) => {
  if (
    (value && value?.toString().match(REGEX[key])) ||
    (isOptional && (!value || (value && value?.toString().match(REGEX[key]))))
  )
    return "";
  else {
    return !value
      ? `${ERROR_MESSAGE[key + "Required"] ?? "This field is required"}`
      : `${ERROR_MESSAGE[key + "Invalid"]}`;
  }
};

// const showError = (message) => {
//   renderSnackbar(message, "snk-red", "icon-warning");
// };

// const showSuccess = (message) => {
//   renderSnackbar(message, "snk-green", "icon-checkBox");
// };

// const renderSnackbar = (message, className, iconName) => {
//   if (resetTimeout) {
//     clearTimeout(resetTimeout);
//   }
//   const snackbar = document.getElementById("snackbar");
//   const snackbarText = document.getElementById("snackbar-message");
//   const snackbarIcon = document.getElementById("snackbar-icon");
//   if (snackbar && snackbarText) {
//     snackbar.classList = `snackbox ${className}`;
//     snackbarIcon.classList = iconName;
//     snackbarText.innerText = message;
//   }
//   resetTimeout = setTimeout(() => {
//     snackbar.classList.add("d-none");
//   }, 5000);
// };

const hasAccess = (pageName, elementId) => {
  let allAccessOfThePage = getItemFromCookie(pageName);
  return allAccessOfThePage
    ? allAccessOfThePage.find(
      (element) => element.toString() === elementId.toString()
    )
    : false;
};

const getDate = (date) => {
  if (!date) return null;
  date = new Date(date);
  const finalDate = date?.getDate().toString().padStart(2, 0);
  const finalMonth = (date?.getMonth() + 1).toString().padStart(2, 0);
  const finalYear = date?.getFullYear();
  return `${finalDate}-${finalMonth}-${finalYear}`;
};

const getYMDDate = (date) => {
  if (!date) return null;
  date = new Date(date);
  const finalDate = date?.getDate().toString().padStart(2, 0);
  const finalMonth = (date?.getMonth() + 1).toString().padStart(2, 0);
  const finalYear = date?.getFullYear();
  return `${finalYear}-${finalMonth}-${finalDate}`;
};

const getMDYDate = (date) => {
  if (!date) return null;
  date = new Date(date);
  const finalDate = date?.getDate().toString().padStart(2, 0);
  const finalMonth = (date?.getMonth() + 1).toString().padStart(2, 0);
  const finalYear = date?.getFullYear();
  return `${finalMonth}-${finalDate}-${finalYear}`;
};

const getMDYDateTime = (date) => {
  if (!date) return null;
  date = new Date(date);
  const hour = date?.getHours().toString().padStart(2, 0);
  const minute = date?.getMinutes().toString().padStart(2, 0);
  return `${getMDYDate(date)} ${hour}:${minute}`;
};

const getNumberFromString = (value) => {
  const data = value.match(/^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/);
  return data?.length ? data[0] : "";
};

const getFileUrl = (url) => {
  return `${process.env.REACT_APP_BUCKET_BASE_URL || ""}${url}${process.env.REACT_APP_BUCKET_END_URL || ""
    }`;
};

const downloadFile = (url) => {
  if (url) {
    axios
      .get(url, { responseType: "blob" })
      .then((response) => {
        const url = window.URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", new Date().getTime());
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((error) => {
       // showError(ERROR_MESSAGE.somethingWentWrong);
      });
  }
};

// const backToPreviousPage = () => {
//   navigate(-1);
// };

export {
  leftTrim,
  trim,
  encrypt,
  decrypt,
  setItem,
  getItem,
  deleteItem,
  getItemFromCookie,
  setItemToCookie,
  clearAllItem,
  markAllAsTouched,
  leftTrimAllValue,
  isFormValid,
  //showError,
  //showSuccess,
  getErrorMessage,
  hasAccess,
  getDate,
  getYMDDate,
  getNumberFromString,
  getMDYDate,
  getMDYDateTime,
  getFileUrl,
  downloadFile,
};
