
import logol from "./assets/Images/Swagatham1.png";
import BannerImage from "./assets/Images/landing/landing1.svg"
import LandingImage1 from "./assets/Images/landing/landing2.svg"
import LandingImage2 from "./assets/Images/landing/landing3.svg"
import LandingImage3 from "./assets/Images/landing/landing4.svg"
import LandingImage4 from "./assets/Images/landing/landing5.svg"
import LandingImage5 from "./assets/Images/landing/landing6.svg"
import LandingImage6 from "./assets/Images/landing/landing7.svg"
import LandingImage7 from "./assets/Images/landing/landing8.svg"

export const ImagePath = {
logo:logol,
BannerImage:BannerImage,
LandingImage1:LandingImage1,
LandingImage2:LandingImage2,
LandingImage3:LandingImage3,
LandingImage4:LandingImage4,
LandingImage5:LandingImage5,
LandingImage6:LandingImage6,
LandingImage7:LandingImage7,
}