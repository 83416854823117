import { lazy, Suspense, useState, useEffect } from "react";
import "./assets/css/font-awesome.min.css";
import "./assets/css/line-awesome.min.css";
import "./assets/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/select2.min.css";
import "./assets/plugins/bootstrap-tagsinput/bootstrap-tagsinput.css";
import "./assets/css/style.css";
import "./assets/css/bodyStyle.css";
import "./assets/css/sidebarStyle.css";
import "./assets/css/headerStyle.css";
import "./assets/css/ico.css";
//import "./assets/js/bootstrap-datetimepicker.min.js"

import authContext from "./helper/AuthContext";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Companies from "./Pages/Companies";
import Subscription from "./Pages/Subscription";
import Invoice from "./Pages/Invoice";
import ProductCatalogue from "./Pages/ProductCatalogue";
import Plans from "./Pages/Plans";
import Lead from "./Pages/Leads";
import CompanyAddEdit from "./Pages/CompanyAddEdit";
import SubscriptionAddEdit from "./Pages/SubscriptionAddEdit";
import InvoiceAddEdit from "./Pages/InvoiceAddEdit";
import ProductAddEdit from "./Pages/ProductAddEdit";
import PlanAddEdit from "./Pages/PlanAddEdit";
import CompanyInfo from "./Pages/CompanyInfo";
import ProductInfo from "./Pages/ProductInfo";
import PlanInfo from "./Pages/PlanInfo";
import CompanyInvoice from "./Pages/CompanyInvoice";
import PageNotFound from "./components/PageNotFound";
import Dashboard from "./Pages/Dashboard";
import LandingPage_v2 from "./Pages/LandingPage";
import LandingPage from "./Pages/LandingPageOld";
import InvoiceInfo from "./Pages/InvoiceInfo";




import Auth from "./helper/Auth";
const Login = lazy(() => import("./Pages/LoginPage"));
function App() {
  const [authenticated, setAuthenticated] = useState(
    localStorage.getItem("access")
  );
  useEffect(() => {
    setAuthenticated(localStorage.getItem("access"));
  }, [authenticated]);

  return (
    <authContext.Provider
      value={{
        authenticated,
        setAuthenticated,
      }}
    >
      <Router>
        <Suspense fallback={<></>}>
          <>
            <Routes>
              <Route
                path="/"
                element={<LandingPage_v2 />}
              />
              <Route
                path="/landing"
                element={<LandingPage_v2 />}
              />
              <Route exact path="/superadminlogin" element={<Login />} />

              <Route path="/" element={<Companies />} />
              <Route path="/company" element={<Auth Component={Companies} />} />
              <Route
                path="/company-add"
                element={<Auth Component={CompanyAddEdit} />}
              />
              <Route
                path="/company-edit/:CompanyId"
                element={<Auth Component={CompanyAddEdit} />}
              />
               <Route
                path="/company-lead-add/:LeadId"
                element={<Auth Component={CompanyAddEdit} />}
              />

              <Route
                path="/subscription"
                element={<Auth Component={Subscription} />}
              />
              <Route
                path="/subscription-add"
                element={<Auth Component={SubscriptionAddEdit} />}
              />
              <Route
                path="/subscription-edit/:subscriptionId"
                element={<Auth Component={SubscriptionAddEdit} />}
              />

              <Route path="/invoices" element={<Auth Component={Invoice} />} />
              <Route path="/invoiceInfo/:InvoiceId" element={<Auth Component={InvoiceInfo} />} />

              <Route
                path="/invoices-add-edit"
                element={<Auth Component={InvoiceAddEdit} />}
              />

              <Route
                path="/product"
                element={<Auth Component={ProductCatalogue} />}
              />
              <Route
                path="/product-add"
                element={<Auth Component={ProductAddEdit} />}
              />
              <Route
                path="/product-edit/:ProductId"
                element={<Auth Component={ProductAddEdit} />}
              />

              <Route path="/plan" element={<Auth Component={Plans} />} />
              <Route path="/lead" element={<Auth Component={Lead} />} />
              <Route
                path="/plan-add"
                element={<Auth Component={PlanAddEdit} />}
              />
              <Route
                path="/plan-edit/:PlanId"
                element={<Auth Component={PlanAddEdit} />}
              />

              <Route
                path="/companyInfo/:CompanyId"
                element={<Auth Component={CompanyInfo} />}
              />
              <Route
                path="/companyInvoice/:CompanyId"
                element={<Auth Component={CompanyInvoice} />}
              />
              <Route
                path="/productInfo/:ProductId"
                element={<Auth Component={ProductInfo} />}
              />
              <Route
                path="/planInfo/:PlanId"
                element={<Auth Component={PlanInfo} />}
              />

              <Route
                path="/page-not-found"
                element={<Auth Component={PageNotFound} />}
              />
              <Route
                path="/dashboard"
                element={<Dashboard />}
              />

            </Routes>
          </>
        </Suspense>
      </Router>
    </authContext.Provider>
  );
}

export default App;
