import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import profilepic from "../assets/Images/avatar-21.jpg";

const Header = () => {
  const navigate = useNavigate();
  const [rightMenu, setRightMenu] = useState(false);
  const [name, setName] = useState("");
  const [userImage, setUserImage] = useState("");

  const Logout = () => {
    localStorage.clear();
    navigate("/superadminlogin");
  };
  const rightClickMenu = () => {
    !rightMenu ? setRightMenu(true) : setRightMenu(false);
  };
  return (
    <div className="header super-admin-header ">
      <a id="mobile_btn" className="mobile_btn" href="#sidebar">
        <i className="fa fa-bars" />
      </a>
      <ul className="nav user-menu">
        <li className="nav-item dropdown"></li>
        <li className="nav-item dropdown has-arrow main-drop">
          <a
            className="dropdown-toggle nav-link d-flex align-items-center"
            data-toggle="dropdown"
          >
            <figure className="admin-user-icon">
              {userImage ? (
                <img src={profilepic} alt="user-img" />
              ) : (
                <span>S</span>
              )}
            </figure>
            <span onClick={rightClickMenu} className="s-admin-name">
              {name ? <span>{name}</span> : <span>Super-Admin</span>} &nbsp;
              <i className="fa fa-caret-down" aria-hidden="true"></i>
            </span>
          </a>
          <div
            className="dropdown-menu dropdown-menu-right"
            style={rightMenu ? { display: "block" } : { display: "none" }}
          >
            <a className="dropdown-item" onClick={Logout}>
              <i className="fa fa-sign-out"></i> Log out
            </a>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default Header;
