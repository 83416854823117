import React, { useState, useEffect } from "react";
import { api } from "../helper/Api";
import { Link, useNavigate } from "react-router-dom";
import DefaultPage from "./DefaultPage";
import Swal from "sweetalert2";
import {
  ActiveButton,
  DeleteButton,
  DeleteModal,
  EditButton,
  InactiveButton,
  ListingCardHeader,
  ListingTable,
  SearchBox,
} from "../components/UtilsAndModal";
import Loader from "../components/loader";
import { Toaster, toast } from "react-hot-toast";
let timeOut;
const Plans = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  const [ButtonLoader, setButtonLoader] = useState(false);
  const [listingSearchValue, setListingSearchValue] = useState("");
  const [planData, setPlanData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState({ deleteModal: false });

  useEffect(() => {
    getPlanList();
  }, [listingSearchValue]);

  const Logout = () => {
    localStorage.clear();
    navigate("/superadminlogin");
  };

  const getPlanList = () => {
    api
      .get(`/superadmin/plan/listing/?plan_id=0&search=${listingSearchValue}`)
      .then((response) => {
        if (response.status_code === 200) {
          setPlanData(response.data);
          if (response.data.length === 0) {
            setLoader(false);
          }
        } else {
          Swal.fire({
            position: "top-end",
            title: response.message ? response.message : "Somthing went wrong",
            showConfirmButton: false,
            backdrop: false,
            timer: 5000,
          });

          Logout();
        }
      })
      .catch((error) => {
        setLoader(false);
        Swal.fire({
          position: "top-end",
          title: `Error: ${error}`,
          showConfirmButton: false,
          backdrop: false,
          timer: 5000,
        });

        Logout();
      });
  };

  const columns = [
    {
      title: "Status",

      render: (a) => (
        <>
          <span
            className={`status-label ${
              a.plan_status === "A" ? "active" : "inactive"
            }`}
          >
            {a.plan_status === "A" ? "Active" : "Inactive"}{" "}
          </span>
        </>
      ),
    },
    {
      title: "Product Family",

      render: (record) => (
        <div>
          <Link
            className={`text-nowrap`}
            to={`/planInfo/${record.plan_price_map_id}`}
          >
            {record.family_name}{" "}
          </Link>
        </div>
      ),
    },

    {
      title: "Plan external Name",
      render: (a) => (
        <>
          <span className={`text-nowrap`}>{a.external_name}</span>
        </>
      ),
    },

    {
      render: (a) => (
        <>
          <span className={`text-nowrap`}>{a.internal_name}</span>
        </>
      ),

      title: (titleProps) => {
        const sortedColumn = titleProps.sortColumns?.find(
          ({ column }) => column.key === "name"
        );

        return (
          <div className="d-flex table-filter">
            <span>Plan Internal Name</span>
            <div className="filter-arrow">
              {sortedColumn?.order === "ascend" ? (
                <i className="la la-arrow-up"></i>
              ) : (
                <i className="la la-arrow-down"></i>
              )}
            </div>
          </div>
        );
      },
    },
    {
      title: "Pricing Model ",
      render: (a) => (
        <>
          <span className={`text-nowrap`}>{a.pricing_module}</span>
        </>
      ),
    },
    {
      render: (a) => (
        <>
          <span className={`text-nowrap`}>₹ {a.price}</span>
        </>
      ),
      title: (titleProps) => {
        const sortedColumn = titleProps.sortColumns?.find(
          ({ column }) => column.key === "name"
        );

        return (
          <div className="d-flex table-filter">
            <span>Price</span>
            <div className="filter-arrow">
              {sortedColumn?.order === "ascend" ? (
                <i className="la la-arrow-up"></i>
              ) : (
                <i className="la la-arrow-down"></i>
              )}
            </div>
          </div>
        );
      },
    },
    {
      title: "Billing Cycle ",
      render: (a) => (
        <>
          <span className={`text-nowrap`}>{a.billing_cycle}</span>
        </>
      ),
    },
    {
      title: "Frequency ",
      render: (a) => (
        <>
          <span className={`text-nowrap`}>{a.frequency}</span>
        </>
      ),
    },
    // {
    //   title: "Description ",
    //   render: (a) => (
    //     <>
    //       <span
    //         className={`text-nowrap`}
    //         dangerouslySetInnerHTML={{__html: a.plan_description}}
    //       >

    //       </span>
    //     </>
    //   ),

    // },
    {
      render: (a) => (
        <>
          <span className={`text-nowrap`}>{a.created_date}</span>
        </>
      ),
      title: (titleProps) => {
        const sortedColumn = titleProps.sortColumns?.find(
          ({ column }) => column.key === "name"
        );

        return (
          <div className="d-flex table-filter">
            <span>Created On</span>
            <div className="filter-arrow">
              {sortedColumn?.order === "ascend" ? (
                <i className="la la-arrow-up"></i>
              ) : (
                <i className="la la-arrow-down"></i>
              )}
            </div>
          </div>
        );
      },
    },
  ];
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",

      name: record.name,
    }),
    // selectedRowKeys: selectedRows.map((row) => row?.company_id),
    selectedRowKeys,
  };
  const edit = () => {
    navigate(`/plan-edit/${selectedRowKeys}`);
  };
  const deletePlan = () => {
    setModalIsOpen({ deleteModal: true });
  };

  const closeModal = () => {
    setModalIsOpen({ deleteModal: false });
  };
  const confirmDelete = () => {
    setButtonLoader(true);

    api
      .post(`superadmin/plan/delete/`, { plan_price_map_id: selectedRowKeys })
      .then((response) => {
        if (response.status_code === 200) {
          getPlanList();
          setModalIsOpen({ deleteModal: false });
          setSelectedRowKeys("");
          navigate("/plan");
          setButtonLoader(false);
          toast.success(response?.message);
        } else {
          Swal.fire({
            position: "top-end",
            title: response.message ? response.message : "Somthing went wrong",
            showConfirmButton: false,
            backdrop: false,
            timer: 5000,
          });
          setModalIsOpen({ deleteModal: false });
          setButtonLoader(false);
        }
      });
  };

  const statusChange = (e) => {
    setButtonLoader(true);

    let name = e.target.name;
    api
      .post(`superadmin/plan/status/`, {
        plan_price_map_id: selectedRowKeys,
        status: name === "active" ? "A" : "I",
      })
      .then((response) => {
        if (response.status_code === 200) {
          getPlanList();
          setButtonLoader(false);
          toast.success(response?.message);
          setSelectedRowKeys([]);

          navigate("/plan");
        } else {
          setButtonLoader(false);
        }
      });
  };
  const setSearchValue = (event) => {
    setListingSearchValue(event.target.value);
    if (timeOut) clearTimeout(timeOut);
    timeOut = setTimeout(() => {}, 500);
  };

  return (
    <>
      <DefaultPage />

      <div className="page-wrapper supar-admin-page-wrapper">
        <div className="content container-fluid">
          <div className="card">
            <ListingCardHeader
              PageTitle="Plans"
              PageName="Plan"
              link="/plan-add"
              icon="pl"
              PageIcon={`plan`}
            />

            <div className="card-action-wrap d-flex align-items-center justify-content-between pt-3 pb-2">
              <div className="card-actions pr-4">
                <EditButton selectedRowKeys={selectedRowKeys} editB={edit} />
                <DeleteButton
                  selectedRowKeys={selectedRowKeys}
                  deleteB={deletePlan}
                />
                <ActiveButton
                  name="active"
                  selectedRowKeys={selectedRowKeys}
                  active={statusChange}
                />
                <InactiveButton
                  name="inactive"
                  selectedRowKeys={selectedRowKeys}
                  inactive={statusChange}
                />
              </div>
              <SearchBox setSearchValue={setSearchValue} />
            </div>
            <ListingTable
              Data={planData}
              columns={columns}
              loader={loader}
              rowSelection={rowSelection}
              id="plan_price_map_id"
            />
          </div>
        </div>

        {modalIsOpen.deleteModal && (
          <DeleteModal
            modalIsOpen={modalIsOpen.deleteModal}
            closeModal={closeModal}
            content="Plan"
            confirmDelete={confirmDelete}
          />
        )}
      </div>
      {ButtonLoader && <Loader />}
      <Toaster />
    </>
  );
};

export default Plans;
