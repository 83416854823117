import React, { useState, useEffect } from "react";
import { api } from "../helper/Api";
import { Link, useNavigate } from "react-router-dom";
import DefaultPage from "./DefaultPage";
import {
  ActiveButton,
  DownloadButton,
  EditButton,
  InactiveButton,
  ListingCardHeader,
  ListingTable,
  SearchBox,
} from "../components/UtilsAndModal";
import Loader from "../components/loader";
import Swal from "sweetalert2";
import axios from "axios";
import { Toaster, toast } from "react-hot-toast";

const Invoice = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  const [ButtonLoader, setButtonLoader] = useState(false);

  const [listingSearchValue, setListingSearchValue] = useState("");
  const [invoiceData, setInvoiceData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  useEffect(() => {
    getInvoiceList();
  }, [listingSearchValue]);

  const Logout = () => {
    localStorage.clear();
    navigate("/superadminlogin");
  };

  const getInvoiceList = () => {
    api
      .get(`/superadmin/invoice/list/?search=${listingSearchValue}`)
      .then((response) => {
        setLoader(false);

        if (response.status_code === 200) {
          var data = response.data;

          setInvoiceData(data);
        } else {
          Swal.fire({
            position: "top-end",
            title: response.message ? response.message : "Somthing went wrong",
            showConfirmButton: false,
            backdrop: false,
            timer: 5000,
          });

          Logout();
        }
      })
      .catch((error) => {
        setLoader(false);
        Swal.fire({
          position: "top-end",
          title: `Error: ${error}`,
          showConfirmButton: false,
          backdrop: false,
          timer: 5000,
        });

        Logout();
      });
  };

  const columns = [
    {
      title: "Status",

      render: (a) => (
        <>
          <span
            className={`status-label ${
              a.status === "A"
                ? "active"
                : a.status === "I"
                ? "inactive"
                : "cancel"
            }`}
          >
            {a.status === "A"
              ? "Active"
              : a.status === "I"
              ? "Inactive"
              : "Cancel"}{" "}
          </span>
        </>
      ),
    },
    {
      title: "Date & Time",
      render: (a) => (
        <>
          <span className={`text-nowrap`}>{a.created_date}</span>
        </>
      ),
    },
    {
      render: (record) => (
        <div>
          <Link
            className={`text-nowrap`}
            to={`/InvoiceInfo/${record.invoice_id}`}
          >
            {record.company_name}{" "}
          </Link>
        </div>
      ),
      title: "Company Name",
    },
    {
      render: (a) => (
        <>
          <span className={`text-nowrap`}>{a.plan_name}</span>
        </>
      ),

      title: (titleProps) => {
        const sortedColumn = titleProps.sortColumns?.find(
          ({ column }) => column.key === "name"
        );

        return (
          <div className="d-flex align-items-center justify-content-between">
            <span>Plan Name</span>
          </div>
        );
      },
    },
    {
      title: "Product Group",
      render: (a) => (
        <>
          <span className={`text-nowrap`}>{a.product_name}</span>
        </>
      ),
    },

    {
      dataIndex: "email",
      title: (titleProps) => {
        const sortedColumn = titleProps.sortColumns?.find(
          ({ column }) => column.key === "name"
        );

        return (
          <div className="d-flex align-items-center justify-content-between">
            <span>Email Id</span>
            {/* {sortedColumn?.order === "ascend" ? (
              <i className="la la-arrow-up"></i>
            ) : (
              <i className="la la-arrow-down"></i>
            )} */}
          </div>
        );
      },
    },
    {
      title: "Payment Mode ",
      dataIndex: "payment_mode",
    },
    {
      title: "Transaction ID ",
      dataIndex: "reference_code",
    },
    {
      title: "Issued on ",

      render: (a) => (
        <>
          <span className={`text-nowrap`}>{a.issue_date}</span>
        </>
      ),
    },

    {
      title: "Amount ",
      render: (a) => (
        <>
          <span className={`text-nowrap`}>₹ {a.amount}</span>
        </>
      ),
    },
    {
      render: (a) => (
        <>
          <span className={`text-nowrap`}>{a.paid_date}</span>
        </>
      ),
      title: (titleProps) => {
        const sortedColumn = titleProps.sortColumns?.find(
          ({ column }) => column.key === "name"
        );

        return (
          <div className="d-flex align-items-center justify-content-between">
            <span>Paid On</span>
            {/* {sortedColumn?.order === "ascend" ? (
              <i className="la la-arrow-up"></i>
            ) : (
              <i className="la la-arrow-down"></i>
            )} */}
          </div>
        );
      },
    },
  ];
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",

      name: record.name,
    }),
    selectedRowKeys,
  };

  const setSearchValue = (event) => {
    setListingSearchValue(event.target.value);
    if (timeOut) clearTimeout(timeOut);
    timeOut = setTimeout(() => {}, 500);
  };
  const statusChange = (e) => {
    setButtonLoader(true);

    let name = e.target.name;
    api
      .post(`/superadmin/invoice/status_update/`, {
        id: selectedRowKeys,
        status: name === "active" ? "A" : "I",
      })
      .then((response) => {
        if (response.status_code === 200) {
          getInvoiceList();
          setButtonLoader(false);
          toast.success(response?.message);
          setSelectedRowKeys([]);
          setSelectedRows([]);

          navigate("/invoices");
        } else {
          setButtonLoader(false);
        }
      });
  };
  const cancelStatusProduct = () => {
    setButtonLoader(true);

    api
      .post(`/superadmin/invoice/status_update/`, {
        id: selectedRowKeys,
        status: "C",
      })
      .then((response) => {
        if (response.status_code === 200) {
          getInvoiceList();
          setButtonLoader(false);
          toast.success(response?.message);
          setSelectedRowKeys([]);
          setSelectedRows([]);

          navigate("/invoices");
        } else {
          setButtonLoader(false);
        }
      });
  };

  const invoiceDownloadHandler = () => {
    setButtonLoader(true);

    api
      .post(
        `/superadmin/invoice/invoice_download/`,
        {
          invoice_id: `${selectedRowKeys[0]}`,
        },
        { responseType: "arraybuffer" }
      )
      .then((response) => {
        // Create a Blob from the response data
        const file = new Blob([response.data], {
          type: "application/pdf",
        });

        // Create a temporary URL for the file
        const fileURL = window.URL.createObjectURL(file);

        // Create a link element to trigger the download
        const link = document.createElement("a");
        link.href = fileURL;

        const fileName = `${selectedRows[0].company_name}${selectedRows[0].invoice_id}.pdf`;
        link.setAttribute("download", fileName); // Set the desired file name
        document.body.appendChild(link);

        // Simulate a click on the link to start the download
        link.click();

        // Clean up: Remove the temporary link and revoke the object URL
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(fileURL);

        setSelectedRowKeys([]);
        setSelectedRows([]);
      })
      .catch((error) => {
        // Handle any error that occurred during the API request
        console.error(error);
      })
      .finally(() => {
        setButtonLoader(false);
      });
  };

  return (
    <>
      <DefaultPage />

      <div className="page-wrapper supar-admin-page-wrapper">
        <div className="content container-fluid">
          <div className="card">
            <ListingCardHeader
              PageTitle="Invoices"
              PageName="Invoice"
              link="/invoice-add"
              icon="i"
              PageIcon={`invoice`}
            />
            <div className="card-action-wrap d-flex align-items-center justify-content-between pt-3 pb-2">
              <div className="card-actions pr-4">
                <ActiveButton
                  name="active"
                  selectedRowKeys={selectedRowKeys}
                  active={statusChange}
                />
                <InactiveButton
                  name="inactive"
                  selectedRowKeys={selectedRowKeys}
                  inactive={statusChange}
                />
                <button
                  type="button"
                  className="btn-light-outlined btn rounded"
                  onClick={cancelStatusProduct}
                  disabled={selectedRowKeys.length > 0 ? false : true}
                >
                  Cancel Invoice
                </button>

                <DownloadButton
                  selectedRowKeys={selectedRowKeys}
                  onClick={invoiceDownloadHandler}
                />
              </div>
              <SearchBox setSearchValue={setSearchValue} />
            </div>
            <ListingTable
              Data={invoiceData}
              columns={columns}
              loader={loader}
              rowSelection={rowSelection}
              id="invoice_id"
            />
          </div>
        </div>
      </div>
      {ButtonLoader && <Loader />}
      <Toaster />
    </>
  );
};

export default Invoice;
