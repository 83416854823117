import React, { useEffect, useState } from "react";
import DefaultPage from "./DefaultPage";
import { api } from "../helper/Api";
import { useNavigate, useParams } from "react-router-dom";
import { AddUpdateCardFooter, AddUpdateCardHeader } from "../components/UtilsAndModal";
import { Form, Formik } from "formik";
import { productSchema } from "../components/FormValidation";
import Swal from "sweetalert2";
import Loader from "../components/loader";

const ProductAddEdit = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  let { ProductId } = useParams();
  const [formValue, setFormValue] = useState({
    productFamilyId: "",
    productFamilyName: "",
    description: "",
    status: "",
  });

  useEffect(() => {
    if (ProductId) {
      getProductList();
    }
  }, []);

  const getProductList = () => {
    setLoader(true)
    api
      .get(`/superadmin/product/listing/?product_id=${ProductId}`)
      .then((response) => {
        if (response.status_code === 200) {
          setLoader(false)
          var data = response.data;
          setFormValue({
            productFamilyId: data.product_id,
            productFamilyName: data.name,
            description: data.prod_description,
            status: data.status,
          });
        } else {
          setLoader(false)
        }
      });

  };

  const cancel = () => {
    navigate("/product");
  };

  const onSubmit = (values) => {
    setLoader(true)
    const { productFamilyName, status, description } = values;
    let formData = new FormData();
    formData.append("status", status);
    formData.append("product_id", ProductId ? ProductId : "");
    formData.append("prod_description", description);
    formData.append("name", productFamilyName);
    {
      (ProductId
        ? api.put(`/superadmin/product/update/`, formData)
        : api.post(`/superadmin/product/create/`, formData)
      ).then(function (response) {
        if (response.status_code === 200) {
          setLoader(false)
          navigate("/product");
        } else if (response.status_code === 400) {
          Swal.fire({
            position: 'top-end',
            title: response.message ? response.message : 'Fill all *Required field',
            showConfirmButton: false,
            backdrop: false,
            timer: 3000
          })
          setLoader(false)
        }
      });
    }
  };
  return (
    <>
      <DefaultPage />
      <div className="page-wrapper supar-admin-page-wrapper">
        <div className="content container-fluid">
          <Formik
            initialValues={formValue}
            validationSchema={productSchema}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {({ errors, values, touched, handleChange, handleBlur }) => (
              <Form autocomplete="off">
                <div className="card">
                  <AddUpdateCardHeader PageName='Product Catalogue' icon='p' PageIcon='product' cancel={cancel} PageId={ProductId} />
                </div>
                <div className="card">
                  <div className="card-header py-2">
                    <h4 className="card-title mb-0">Basic Information</h4>
                  </div>
                  <div className="card-body">
                    <div className="card-form-row uneven-row">
                      <div className="column-swa">
                        <div className="form-group">
                          <label className="col-form-label">
                            Product Family Name<sup className="text-danger">*</sup>
                          </label>
                          <input
                            className={`form-control ${(touched.productFamilyName && errors.productFamilyName)
                              ? "error-input"
                              : ""
                              }`}
                            type="text"
                           // placeholder=" Product Family Name"
                            name="productFamilyName"
                            value={values.productFamilyName || ""}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            autocomplete="none"
                          />
                          <span className="input-error">

                            {touched.productFamilyName && errors.productFamilyName ? errors.productFamilyName : null}
                          </span>
                        </div>
                      </div>

                      <div className="column-swa">
                        <div className="form-group">
                          <label className="col-form-label">Status<sup className="text-danger">*</sup></label>
                          <select
                            className={`form-control ${(touched.status && errors.status)
                              ? "error-input"
                              : ""
                              }`}
                            name="status"
                            value={values.status || ""}
                            onBlur={handleBlur}

                            onChange={handleChange}
                          >
                            <option value="">Select Status</option>
                            <option value="A">Active</option>
                            <option value="I">Inactive</option>
                          </select>
                          <span className="input-error">

                            {touched.status && errors.status ? errors.status : null}
                          </span>
                        </div>
                      </div>
                      <div className="column-swa" >
                        <div className="form-group">
                          <label className="col-form-label">Description</label>
                          <textarea

                            className="form-control"
                           // placeholder="Description"
                            name="description"
                            value={values.description || ""}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          ></textarea>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <AddUpdateCardFooter cancel={cancel} PageId={ProductId} />
              </Form>

            )}
          </Formik>


        </div>
      </div>
      {loader && <Loader />}
    </>
  );
};

export default ProductAddEdit;
