import React, { useState, useEffect } from "react";
import { api } from "../helper/Api";
import { Link, useNavigate } from "react-router-dom";
import DefaultPage from "./DefaultPage";

import {
  ActiveButton,
  DectivateModal,
  DeleteButton,
  DeleteModal,
  EditButton,
  InactiveButton,
  ListingCardHeader,
  ListingTable,
  SearchBox,
} from "../components/UtilsAndModal";
import Loader from "../components/loader";
import { Toaster, toast } from "react-hot-toast";
import Swal from "sweetalert2";

const Subscriptions = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  const [ButtonLoader, setButtonLoader] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [listingSearchValue, setListingSearchValue] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState({
    deleteModal: false,
    urlModal: false,
  });
  const [statusModalOpen, setStatusModalOpen] = useState(false);

  useEffect(() => {
    getCompanyList();
  }, [listingSearchValue]);

  const Logout = () => {
    localStorage.clear();
    navigate("/superadminlogin");
  };

  const getCompanyList = () => {
    api
      .get(`/superadmin/subscription/list/?search=${listingSearchValue}`)
      .then((response) => {
        setLoader(false);
        if (response.status_code === 200) {
          var data = response.data;
          setSubscriptionData(data);
        } else {
          Swal.fire({
            position: "top-end",
            title: response.message ? response.message : "Somthing went wrong",
            showConfirmButton: false,
            backdrop: false,
            timer: 5000,
          });

          Logout();
        }
      })
      .catch((error) => {
        setLoader(false);
        Swal.fire({
          position: "top-end",
          title: `Error: ${error}`,
          showConfirmButton: false,
          backdrop: false,
          timer: 5000,
        });

        Logout();
      });
  };

  const columns = [
    {
      title: "Status",

      render: (a) => (
        <>
          <span
            className={`status-label ${
              a.status === "A" ? "active" : "inactive"
            }`}
          >
            {a.status === "A" ? "Active" : "Inactive"}{" "}
          </span>
        </>
      ),
    },

    {
      title: "Company Name",
      render: (a) => (
        <>
          <span className={`text-nowrap`}>{a.company_name}</span>
        </>
      ),
    },
    {
      title: "Product Family",
      render: (a) => (
        <>
          <span className={`text-nowrap`}>{a.product_name}</span>
        </>
      ),
    },
    {
      render: (a) => (
        <>
          <span className={`text-nowrap`}>{a.plan_name}</span>
        </>
      ),

      title: (titleProps) => {
        const sortedColumn = titleProps.sortColumns?.find(
          ({ column }) => column.key === "name"
        );

        return (
          <div className="d-flex table-filter">
            <span>Plans</span>
            <div className="filter-arrow">
              {sortedColumn?.order === "ascend" ? (
                <i className="la la-arrow-up"></i>
              ) : (
                <i className="la la-arrow-down"></i>
              )}
            </div>
          </div>
        );
      },
    },
    {
      title: "Billing Type ",
      render: (a) => (
        <>
          <span className={`text-nowrap`}>{a.billing_type}</span>
        </>
      ),
    },

    {
      render: (a) => (
        <>
          <span className={`text-nowrap`}>{a.billing_address1}</span>
        </>
      ),

      title: (titleProps) => {
        const sortedColumn = titleProps.sortColumns?.find(
          ({ column }) => column.key === "name"
        );

        return (
          <div className="d-flex table-filter">
            <span>Address 1</span>
            <div className="filter-arrow">
              {sortedColumn?.order === "ascend" ? (
                <i className="la la-arrow-up"></i>
              ) : (
                <i className="la la-arrow-down"></i>
              )}
            </div>
          </div>
        );
      },
    },
    {
      title: "Address 2",
      render: (a) => (
        <>
          <span className={`text-nowrap`}>{a.billing_address2}</span>
        </>
      ),
    },

    {
      title: "City ",
      render: (a) => (
        <>
          <span className={`text-nowrap`}>{a.billing_city}</span>
        </>
      ),
    },
    {
      title: "State ",
      render: (a) => (
        <>
          <span className={`text-nowrap`}>{a.billing_state_name}</span>
        </>
      ),
    },
    {
      title: "Country ",
      render: (a) => (
        <>
          <span className={`text-nowrap`}>{a.billing_country_name}</span>
        </>
      ),
    },

    {
      dataIndex: "billing_pincode",
      title: (titleProps) => {
        const sortedColumn = titleProps.sortColumns?.find(
          ({ column }) => column.key === "name"
        );

        return (
          <div className="d-flex table-filter">
            <span>Pin Code</span>
            <div className="filter-arrow">
              {sortedColumn?.order === "ascend" ? (
                <i className="la la-arrow-up"></i>
              ) : (
                <i className="la la-arrow-down"></i>
              )}
            </div>
          </div>
        );
      },
    },
  ];
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",

      name: record.name,
    }),
    selectedRowKeys,
  };
  const edit = () => {
    navigate(`/subscription-edit/${selectedRowKeys}`);
  };
  const deleteSubscription = () => {
    setModalIsOpen({ deleteModal: true, urlModal: false });
  };
  const statusChangeSubscription = () => {
    setStatusModalOpen(true);
  };
  const confirmDelete = () => {
    setButtonLoader(true);
    api
      .post(`/superadmin/subscription/delete/`, { id: selectedRowKeys })
      .then((response) => {
        if (response.status_code === 200) {
          getCompanyList();
          setModalIsOpen({ deleteModal: false });
          setSelectedRowKeys("");
          toast.success(response?.message);
          navigate("/subscription");
          setButtonLoader(false);
        } else {
          setButtonLoader(false);
        }
      });
  };
  const confirmChange = (e) => {
    setButtonLoader(true);
    let name = e.target.name;
    api
      .post(`superadmin/subscription/status_update/`, {
        id: selectedRowKeys,
        status: name === "active" ? "A" : "I",
      })
      .then((response) => {
        if (response.status_code === 200) {
          getCompanyList();
          setButtonLoader(false);
          setStatusModalOpen(false);
          toast.success(response?.message);
          setSelectedRowKeys([]);
          setSelectedRows([]);
          navigate("/subscription");
        } else {
          setButtonLoader(false);
        }
      });
  };
  const closeModal = () => {
    setModalIsOpen({ deleteModal: false, urlModal: false });
  };
  const closeStatusModal = () => {
    setStatusModalOpen(false);
  };

  const setSearchValue = (event) => {
    setListingSearchValue(event.target.value);
  };
  return (
    <>
      <DefaultPage />

      <div className="page-wrapper supar-admin-page-wrapper">
        <div className="content container-fluid">
          <div className="card">
            <ListingCardHeader
              PageTitle="Subscriptions"
              PageName="Subscription"
              link="/subscription-add"
              icon="s"
              PageIcon={`money`}
            />
            <div className="card-action-wrap d-flex align-items-center justify-content-between pt-3 pb-2">
              <div className="card-actions pr-4">
                <EditButton selectedRowKeys={selectedRowKeys} editB={edit} />
                <DeleteButton
                  selectedRowKeys={selectedRowKeys}
                  deleteB={deleteSubscription}
                />
                {/* <ActiveButton
                  name='active'
                  selectedRowKeys={selectedRowKeys}
                  active={statusChange}
                /> */}
                <InactiveButton
                  selectedRowKeys={selectedRowKeys}
                  // changeStatus={statusChangeSubscription}
                  inactive={statusChangeSubscription}
                />
              </div>
              <SearchBox setSearchValue={setSearchValue} />
            </div>
            <ListingTable
              Data={subscriptionData}
              columns={columns}
              loader={loader}
              rowSelection={rowSelection}
              id="subscription_id"
            />
          </div>
        </div>
      </div>
      {modalIsOpen.deleteModal && (
        <DeleteModal
          modalIsOpen={modalIsOpen.deleteModal}
          closeModal={closeModal}
          content="Subscription"
          confirmDelete={confirmDelete}
        />
      )}
      {statusModalOpen && (
        <DectivateModal
          modalIsOpen={statusModalOpen}
          closeModal={closeStatusModal}
          content="Subscription"
          confirmChange={confirmChange}
        />
      )}
      {ButtonLoader && <Loader />}
      <Toaster />
    </>
  );
};

export default Subscriptions;
