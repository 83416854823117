import React, { useState, useEffect } from "react";
import DefaultPage from "./DefaultPage";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { api } from "../helper/Api";
import Swal from "sweetalert2";
import {
  ActivateModal,
  ActiveButton,
  AlertModal,
  DectivateModal,
  DeleteButton,
  DeleteModal,
  EditButton,
  InactiveButton,
  LaunchButton,
  LaunchCompany,
  ListingCardHeader,
  ListingTable,
  SearchBox,
} from "../components/UtilsAndModal";
import Loader from "../components/loader";
import toast, { Toaster } from "react-hot-toast";
import ProgressBar from "@ramonak/react-progress-bar";

let timeOut;
const Companies = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  const [progress, setProgress] = useState(0);
  const [showProgressBar, setShowProgressBar] = useState(false);
  useEffect(() => {
    let id;
    if (showProgressBar) {
      id = setInterval(() => {
        setProgress((oldCount) => (oldCount < 99 ? oldCount + 2 : oldCount));
      }, 1000);
    } else {
      clearInterval(id);
    }

    return () => {
      clearInterval(id);
    };
  }, [showProgressBar]);
  const [ButtonLoader, setButtonLoader] = useState(false);
  const [listingSearchValue, setListingSearchValue] = useState("");
  const [companyData, setCompanyData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [statusChangeModalOpen, setStatusChangeModalOpen] = useState(false);
  const [activeStatusChangeModalOpen, setActiveStatusChangeModalOpen] =
    useState(false);
  const [alertModalOpen, setAlertModalOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState({
    deleteModal: false,
    urlModal: false,
  });

  useEffect(() => {
    getCompanyList();
  }, []);

  const Logout = () => {
    localStorage.clear();
    navigate("/superadminlogin");
  };

  const getCompanyList = () => {
    api
      .get("/superadmin/company/listing/?company_id=0")
      .then((response) => {
        if (response.status_code === 200) {
          setCompanyData(response.data);
          if (response.data.length === 0) {
            setLoader(false);
          }
        } else {
          Swal.fire({
            position: "top-end",
            title: response.message ? response.message : "Somthing went wrong",
            showConfirmButton: false,
            backdrop: false,
            timer: 5000,
          });

          Logout();
        }
      })
      .catch((error) => {
        Swal.fire({
          position: "top-end",
          title: `Error: ${error}`,
          showConfirmButton: false,
          backdrop: false,
          timer: 5000,
        });

        Logout();
      });
  };

  const clickToSetupCompany = () => {
    setProgress(0);
    setShowProgressBar(true);
    setModalIsOpen({ deleteModal: false, urlModal: false });
    setButtonLoader(true);
    api
      .post("/superadmin/company/lunch/", { company_id: selectedRowKeys[0] })
      .then(function (response) {
        if (response.status === 200) {
          toast.success(response.message);
          setProgress(100);
          setShowProgressBar(false);
          navigate("/company");
          getCompanyList();
          setButtonLoader(false);
          setSelectedRowKeys("");
        } else {
          Swal.fire({
            position: "top-end",
            title: response.message ? response.message : "Somthing went wrong",
            showConfirmButton: false,
            backdrop: false,
            timer: 5000,
          });
          setButtonLoader(false);
          setShowProgressBar(false);
        }
      });
  };

  const columns = [
    {
      title: "Status",
      render: (a) => (
        <span
          className={`status-label ${a.status === "A" ? "active" : "inactive"}`}
        >
          {a.status === "A"
            ? "Active"
            : a.status === "P"
            ? "Pending"
            : a.status === "T"
            ? "Trial"
            : a.status === "I"
            ? "Inactive"
            : a.status === "SP"
            ? "S.Pending"
            : a.status === "SE"
            ? "S.Expired"
            : ""}
        </span>
      ),
    },
    {
      title: "Login",
      render: (a) => (
        <div>
          <Link
            className={`text-nowrap`}
            to={`https://${a.subdomain}.swagatham.co.in/blue/login`}
            target="_blank"
          >
            {a.status === "A" ? "Login" : a.status === "T" ? "Login" : ""}
          </Link>
        </div>
      ),
    },

    {
      title: "Company Name",
      render: (record) => (
        <div>
          <Link
            className={`text-nowrap`}
            to={`/companyInfo/${record.company_id}`}
          >
            {record.name}
          </Link>
        </div>
      ),
    },
    {
      title: "Branch Name",
      dataIndex: "branch",
    },
    {
      title: "Sub Domain",
      render: (a) => (
        <span className={`text-nowrap`}>{a.subdomain}.swagatham.co.in</span>
      ),
    },
    {
      dataIndex: "login_email",
      title: (titleProps) => {
        const sortedColumn = titleProps.sortColumns?.find(
          ({ column }) => column.key === "name"
        );

        return (
          <div className="d-flex align-items-center justify-content-between">
            <span>Login Email</span>
          </div>
        );
      },
    },
    {
      title: "POC Name",
      render: (a) => <span className={`text-nowrap`}>{a.poc_name}</span>,
    },
    {
      title: "POC Number",
      dataIndex: "poc_mobile",
    },
    {
      title: "POC Email",
      render: (a) => (
        <>
          <span className={`text-nowrap`}>{a.poc_email}</span>
        </>
      ),
    },
    {
      title: "Company Url",
      render: (record) => (
        <div>
          <Link className={`text-nowrap`} to="#">
            {record.website_url}
          </Link>
        </div>
      ),
    },

    {
      render: (a) => (
        <>
          <span className={`text-nowrap`}>{a.created_date}</span>
        </>
      ),

      title: (titleProps) => {
        const sortedColumn = titleProps.sortColumns?.find(
          ({ column }) => column.key === "name"
        );

        return (
          <div className="d-flex align-items-center justify-content-between">
            <span>Create Date</span>
          </div>
        );
      },
    },
    {
      title: "Modified Date ",
      render: (a) => (
        <>
          <span className={`text-nowrap`}>{a.updated_date}</span>
        </>
      ),
    },
    {
      render: (a) => (
        <>
          <span className={`text-nowrap`}>{a.updated_date}</span>
        </>
      ),
      title: (titleProps) => {
        const sortedColumn = titleProps.sortColumns?.find(
          ({ column }) => column.key === "name"
        );

        return (
          <div className="d-flex align-items-center justify-content-between">
            <span>Last Login Date</span>
          </div>
        );
      },
    },
  ];
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
    selectedRowKeys,
    // selectedRowKeys: selectedRows.map((row) => row?.company_id),
  };
  const deleteCompany = () => {
    setModalIsOpen({ deleteModal: true, urlModal: false });
  };
  const inactiveCompany = () => {
    setStatusChangeModalOpen(true);
  };
  const activeCompany = () => {
    setActiveStatusChangeModalOpen(true);
  };
  const alertOpenModal = () => {
    setAlertModalOpen(true);
  };
  const alertCloseModal = () => {
    setAlertModalOpen(false);
  };
  const launchModalOpen = () => {
    setModalIsOpen({ deleteModal: false, urlModal: true });
  };
  const closeModal = () => {
    setModalIsOpen({ deleteModal: false });
  };
  const inactiveCloseModal = () => {
    setStatusChangeModalOpen(false);
  };
  const activeStatusCloseModal = () => {
    setActiveStatusChangeModalOpen(false);
  };
  const confirmDelete = () => {
    setButtonLoader(true);
    api
      .post(`superadmin/company/delete/`, { company_id: selectedRowKeys })
      .then((response) => {
        if (response.status_code === 200) {
          toast.success(response.message);
          getCompanyList();
          setModalIsOpen({ deleteModal: false, urlModal: false });
          navigate("/company");
          setSelectedRowKeys("");
          setButtonLoader(false);
        } else {
          setButtonLoader(false);
        }
      });
  };

  const clickToInactiveCompany = (e) => {
    setButtonLoader(true);
    //let name = e.target.name;
    //console.log(name,'nammmmm');
    api
      .post(`superadmin/company/status/`, {
        company_id: selectedRowKeys,
        status: "I",
      })
      .then((response) => {
        if (response.status_code === 200) {
          getCompanyList();
          setButtonLoader(false);
          setStatusChangeModalOpen(false);
          toast.success("Company Status Changes Successfully");
          setSelectedRowKeys([]);
          setSelectedRows([]);
          navigate("/company");
        } else {
          setButtonLoader(false);
        }
      });
  };

  const clickToActiveCompany = (e) => {
    setButtonLoader(true);
    //let name = e.target.name;
    //console.log(name,'nammmmm');
    api
      .post(`superadmin/company/status/`, {
        company_id: selectedRowKeys,
        status: "A",
      })
      .then((response) => {
        if (response.status_code === 200) {
          getCompanyList();
          // setButtonLoader(false);
          setActiveStatusChangeModalOpen(false);
          toast.success("Company Status Changes Successfully");
          setSelectedRowKeys([]);
          setSelectedRows([]);
          navigate("/company");
        } else if (response.status_code === 400) {
          activeStatusCloseModal();
          alertOpenModal();
          setAlertMessage(response.message);
          navigate("/company");
          // setButtonLoader(false);
        }
        setButtonLoader(false);
      });
  };

  const setSearchValue = (event) => {
    setListingSearchValue(event.target.value);
    if (timeOut) clearTimeout(timeOut);
    timeOut = setTimeout(() => {
      if (event.target.value) {
        searchData(event.target.value);
      } else {
        getCompanyList();
      }
    }, 500);
  };
  const searchData = (search) => {
    setShowProgressBar(true);
    api
      .post(`superadmin/company/search/`, {
        data: listingSearchValue || search,
      })
      .then((response) => {
        if (response.status === 200) {
          setCompanyData([...response.data]);
          setShowProgressBar(false);
        } else {
          setCompanyData([]);
          if (response.data.length === 0) {
            setLoader(false);
          }
        }
      });
  };
  const edit = () => {
    navigate(`/company-edit/${selectedRowKeys}`);
  };

  return (
    <>
      <DefaultPage />
      <div className="page-wrapper supar-admin-page-wrapper">
        <div className="content container-fluid">
          <div className="card">
            <ListingCardHeader
              PageTitle="Companies"
              PageName="Company"
              link="/company-add"
              icon="c"
              PageIcon={`company`}
            />
            <div className="card-action-wrap d-flex align-items-center justify-content-between pt-3 pb-2">
              <div className="card-actions pr-4">
                <EditButton selectedRowKeys={selectedRowKeys} editB={edit} />
                <DeleteButton
                  selectedRowKeys={selectedRowKeys}
                  selectedRows={selectedRows}
                  deleteB={deleteCompany}
                />
                <ActiveButton
                  name="active"
                  selectedRowKeys={selectedRowKeys}
                  selectedRows={selectedRows}
                  active={activeCompany}
                />
                <InactiveButton
                  name="inactive"
                  selectedRowKeys={selectedRowKeys}
                  inactive={inactiveCompany}
                />

                <LaunchButton
                  selectedRowKeys={selectedRowKeys}
                  selectedRows={selectedRows}
                  launchModalOpen={launchModalOpen}
                />
              </div>
              <SearchBox setSearchValue={setSearchValue} />
            </div>

            <ListingTable
              Data={companyData}
              columns={columns}
              loader={loader}
              rowSelection={rowSelection}
              id="company_id"
            />
          </div>
        </div>

        {modalIsOpen.deleteModal && (
          <DeleteModal
            modalIsOpen={modalIsOpen.deleteModal}
            closeModal={closeModal}
            content="Company"
            confirmDelete={confirmDelete}
          />
        )}
        {modalIsOpen.urlModal && (
          <LaunchCompany
            modalIsOpen={modalIsOpen.urlModal}
            closeModal={closeModal}
            content="Company"
            clickToSetupCompany={clickToSetupCompany}
          />
        )}
        {statusChangeModalOpen && (
          <DectivateModal
            modalIsOpen={statusChangeModalOpen}
            closeModal={inactiveCloseModal}
            content="Company"
            confirmChange={clickToInactiveCompany}
          />
        )}

        {activeStatusChangeModalOpen && (
          <ActivateModal
            modalIsOpen={activeStatusChangeModalOpen}
            closeModal={activeStatusCloseModal}
            content="Company"
            confirmActivate={clickToActiveCompany}
          />
        )}

        {alertModalOpen && (
          <AlertModal
            modalIsOpen={alertModalOpen}
            closeModal={alertCloseModal}
            content={alertMessage}
          />
        )}

        {ButtonLoader && <Loader />}
        <Toaster />

        {showProgressBar && (
          // <div style={{ transform: "translateY(-500px)", zIndex: 1000 }}>
          <div
            style={{ position: "fixed", left: "0", bottom: "0", width: "100%" }}
          >
            <ProgressBar
              completed={progress}
              maxCompleted={100}
              bgColor="#02ADEE"
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Companies;
