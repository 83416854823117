import React, { useEffect, useState } from "react";
import DefaultPage from "./DefaultPage";
import { api } from "../helper/Api";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../components/loader";

import { AddUpdateCardFooter, AddUpdateCardHeader } from "../components/UtilsAndModal";


const ProductInfo = () => {
  const navigate = useNavigate();
  let { ProductId } = useParams();
  const [loader, setLoader] = useState(false);

  const [formValue, setFormValue] = useState({
    productFamilyId: "",
    productFamilyName: "",
    description: "",
    status: "",
  });

  useEffect(() => {
    if (ProductId) {
      getProductList();
    }
  }, []);

  const getProductList = () => {
    setLoader(true)

    api
      .get(`/superadmin/product/listing/?product_id=${ProductId}`)
      .then((response) => {

        if (response.status_code === 200) {
          setLoader(false)
          var data = response.data;

          setFormValue({
            productFamilyId: data.product_id,
            productFamilyName: data.name,
            description: data.prod_description,
            status: data.status,
          });
        } else {
          setLoader(false)

        }
      });

  };

  const cancel = () => {
    navigate("/product");
  };

  return (
    <>
      <DefaultPage />
      <div className="page-wrapper supar-admin-page-wrapper">
        <div className="content container-fluid">
          <div className="card">
            <AddUpdateCardHeader PageName='Product Catalogue' companyName={formValue.productFamilyName} icon='p' PageIcon='product' cancel={cancel} />
          </div>

          <form>
            <div className="card">
              <div className="card-header py-2">
                <h4 className="card-title mb-0">Basic Information</h4>
              </div>
              <div className="card-body">
                <div className="card-form-row">
                  <div className="column-swa">
                    <div className="form-group">
                      <label className="col-form-label view-cel">
                        <span className="view-label">Product Family Name</span>{" "}
                        <span className="view-value">
                          {formValue.productFamilyName}
                        </span>
                      </label>
                    </div>
                  </div>

                  <div className="column-swa">
                    <div className="form-group">
                      <label className="col-form-label view-cel">
                        <span className="view-label">Status </span>{" "}
                        <span className="view-value">
                          {formValue.status === "I" ? "Inactive" : "Active"}
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row" style={{ width: "100%" }}>
                  {" "}
                  <div className="column-swa">
                    <div className="form-group">
                      <label className="col-form-label">
                        <span className="view-dec-label">Description :</span>
                        <span className="view-dec">
                          {formValue.description}
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <AddUpdateCardFooter cancel={cancel}/> */}

          </form>
        </div>
      </div>
      {loader && <Loader />}
    </>
  );
};

export default ProductInfo;
