import React, { useState, useEffect } from "react";
import { api } from "../helper/Api";
import { Link, useNavigate } from "react-router-dom";
import DefaultPage from "./DefaultPage";
import Loader from "../components/loader";
import {
  ActiveButton,
  DeleteButton,
  DeleteModal,
  EditButton,
  InactiveButton,
  ListingCardHeader,
  ListingTable,
  SearchBox,
  ChangeStatusButton,
  StatusChangeModal,
  ActivateModal,
} from "../components/UtilsAndModal";
import Swal from "sweetalert2";
import { Toaster, toast } from "react-hot-toast";
let timeOut;
const Products = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  const [ButtonLoader, setButtonLoader] = useState(false);
  const [listingSearchValue, setListingSearchValue] = useState("");
  const [productData, setProductData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState({ deleteModal: false });
  const [statusChange, setStatusChange] = useState(false);
  const [deactivate, setDeactivate] = useState(false);

  useEffect(() => {
    getProductList();
  }, []);

  const Logout = () => {
    localStorage.clear();
    navigate("/superadminlogin");
  };

  const getProductList = () => {
    api
      .get("/superadmin/product/listing/?product_id=0")
      .then((response) => {
        if (response.status_code === 200) {
          var data = response.data;
          setProductData(data);
          if (data.length === 0) {
            setLoader(false);
          }
        } else {
          Swal.fire({
            position: "top-end",
            title: response.message ? response.message : "Somthing went wrong",
            showConfirmButton: false,
            backdrop: false,
            timer: 5000,
          });

          Logout();
        }
      })
      .catch((error) => {
        setLoader(false);
        Swal.fire({
          position: "top-end",
          title: `Error: ${error}`,
          showConfirmButton: false,
          backdrop: false,
          timer: 5000,
        });

        Logout();
      });
  };

  const columns = [
    {
      title: () => {
        return (
          <div>
            <span>Status</span>
          </div>
        );
      },

      render: (a) => (
        <>
          <span
            className={`status-label ${
              a.status === "A" ? "active" : "inactive"
            }`}
          >
            {a.status === "A" ? "Active" : "Inactive"}{" "}
          </span>
        </>
      ),
    },

    {
      title: "Product Family",
      render: (record) => (
        <div>
          <Link
            className={`text-nowrap`}
            to={`/productInfo/${record.product_id}`}
          >
            {record.name}{" "}
          </Link>
        </div>
      ),
    },

    {
      dataIndex: "prod_description",
      title: (titleProps) => {
        const sortedColumn = titleProps.sortColumns?.find(
          ({ column }) => column.key === "name"
        );

        return (
          <div className="d-flex align-items-center justify-content-between">
            <span>Description</span>
          </div>
        );
      },
    },

    {
      title: "Created Date",
      render: (a) => (
        <>
          <span className={`text-nowrap`}>{a.created_date}</span>
        </>
      ),
    },
  ];
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",

      name: record.name,
    }),
    selectedRowKeys,
  };
  const edit = () => {
    navigate(`/product-edit/${selectedRowKeys}`);
  };
  const deleteProduct = () => {
    setModalIsOpen({ deleteModal: true });
  };
  const statusmodalOpen = () => {
    setStatusChange(true);
  };

  const closeModal = () => {
    setModalIsOpen({ deleteModal: false });
  };
  const closeStatusModal = () => {
    setStatusChange(false);
  };
  const confirmDelete = () => {
    setButtonLoader(true);
    api
      .post(`superadmin/product/delete/`, { product_id: selectedRowKeys })
      .then((response) => {
        if (response.status_code === 200) {
          getProductList();
          setModalIsOpen({ deleteModal: false });
          setSelectedRowKeys("");
          navigate("/product");
          setButtonLoader(false);
          toast.success(response?.message);
        } else {
          Swal.fire({
            position: "top-end",
            title: response.message ? response.message : "Somthing went wrong",
            showConfirmButton: false,
            backdrop: false,
            timer: 5000,
          });
          setModalIsOpen({ deleteModal: false });
          setButtonLoader(false);
        }
      });
  };
  const statusChangeb = (e) => {
    setButtonLoader(true);

    let name = e.target.name;
    api
      .post(`superadmin/product/status/`, {
        product_id: selectedRowKeys,
        status: name === "active" ? "A" : "I",
      })
      .then((response) => {
        if (response.status_code === 200) {
          getProductList();
          setButtonLoader(false);
          toast.success(response?.message);
          setSelectedRowKeys([]);
          setSelectedRows([]);
          navigate("/product");
        } else {
          setButtonLoader(false);
        }
      });
  };
  const changeStatusd = (e) => {
    let value = e.target.value;
    if (value) {
      setStatusChange(true);
    }

    // if(value){
    //   api
    //   .post(`superadmin/product/status/`, {
    //     product_id: selectedRowKeys,
    //     status: value,
    //   })
    //   .then((response) => {
    //     if (response.status_code === 200) {
    //       getProductList();
    //       navigate("/product");
    //     } else {
    //     }
    //   });
    // }
  };

  const setSearchValue = (event) => {
    setListingSearchValue(event.target.value);
    if (timeOut) clearTimeout(timeOut);
    timeOut = setTimeout(() => {
      if (event.target.value) {
        searchData(event.target.value);
      } else {
        getProductList();
      }
    }, 500);
  };

  const searchData = (search) => {
    api
      .post(`superadmin/product/search/`, {
        data: listingSearchValue || search,
      })
      .then((response) => {
        if (response.status === 200) {
          setProductData([...response.data]);
        } else {
          setProductData([]);
          if (response.data.length === 0) {
            setLoader(false);
          }
        }
      });
  };
  return (
    <>
      <DefaultPage />

      <div className="page-wrapper supar-admin-page-wrapper">
        <div className="content container-fluid">
          <div className="card">
            <ListingCardHeader
              PageTitle="Product Catalogues"
              PageName="Product"
              link="/product-add"
              icon="p"
              PageIcon={`product`}
            />

            <div className="card-action-wrap d-flex align-items-center justify-content-between pt-3 pb-2">
              <div className="card-actions pr-4">
                <EditButton selectedRowKeys={selectedRowKeys} editB={edit} />
                <DeleteButton
                  selectedRowKeys={selectedRowKeys}
                  deleteB={deleteProduct}
                />
                <ActiveButton
                  name="active"
                  selectedRowKeys={selectedRowKeys}
                  active={statusChangeb}
                />
                <InactiveButton
                  name="inactive"
                  selectedRowKeys={selectedRowKeys}
                  inactive={statusChangeb}
                />
                {/* <ChangeStatusButton changeStatus={changeStatusd} selectedRowKeys={selectedRowKeys}/> */}
              </div>
              <SearchBox setSearchValue={setSearchValue} />
            </div>

            <ListingTable
              Data={productData}
              columns={columns}
              loader={loader}
              rowSelection={rowSelection}
              id="product_id"
            />
          </div>
        </div>

        {modalIsOpen.deleteModal && (
          <DeleteModal
            modalIsOpen={modalIsOpen.deleteModal}
            closeModal={closeModal}
            content="Product"
            confirmDelete={confirmDelete}
          />
        )}
        {statusChange && (
          <ActivateModal
            modalIsOpen={statusChange}
            closeModal={closeStatusModal}
            content="Product"
            confirmActivate={confirmDelete}
          />
        )}
      </div>

      {ButtonLoader && <Loader />}
      <Toaster />
    </>
  );
};

export default Products;
