import React from "react";
import { useNavigate } from "react-router";

const PageNotFound = () => {
    const navigate = useNavigate();

    const gotoHome = () => {
        navigate('/');
    }

    return (
        <div>
            <h2>Page Not Found</h2>
            <button onClick={gotoHome}>Goto Home</button>
        </div>
    );
}

export default PageNotFound;