import React, { useEffect, useState } from "react";
import DefaultPage from "./DefaultPage";
import { api } from "../helper/Api";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../components/loader";

import { AddUpdateCardFooter, AddUpdateCardHeader } from "../components/UtilsAndModal";

const PlanInfo = () => {
  const navigate = useNavigate();
  let { PlanId } = useParams();
  const [loader, setLoader] = useState(false);

  const [formValue, setFormValue] = useState({
    productFamily: "",
    planExternal: "",
    planInternal: "",
    pricingModule: "",
    price: "",
    billingCycle: "",
    frequency: "",
    status: "",
  });

  useEffect(() => {
    if (PlanId) {
      getPlanDetails();
    }
  }, []);
  const getPlanDetails = () => {
    setLoader(true)

    api.get(`/superadmin/plan/listing/?plan_id=${PlanId}`).then((response) => {
      if (response.status_code === 200) {
        setLoader(false)
        var data = response.data[0];
        setFormValue({
          productFamily: data.family_name,
          planExternal: data.external_name,
          planInternal: data.internal_name,
          pricingModule: data.pricing_module,
          price: data.price,
          billingCycle: data.billing_cycle,
          frequency: data.frequency,
          status: data.plan_status,
          dec: data.plan_description,
        });
      } else {
        setLoader(false)
      }
    });
  };
  const cancel = () => {
    navigate("/plan");
  };

  return (
    <>
      <DefaultPage />
      <div className="page-wrapper supar-admin-page-wrapper">
        <div className="content container-fluid">
          <div className="card">
            <AddUpdateCardHeader PageName='Plan' companyName={formValue.productFamily} icon='pl' PageIcon='plan' cancel={cancel} />

          </div>

          <form>
            <div className="card">
              <div className="card-header py-2">
                <h4 className="card-title mb-0">Basic Information</h4>
              </div>
              <div className="card-body">
                <div className="card-form-row">
                  <div className="column-swa">
                    <div className="form-group">
                      <label className="col-form-label view-cel">
                        <span className="view-label">Product Family</span>{" "}
                        <span className="view-value">
                          {formValue.productFamily}
                        </span>
                      </label>
                    </div>
                  </div>
                  <div className="column-swa">
                    <div className="form-group">
                      <label className="col-form-label view-cel">
                        <span className="view-label">Plan External Name</span>{" "}
                        <span className="view-value">
                          {formValue.planExternal}
                        </span>
                      </label>
                    </div>
                  </div>
                  <div className="column-swa">
                    <div className="form-group">
                      <label className="col-form-label view-cel">
                        <span className="view-label">Plan Internal Name</span>{" "}
                        <span className="view-value">
                          {formValue.planInternal}
                        </span>
                      </label>
                    </div>
                  </div>
                  <div className="column-swa">
                    <div className="form-group">
                      <label className="col-form-label view-cel">
                        <span className="view-label">Pricing Module</span>{" "}
                        <span className="view-value">
                          {formValue.pricingModule}
                        </span>
                      </label>
                    </div>
                  </div>
                  <div className="column-swa">
                    <div className="form-group">
                      <label className="col-form-label view-cel">
                        <span className="view-label">Price</span>{" "}
                        <span className="view-value">
                          ₹ {formValue.price}
                        </span>
                      </label>
                    </div>
                  </div>
                  <div className="column-swa">
                    <div className="form-group">
                      <label className="col-form-label view-cel">
                        <span className="view-label">Billing Cycle</span>{" "}
                        <span className="view-value">
                          {formValue.billingCycle}
                        </span>
                      </label>
                    </div>
                  </div>
                  <div className="column-swa">
                    <div className="form-group">
                      <label className="col-form-label view-cel">
                        <span className="view-label">Frequency</span>{" "}
                        <span className="view-value">
                          {formValue.frequency}
                        </span>
                      </label>
                    </div>
                  </div>
                  <div className="column-swa">
                    <div className="form-group">
                      <label className="col-form-label view-cel">
                        <span className="view-label">Status</span>{" "}
                        <span className="view-value">
                          {formValue.status === "I" ? "Inactive" : "Active"}
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row" style={{ width: "100%" }}>
                  {" "}
                  <div className="column-swa">
                    <div className="form-group">
                      <label className="col-form-label">
                        <span className="view-dec-label">Description</span>
                        <span className="view-dec" dangerouslySetInnerHTML={{ __html: formValue.dec }}></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <AddUpdateCardFooter cancel={cancel}/> */}
          </form>
        </div>
      </div>
      {loader && <Loader />}
    </>
  );
};

export default PlanInfo;
