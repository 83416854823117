import { SETCOMPANYLIST } from "../Constant";

export const CompanyData = (data = [], action) => {
  switch (action.type) {
    case SETCOMPANYLIST:
      return [...action.data];

    default:
      return data;
  }
};
