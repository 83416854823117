import React from "react";
import logo from "../assets/Images/Swagathamsuperlogo.jpg";
import { Link, useLocation } from "react-router-dom";
const Sidebar = () => {
  const location = useLocation();
  return (
    <div className="sidebar super-sidebar" id="sidebar">
      <a href="#" className="logo sidebar-logo">
        <img src={logo} alt="logo" className="sidebar-logo-img" />
      </a>
      <div className="sidebar-inner slimscroll">
        <div id="sidebar-menu" className="sidebar-menu">
          <ul>
          <li>
              <Link
                className={`company-sidebar-link ${location.pathname === "/lead" ? "active" : ""
                  }`}
                to="/lead"
              >
                <span className="icon icon-Group"></span> <span> Leads </span>
              </Link>
            </li>
            <li>
              <Link
                className={`company-sidebar-link ${location.pathname === "/company" ? "active" : ""
                  }`}
                to="/company"
              >
                <span className="icon icon-company"></span>{" "}
                <span>Companies</span>
              </Link>
            </li>

            <li>
              <Link
                className={`company-sidebar-link ${location.pathname === "/subscription" ? "active" : ""
                  }`}
                to="/subscription"
              >
                <span className="icon icon-money"></span>
                <span> Subscriptions </span>
              </Link>
            </li>

            <li>
              <Link
                className={`company-sidebar-link ${location.pathname === "/invoices" ? "active" : ""
                  }`}
                to="/invoices"
              >
                <span className="icon icon-invoice"></span>{" "}
                <span> Invoices </span>
              </Link>
            </li>

            <li>
              <Link
                className={`company-sidebar-link ${location.pathname === "/product" ? "active" : ""
                  }`}
                to="/product"
              >
                <span className="icon icon-product"></span>
                <span> Product Catalogues </span>
              </Link>
            </li>
            <li>
              <Link
                className={`company-sidebar-link ${location.pathname === "/plan" ? "active" : ""
                  }`}
                to="/plan"
              >
                <span className="icon icon-plan"></span> <span> Plans </span>
              </Link>
            </li>
           
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
