import React from "react";
import Modal from "react-modal";
import { customStyles } from "./ModalStyle";
import { Table } from "antd";
//import { DatePicker } from 'antd';
import { useField, useFormikContext } from "formik";
import { itemRender, onShowSizeChange } from "./Pagination";
import { TableSkeltonPlan, TableSkeltonProduct } from "./TableSkelton";
import { Link } from "react-router-dom";
import EmptyBox from "../assets/Images/empty-box.svg";
import { DatePicker, Space } from "antd";
import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs from "dayjs";
dayjs.extend(customParseFormat);
const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY", "DD-MM-YYYY", "DD-MM-YY"];

export const DeleteModal = ({
  modalIsOpen,
  closeModal,
  confirmDelete,
  content,
}) => {
  return (
    <Modal isOpen={modalIsOpen} style={customStyles}>
      <div className="modal-main">
        <div className="modal-icon">
          <div className="modal-icon-tab delete">
            <i className="icon icon-trash" aria-hidden="true"></i>
          </div>
        </div>
        <h3> Are you sure you want to delete this {content}?</h3>
        <h2>
          Please confirm that you wish to proceed with the deletion by clicking
          'Delete' or click 'Cancel' to keep the {content} and its data.
        </h2>
        <div className="button-set">
          <button
            className="btn-light-outlined btn rounded"
            style={{ marginRight: "10px" }}
            onClick={closeModal}
          >
            Cancel
          </button>
          <button className="btn btn-primary rounded" onClick={confirmDelete}>
            Delete
          </button>
        </div>
      </div>
    </Modal>
  );
};

export const AlertModal = ({
  modalIsOpen,
  closeModal,
  // confirmActivate,
  content,
}) => {
  return (
    <Modal isOpen={modalIsOpen} style={customStyles}>
      <div className="modal-main">
        <div className="modal-icon">
          <div className="modal-icon-tab activate">
            {/* <i className="icon icon-alert" aria-hidden="true"></i> */}
            <i className="icon icon-lock" aria-hidden="true"></i>
          </div>
        </div>
        <h3> {content}</h3>
        <div className="button-set">
          <button
            className="btn-light-outlined btn rounded mt-3"
            style={{ margin: "0 auto" }}
            onClick={closeModal}
          >
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
};

export const ActivateModal = ({
  modalIsOpen,
  closeModal,
  confirmActivate,
  content,
}) => {
  return (
    <Modal isOpen={modalIsOpen} style={customStyles}>
      <div className="modal-main">
        <div className="modal-icon">
          <div className="modal-icon-tab activate">
            <i className="icon icon-lock" aria-hidden="true"></i>
          </div>
        </div>
        <h3> Are you sure you want to change this {content} status ?</h3>
        <h2>
          Please confirm that you wish to proceed with the change by clicking
          'Activate' or click 'Cancel' to back {content} Page.
        </h2>
        <div className="button-set">
          <button
            className="btn-light-outlined btn rounded"
            style={{ marginRight: "10px" }}
            onClick={closeModal}
          >
            Cancel
          </button>
          <button className="btn btn-primary rounded" onClick={confirmActivate}>
            Activate
          </button>
        </div>
      </div>
    </Modal>
  );
};

export const DectivateModal = ({
  modalIsOpen,
  closeModal,
  confirmChange,
  content,
}) => {
  return (
    <Modal isOpen={modalIsOpen} style={customStyles}>
      <div className="modal-main">
        <div className="modal-icon">
          <div className="modal-icon-tab delete">
            <i className="icon icon-lock" aria-hidden="true"></i>
          </div>
        </div>
        <h3> Are you sure you want to change this {content} status ?</h3>
        <h2>
          Please confirm that you wish to proceed with the change by clicking
          'Deactivate' or click 'Cancel' to back {content} Page.
        </h2>
        <div className="button-set">
          <button
            className="btn-light-outlined btn rounded"
            style={{ marginRight: "10px" }}
            onClick={closeModal}
          >
            Cancel
          </button>
          <button className="btn btn-primary rounded" onClick={confirmChange}>
            Deactivate
          </button>
        </div>
      </div>
    </Modal>
  );
};
export const LaunchCompany = ({
  modalIsOpen,
  closeModal,
  clickToSetupCompany,
  content,
}) => {
  return (
    <Modal isOpen={modalIsOpen} style={customStyles}>
      <div className="modal-main">
        <div className="modal-icon">
          <div className="modal-icon-tab delete">
            <i className="icon icon-launch" aria-hidden="true"></i>
          </div>
        </div>
        <h3> Are you sure you want to launch this {content}?</h3>
        <h2>
          Please confirm that you wish to proceed with the launch by clicking
          'Launch' or click 'Cancel' to keep the {content} and its data.
        </h2>
        <div className="button-set">
          <button
            className="btn-light-outlined btn rounded"
            style={{ marginRight: "10px" }}
            onClick={closeModal}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary rounded"
            onClick={clickToSetupCompany}
          >
            Launch
          </button>
        </div>
      </div>
    </Modal>
  );
};
export const EditButton = ({ editB, selectedRowKeys }) => {
  return (
    <button
      type="button"
      onClick={editB}
      className="btn-light-outlined btn rounded"
      disabled={selectedRowKeys.length === 1 ? false : true}
    >
      <i className="icon icon-pencil"></i>
      Edit
    </button>
  );
};
export const DeleteButton = ({ deleteB, selectedRowKeys, selectedRows }) => {
  return (
    <button
      type="button"
      onClick={deleteB}
      className="btn-light-outlined btn rounded"
      disabled={
        selectedRows
          ? selectedRowKeys.length === 1 &&
            !selectedRows[0].launch_subdomain_status
            ? false
            : true
          : selectedRowKeys.length > 0
          ? false
          : true
      }
      // disabled={selectedRowKeys.length > 0 ? false : true}
    >
      <i className="icon icon-trash"></i>
      Delete
    </button>
  );
};

const checkIsStatusInactive = (selectedRows) => {
  if (Array.isArray(selectedRows)) {
    // return selectedRows?.findIndex(item=>item.status==="I" )<=0

    if (selectedRows.length === 1) {
      // console.log((["I","T"].includes(selectedRows[0]?.status)))
      return ["A", "T", "P"].includes(selectedRows[0]?.status);
    } else {
      return true;
    }
  } else {
    true;
  }
};

export const ActiveButton = ({
  active,
  selectedRowKeys,
  name,
  selectedRows,
}) => {
  return (
    <button
      type="button"
      name={name}
      onClick={active}
      className="btn-light-outlined btn rounded"
      disabled={selectedRowKeys.length > 0 ? false : true}
      // disabled={
      //   selectedRowKeys.length !== 1 || checkIsStatusInactive(selectedRows)
      // }
    >
      <i className="icon icon-lock"></i>
      Activate
    </button>
  );
};
export const InactiveButton = ({ inactive, selectedRowKeys, name }) => {
  return (
    <button
      type="button"
      name={name}
      className="btn-light-outlined btn rounded"
      onClick={inactive}
      disabled={selectedRowKeys.length > 0 ? false : true}
    >
      <i className="icon icon-lock"></i>
      Deactivate
    </button>
  );
};
export const ChangeStatusButton = ({
  active,
  selectedRowKeys,
  name,
  changeStatus,
}) => {
  return (
    <div className="status-dropdown">
      <select
        className={
          selectedRowKeys.length > 0 ? "form-control " : "form-control disabled"
        }
        name="status"
        // disabled={true}
        disabled={selectedRowKeys.length > 0 ? false : true}
        onChange={changeStatus}
      >
        <option className="active" value="">
          Change Status
        </option>
        <option value="A">Active</option>
        <option value="I">Inactive</option>
      </select>
    </div>
  );
};
export const LaunchButton = ({
  launchModalOpen,
  selectedRows,
  selectedRowKeys,
}) => {
  return (
    <button
      type="button"
      onClick={launchModalOpen}
      className="btn-light-outlined btn rounded"
      disabled={
        selectedRowKeys.length === 1 && !selectedRows[0].launch_subdomain_status
          ? false
          : true
      }
    >
      <i className="icon icon-launch" />
      Launch
    </button>
  );
};

export const DownloadButton = ({ onClick, selectedRows, selectedRowKeys }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className="btn-light-outlined btn rounded"
      disabled={selectedRowKeys?.length !== 1}
    >
      {/* <i className="icon icon-launch" /> */}
      Download
    </button>
  );
};

export const SearchBox = ({ setSearchValue }) => {
  return (
    <div className="form-group  mb-0 abs-searchbox">
      <input
        type="text"
        className="form-control mb-0"
        placeholder="search"
        onChange={setSearchValue}
      />
      <i className="la la-search"></i>
    </div>
  );
};

export const ListingCardHeader = ({
  PageName,
  PageTitle,
  link,
  icon,
  PageIcon,
}) => {
  return (
    <div className="card-header">
      <div className="d-flex align-items-center justify-content-between">
        <div className="company-header-wrap d-flex align-items-center ">
          <div className={`c-icon ${icon}`}>
            <span className={`icon icon-${PageIcon}`}></span>
          </div>
          <div className="pl-3">
            <small>{PageTitle}</small>
            <h3 className="page-title">{PageName} List</h3>
          </div>
        </div>
        {PageName === "Invoice" ? (
          ""
        ) : (
          <Link to={`${link}`} className="btn btn-primary rounded btn-small">
            <i className="fa fa-plus" />{" "}
            {PageName === "Subscription" ? "Create" : "Add"} {PageName}
          </Link>
        )}
      </div>
    </div>
  );
};
export const ListingTable = ({ Data, columns, loader, rowSelection, id }) => {
  return (
    <div className="card-body pt-1">
      <div className="table-responsive">
        <Table
          className="table-swagatham"
          pagination={{
            total: Data.length,
            showTotal: (total, range) =>
              `${range[0]} - ${range[1]} of ${total} results`,
            defaultPageSize: 20,
            showSizeChanger: false,
            onShowSizeChange: onShowSizeChange,
            itemRender: itemRender,
          }}
          style={{ overflowX: "auto" }}
          columns={columns}
          locale={{
            emptyText: loader ? (
              id !== "product_id" ? (
                <TableSkeltonPlan />
              ) : (
                <TableSkeltonProduct />
              )
            ) : (
              <div className="nodata-intable">
                <figure>
                  <img src={EmptyBox} alt="box" className="img-fluid" />
                </figure>
                <h4>No Data Found</h4>
              </div>
            ),
          }}
          bordered
          scroll={{ x: 400 }}
          dataSource={Data}
          rowKey={id}
          rowSelection={{ ...rowSelection }}
        />
      </div>
    </div>
  );
};

export const AddUpdateCardHeader = ({
  PageName,
  companyName,
  cancel,
  PageId,
  PageIcon,
  icon,
}) => {
  return (
    <div className="card-body p-2">
      <div className="d-flex align-items-center justify-content-between">
        <div className="company-header-wrap d-flex align-items-center ">
          <div className={`c-icon ${icon}`}>
            <span className={`icon icon-${PageIcon}`}></span>
          </div>
          <div className="pl-3">
            <small>{PageName}</small>
            <h3 className="page-title">
              {companyName ? companyName : PageId ? "Edit" : "Add"}{" "}
              {companyName ? "" : PageName}
            </h3>
          </div>
        </div>
        <div className="company-header-btn">
          <div className="submit-n">
            <button
              onClick={cancel}
              className="btn-light-outlined btn btn-small rounded d-flex align-items-center mr-0"
            >
              {companyName ? <i className="fa fa-angle-left mr-2"></i> : ""}
              {companyName ? "Back" : "Cancel"}
            </button>

            {companyName ? (
              ""
            ) : (
              <button
                type="submit"
                className="btn btn-primary rounded btn-small ml-3"
              >
                {PageId ? "Update" : "Save"}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export const AddUpdateCardFooter = ({ cancel, PageId }) => {
  return (
    <div className="card footer-btn-card">
      <div className="card-body py-3 px-2 d-flex justify-content-end align-items-center">
        <button
          className="btn-light-outlined btn btn-small rounded mr-3"
          onClick={cancel}
        >
          Cancel
        </button>
        <button type="submit" className="btn btn-primary btn-small rounded">
          {PageId ? "Update" : "Save"}
        </button>
      </div>
    </div>
  );
};

export const DatePickerP = () => (
  <>
    <DatePicker
      defaultValue={dayjs("17/05/2023", dateFormatList[0])}
      format={dateFormatList}
    />
  </>
);
