import axios from "axios";
import { ERROR_MESSAGE } from "./ConstatntData";
import { clearAllItem, getItem, setItem, showError } from "./CommonService";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

let refreshTokenErrorEncountered = false;

instance.interceptors.request.use(
  (config) => {
    //const token = getItem("token", false);
    const token = localStorage.getItem("rememberme");
    if (token) {
      config.headers["Authorization"] = `token ${token}`;
    }

    if (config.url === "api/image-upload")
      config.headers["Content-Type"] = `multipart/form-data`;

    return config;
  },
  (error) => {
    return {
      ...error.response?.data,
      success: error.response?.data.success ?? 0,
    };
  }
);
instance.interceptors.response.use(
  (res) => {
    if (res.config.url === "/superadmin/invoice/invoice_download/") {
      return res;
    }
    return { ...res.data, success: res.data.success ?? 1 };
  },
  async (error) => {
    const originalConfig = error.config;
    if (!navigator.onLine) {
      return { message: `${ERROR_MESSAGE.noInternetConnection}`, success: 0 };
    } else if (
      error.response?.status === 401 &&
      !originalConfig._retry &&
      !window?.location?.pathname.startsWith("/")
    ) {
      originalConfig._retry = true;
      try {
        const rs = await instance.post("/auth/regenerate-token", {
          refresh_token: getItem("refreshToken", false),
          access_token: btoa(getItem("userDetails")?.id),
        });
        const { refreshToken, token } = rs.data;
        setItem("refreshToken", refreshToken, false);
        setItem("token", token, false);
        return instance(originalConfig);
      } catch (_error) {
        if (!refreshTokenErrorEncountered) {
          clearAllItem();
          // showError(ERROR_MESSAGE.tokenExpired);
          // refreshTokenErrorEncountered = true;
          // window.location.href = "/auth/login";
        }
      }
    }
    if (
      error.response &&
      ((error.response?.status === 401 &&
        window?.location?.pathname.startsWith("/auth/login")) ||
        (error.response?.status !== 403 && error.response?.status !== 401))
    ) {
      // showError(
      //   error.response?.data?.message || ERROR_MESSAGE.somethingWentWrong
      // );
    }
    return {
      ...error.response?.data,
      success: error.response?.data.success ?? 0,
      status: error.response?.status,
    };
  }
);
export { instance as api };
