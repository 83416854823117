import React from "react";
import DefaultPage from "./DefaultPage";

const InvoiceAddEdit = () => {
  return (
    <>
      <DefaultPage />
      <div className="page-wrapper supar-admin-page-wrapper">
        <div className="content container-fluid">
          <div className="card">
            <div className="card-body p-2">
              <div className="card-header">
                <div className="company-header-wrap left-header ">
                  <div className="c-icon">
                    <i className="fa fa-building"></i>
                  </div>
                  <div className="left-padding">
                    <span className="company-header">INVOICE MANAGEMENT</span>
                    <h3 className="super-page-title">Add Invoice</h3>
                  </div>
                </div>

                <div className="company-header-btn">
                  <div className="submit-n">
                    <button className="btn-light-outlined btn btn-small rounded mr-3">
                      Cancel
                    </button>

                    <button className="btn btn-primary rounded btn-small">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <form>
            <div className="card">
              <div className="card-header py-2">
                <h4 className="card-title mb-0">Basic Information</h4>
              </div>
              <div className="card-body">
                <div className="card-form-row">
                  <div className="column-swa">
                    <div className="form-group">
                      <label className="col-form-label">Company Name</label>
                      <input
                        placeholder="Tata Group"
                        className="form-control"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="column-swa">
                    <div className="form-group">
                      <label className="col-form-label">Branch Name</label>
                      <input
                        placeholder="Tata Kolkata"
                        className="form-control"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="column-swa">
                    <div className="form-group">
                      <label className="col-form-label">Company Website</label>
                      <input
                        placeholder="Tatagroup"
                        className="form-control"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="column-swa">
                    <div className="form-group clickEventUrlCheck">
                      <label className="col-form-label">
                        Company Sub Domain URL
                      </label>
                      <input
                        placeholder="tata.swagatham.co.in"
                        className="form-control "
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="column-swa">
                    <div className="form-group">
                      <label className="col-form-label">GST No.</label>
                      <input
                        placeholder="GSTIN123456789"
                        className="form-control"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="column-swa">
                    <div className="form-group">
                      <label className="col-form-label">
                        Sample Database Name
                      </label>
                      <input
                        placeholder="BookStoreDb"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="column-swa">
                    <div className="form-group">
                      <label className="col-form-label">Database Type</label>
                      <input placeholder="SWG" className="form-control" />
                    </div>
                  </div>
                  <div className="column-swa">
                    <div className="form-group">
                      <label className="col-form-label">Host Name</label>
                      <input placeholder="tatagroup" className="form-control" />
                    </div>
                  </div>
                  <div className="column-swa">
                    <div className="form-group">
                      <label className="col-form-label">
                        Database User Name
                      </label>
                      <input placeholder="postgress" className="form-control" />
                    </div>
                  </div>
                  <div className="column-swa">
                    <div className="form-group">
                      <label className="col-form-label">IP Address</label>
                      <input
                        placeholder="24.190.194.169"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="column-swa">
                    <div className="form-group">
                      <label className="col-form-label">No. Of Employee</label>
                      <input placeholder="10,000" className="form-control" />
                    </div>
                  </div>
                  <div className="column-swa">
                    <div className="form-group">
                      <label className="col-form-label">Status</label>
                      <select className=" form-control">
                        <option>Active</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header py-2">
                <h4 className="card-title mb-0">Address Information</h4>
              </div>
              <div className="card-body">
                <div className="card-form-row">
                  <div className="column-swa">
                    <div className="form-group">
                      <label className="col-form-label">Address 1</label>
                      <input
                        placeholder="10/1, Park Street"
                        className="form-control"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="column-swa">
                    <div className="form-group">
                      <label className="col-form-label">
                        Address 2 (Optional)
                      </label>
                      <input
                        placeholder="10/1, Park Street"
                        className="form-control"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="column-swa">
                    <div className="form-group">
                      <label className="col-form-label">City</label>
                      <select className=" form-control">
                        <option>Kolkata</option>
                      </select>
                    </div>
                  </div>
                  <div className="column-swa">
                    <div className="form-group">
                      <label className="col-form-label">State</label>
                      <select className=" form-control">
                        <option>West Bengal</option>
                      </select>
                    </div>
                  </div>
                  <div className="column-swa">
                    <div className="form-group">
                      <label className="col-form-label">Country</label>
                      <select className=" form-control">
                        <option>India</option>
                      </select>
                    </div>
                  </div>
                  <div className="column-swa">
                    <div className="form-group">
                      <label className="col-form-label">Pincode</label>
                      <input
                        placeholder="700016"
                        className="form-control"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="column-swa">
                    <div className="form-group">
                      <label className="col-form-label">Email Address</label>
                      <input
                        placeholder="info@tata.in"
                        className="form-control"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="column-swa">
                    <div className="form-group">
                      <label className="col-form-label">Mobile Number</label>
                      <input
                        placeholder="+91 9876787656"
                        className="form-control"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="card-form-row">
                  <div className="column-swa">
                    <div className="form-group">
                      <label className="col-form-label">Address 1</label>
                      <input
                        placeholder="10/1, Park Street"
                        className="form-control"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="column-swa">
                    <div className="form-group">
                      <label className="col-form-label">
                        Address 2 (Optional)
                      </label>
                      <input
                        className="form-control"
                        placeholder="10/1, Park Street"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="column-swa">
                    <div className="form-group">
                      <label className="col-form-label">City</label>
                      <select className=" form-control">
                        <option>Kolkata</option>
                      </select>
                    </div>
                  </div>
                  <div className="column-swa">
                    <div className="form-group">
                      <label className="col-form-label">State</label>
                      <select className=" form-control">
                        <option>West Bengal</option>
                      </select>
                    </div>
                  </div>
                  <div className="column-swa">
                    <div className="form-group">
                      <label className="col-form-label">Country</label>
                      <select className=" form-control">
                        <option>India</option>
                      </select>
                    </div>
                  </div>
                  <div className="column-swa">
                    <div className="form-group">
                      <label className="col-form-label">Pincode</label>
                      <input
                        placeholder="700016"
                        className="form-control"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="column-swa">
                    <div className="form-group">
                      <label className="col-form-label">Email Address</label>
                      <input
                        placeholder="info@tata.in"
                        className="form-control"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="column-swa">
                    <div className="form-group">
                      <label className="col-form-label">Mobile Number</label>
                      <input
                        placeholder="+91 9876787656"
                        className="form-control"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-header py-2">
                <h4 className="card-title mb-0">Contact Information</h4>
              </div>
              <div className="card-body">
                <div className="card-form-row">
                  <div className="column-swa">
                    <div className="form-group">
                      <label className="col-form-label">POC Name</label>
                      <input
                        placeholder="Rahul Sharma"
                        className="form-control"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="column-swa">
                    <div className="form-group">
                      <label className="col-form-label">POC Phone Number</label>
                      <input
                        placeholder="+91 9876787656"
                        className="form-control"
                        type="text"
                      />
                    </div>
                  </div>

                  <div className="column-swa">
                    <div className="form-group">
                      <label className="col-form-label">
                        POC Email Address
                      </label>
                      <input
                        placeholder="contact@tata.in"
                        className="form-control"
                        type="text"
                      />
                    </div>
                  </div>

                  <div className="column-swa">
                    <div className="form-group">
                      <label className="col-form-label">POC Status</label>
                      <select className=" form-control">
                        <option>Active</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header py-2">
                <h4 className="card-title mb-0">Login Information</h4>
              </div>
              <div className="card-body">
                <div className="card-form-row">
                  <div className="column-swa">
                    <div className="form-group">
                      <label className="col-form-label">
                        Login Email Address
                      </label>
                      <input
                        placeholder="login@tata.in"
                        className="form-control"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="column-swa">
                    <div className="form-group">
                      <label className="col-form-label">Password</label>
                      <input
                        className="form-control"
                        placeholder="*********"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header py-2">
                <h4 className="card-title mb-0">Subscription Information</h4>
              </div>
              <div className="card-body">
                <div className="card-form-row">
                  <div className="column-swa">
                    <div className="form-group">
                      <label className="col-form-label">Plan Name</label>
                      <select className=" form-control">
                        <option>Enterprise</option>
                      </select>
                    </div>
                  </div>
                  <div className="column-swa">
                    <div className="form-group">
                      <label className="col-form-label">Activation Date</label>
                      <input
                        className="form-control"
                        placeholder="02-03-2023 12:00 AM"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="column-swa">
                    <div className="form-group">
                      <label className="col-form-label">Status</label>
                      <select className=" form-control">
                        <option>Active</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header py-2">
                <h4 className="card-title mb-0">Payment Information</h4>
              </div>
              <div className="card-body">
                <div className="card-form-row">
                  <div className="column-swa">
                    <div className="form-group">
                      <label className="col-form-label">Payment Method</label>
                      <select className=" form-control">
                        <option>Net Banking</option>
                      </select>
                    </div>
                  </div>
                  <div className="column-swa">
                    <div className="form-group">
                      <label className="col-form-label">
                        Payment Reference Number
                      </label>
                      <input
                        className="form-control"
                        placeholder="PRN0001234456"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="column-swa">
                    <div className="form-group">
                      <label className="col-form-label">Payment Status</label>
                      <select className=" form-control">
                        <option>Successfull</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-header py-2">
                <h4 className="card-title mb-0">Bank Information</h4>
              </div>
              <div className="card-body">
                <div className="card-form-row">
                  <div className="column-swa">
                    <div className="form-group">
                      <label className="col-form-label">Bank Name</label>
                      <input
                        placeholder="State Bank Of India"
                        className="form-control"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="column-swa">
                    <div className="form-group">
                      <label className="col-form-label">
                        Account Holder Name
                      </label>
                      <input
                        placeholder="Tata Group"
                        className="form-control"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="column-swa">
                    <div className="form-group">
                      <label className="col-form-label">Account Number</label>
                      <input
                        placeholder="004856476t4785"
                        className="form-control"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="column-swa">
                    <div className="form-group">
                      <label className="col-form-label">IFSC Code</label>
                      <input
                        className="form-control"
                        placeholder="SBIN767667876"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card">
              <div
                className="card-body py-3 px-2"
                style={{ display: "flex", justifyContent: "end" }}
              >
                <button className="btn-light-outlined btn btn-small rounded mr-3">
                  Cancel
                </button>

                <button className="btn btn-primary btn-small rounded">
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default InvoiceAddEdit;
