import React, { useEffect, useState } from "react";
import DefaultPage from "./DefaultPage";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { api } from "../helper/Api";
import Loader from "../components/loader";

import { AddUpdateCardFooter, AddUpdateCardHeader } from "../components/UtilsAndModal";

const CompanyInfo = () => {
  const navigate = useNavigate();
  let { InvoiceId } = useParams();
  const [loader, setLoader] = useState(false);

  const [invoiceData, setInvoiceData] = useState({ companyName: '', planName: '', dateAndTime: '', productGroup: '', emailId: '', status: '', paymentMode: '', issuedOn: '', paidOn: '', transactionId: '', amount: '' });




  useEffect(() => {
    if (InvoiceId) {
      getInvoiceList();
    }
  }, []);
  const getInvoiceList = () => {
    setLoader(true)
    api.get(`/superadmin/invoice/list/?invoice_id=${InvoiceId}`).then((response) => {



      if (response.status_code === 200) {
        var data = response.data[0];
        setLoader(false)

        setInvoiceData({ companyName: data.company_name, planName: data.plan_name, dateAndTime: data.paid_date, productGroup: data.product_name, emailId: data.email, status: data.status, paymentMode: data.payment_mode, issuedOn: data.issue_date, paidOn: data.paid_date, transactionId: data.reference_code, amount: data.amount });
      } else {
        setLoader(false)

      }
    });
  };

  const cancel = () => {
    navigate("/invoices");
  };
  return (
    <>
      <DefaultPage />
      <div className="page-wrapper supar-admin-page-wrapper">
        <div className="content container-fluid">
          <div className="card">
            <AddUpdateCardHeader PageName='Invoice' companyName={invoiceData.companyName} icon='i' PageIcon='invoice' cancel={cancel} />


          </div>


          <div className="row sm-row">
            <div className="col-md-6 col-12">
              <div className="card">
                <div className="card-header py-2">
                  <h4 className="card-title mb-0">Basic Information</h4>
                </div>
                <div className="card-body">
                  <div className="card-form-row two-column">
                    <div className="column-swa">
                      <div className="form-group">
                        <label className="col-form-label view-cel">
                          <span className="view-label"> Company Name</span>
                          <span className="view-value">
                            {invoiceData.companyName}
                          </span>
                        </label>
                      </div>
                      <div className="form-group">
                        <label className="col-form-label view-cel">
                          <span className="view-label"> Plan Name</span>
                          <span className="view-value">
                            {invoiceData.planName}
                          </span>
                        </label>
                      </div>
                      <div className="form-group">
                        <label className="col-form-label view-cel">
                          <span className="view-label"> Date & Time</span>
                          <span className="view-value">
                            {invoiceData.dateAndTime}
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className="column-swa">
                      <div className="form-group">
                        <label className="col-form-label view-cel">
                          <span className="view-label"> Product Group</span>
                          <span className="view-value">
                            {invoiceData.productGroup}
                          </span>
                        </label>
                      </div>
                      <div className="form-group">
                        <label className="col-form-label view-cel">
                          <span className="view-label"> Email Id</span>
                          <span className="view-value">
                            {invoiceData.emailId}
                          </span>
                        </label>
                      </div>
                      <div className="form-group">
                        <label className="col-form-label view-cel">
                          <span className="view-label"> Status</span>
                          <span className="view-value">

                            {invoiceData.status === 'A' ? 'Active' : 'Inactive'}
                          </span>
                        </label>
                      </div>
                    </div>




                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="card">
                <div className="card-header py-2">
                  <h4 className="card-title mb-0">Payment Information</h4>
                </div>
                <div className="card-body">
                  <div className="card-form-row two-column">
                    <div className="column-swa">
                      <div className="form-group">
                        <label className="col-form-label view-cel">
                          <span className="view-label">

                            Payment Mode
                          </span>
                          <span className="view-value">
                            {invoiceData.paymentMode}
                          </span>
                        </label>
                      </div>
                      <div className="form-group">
                        <label className="col-form-label view-cel">
                          <span className="view-label">

                            Issued On
                          </span>
                          <span className="view-value">
                            {invoiceData.issuedOn}
                          </span>
                        </label>
                      </div>
                      <div className="form-group">
                        <label className="col-form-label view-cel">
                          <span className="view-label"> Paid On</span>
                          <span className="view-value">  {invoiceData.paidOn}</span>
                        </label>
                      </div>
                    </div>
                    <div className="column-swa">
                      <div className="form-group">
                        <label className="col-form-label view-cel">
                          <span className="view-label"> Transaction Id</span>
                          <span className="view-value">{invoiceData.transactionId}</span>
                        </label>
                      </div>
                      <div className="form-group">
                        <label className="col-form-label view-cel">
                          <span className="view-label"> Amount</span>
                          <span className="view-value"> ₹ {invoiceData.amount}</span>
                        </label>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
      {loader && <Loader />}
    </>
  );
};

export default CompanyInfo;
