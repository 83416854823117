import React, { useEffect, useState } from "react";
import DefaultPage from "./DefaultPage";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "../helper/Api";
import Moment from "moment";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY", "DD-MM-YYYY", "DD-MM-YY"];
import {
  AddUpdateCardFooter,
  AddUpdateCardHeader,
  DatePickerP,
} from "../components/UtilsAndModal";
import { Form, Formik } from "formik";
import { subscriptionSchema } from "../components/FormValidation";
import Swal from "sweetalert2";
import Loader from "../components/loader";
import moment from "moment";

//    const preFilledDate = moment('2023-05-23', 'YYYY-MM-DD');
//  const [selectedDate, setSelectedDate] = useState(preFilledDate);
//   const handleDateChange = (date) => {    setSelectedDate(date);  };
//   return (    <DatePicker defaultValue={preFilledDate} onChange={handleDateChange} />  );}

const SubscriptionAddEdit = () => {
  var date = new Date();
  var todayDate = dayjs(moment(date).format("YYYY-MM-DD"));
  const navigate = useNavigate();
  let { subscriptionId } = useParams();
  const [loader, setLoader] = useState(false);
  const [samShipingAddress, setShipingSameAddress] = useState("");
  const [countryData, setCountryData] = useState("");
  const [stateData, setStateData] = useState("");
  const [scountryData, setsCountryData] = useState("");
  const [sstateData, setsStateData] = useState("");
  const [countryId, setCountryId] = useState("1");
  const [scountryId, setsCountryId] = useState("1");
  const [billingData, setBillingData] = useState([]);
  const [billingType, setBillingType] = useState("");
  const [billingDate, setBillingDate] = useState(
    // dayjs(moment().format("YYYY-MM-DD"))
    todayDate
  );
  const [paymentMethodData, setPaymentMethodData] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [planData, setplanData] = useState([]);
  const [companyId, setCompanyId] = useState("");
  const [companyName1, setCompanyName1] = useState("");
  const [companyValue, setCompanyValue] = useState("");
  const [companyValueError, setCompanyValueError] = useState("");
  const [formValue, setFormValue] = useState({
    companyName: "",
    productFamily: "",
    plan: "",
    billingType: "",
    date: "",
    companyStatus: "A",
    billingAddress1: "",
    billingAddress2: "",
    billingCity: "",
    billingState: "",
    billingCountry: "1",
    billingPincode: "",
    billingEmail: "",
    billingMobile: "",
    sameAddress: "",
    shipingAddress1: "",
    shipingAddress2: "",
    shipingCity: "",
    shipingState: "",
    shipingCountry: "1",
    shipingPincode: "",
    shipingEmail: "",
    shipingMobile: "",
    PaymentMethod: "",
    PaymentRefrenceNumber: "",
    PaymentStatus: "",
  });

  const cancel = () => {
    navigate("/subscription");
  };
  const setSameAddress = (e) => {
    if (e.target.checked) {
      setShipingSameAddress("Y");
    } else {
      setShipingSameAddress("N");
    }
  };
  useEffect(() => {
    country();
    billingList();
    getPaymentMethod();
    getCompany();
    planList();
  }, []);
  useEffect(() => {
    if (subscriptionId) {
      getSubscriptionDetails();
    }
  }, []);
  useEffect(() => {
    if (companyId && !subscriptionId) {
      getCompanyList();
    }
  }, [companyId]);
  const getSubscriptionDetails = () => {
    setLoader(true);

    api
      .get(`/superadmin/subscription/list/?subscription_id=${subscriptionId}`)
      .then((response) => {
        if (response.status_code === 200) {
          setLoader(false);
          var data = response.data[0];

          setFormValue({
            plan: data.plan_price_map_id,
            billingType: data.billing_type_id,
            companyStatus: data.status,
            companyName: data.company_name,
            billingAddress1: data.billing_address1 ?? "",
            billingAddress2: data.billing_address2 ?? "",
            billingCity: data.billing_city ?? "",
            billingState: data.billing_state_id ? data.billing_state_id : "",
            billingCountry: data.billing_country_id
              ? data.billing_country_id
              : "",
            billingPincode: data.billing_pincode ? data.billing_pincode : "",
            billingEmail: data.billing_email ?? "",
            billingMobile: data.billing_mobile ?? "",
            shipingAddress1: data.shipping_address1 ?? "",
            shipingAddress2: data.shipping_address1 ?? "",
            shipingCity: data.shipping_city ?? "",
            shipingState: data.shipping_state_id ? data.shipping_state_id : "",
            shipingCountry: data.shipping_country_id ?? "1",
            shipingPincode: data.shipping_pincode ?? "",
            shipingEmail: data.shipping_email ?? "",
            shipingMobile: data.shipping_mobile ?? "",
            PaymentMethod: data.payment_type_id,
            PaymentRefrenceNumber: data.reference_code,
            PaymentStatus: data.payment_status,
          });
          setCompanyId(data.company_id);

          if (data.billing_type_id == 1) {
            billingTypeChangeHandler(data.billing_type_id);
          } else {
            setBillingDate(
              dayjs(moment(data.billing_date).format("YYYY-MM-DD"))
            );
          }

          // setBillingDate(data.billing_date.slice(0, 10));
          setShipingSameAddress(data.bill_ship_same);
          setCompanyName1(data.company_name);
        } else {
          setLoader(false);
        }
      });
  };
  const getCompany = () => {
    api.get(`/superadmin/company/listing/?company_id=0`).then((response) => {
      if (response.status_code === 200) {
        let data = response.data;
        setCompanyData(data);
      } else {
      }
    });
  };
  const getCompanyList = () => {
    setLoader(true);
    api
      .get(`/superadmin/company/listing/?company_id=${companyId}`)
      .then((response) => {
        if (response.status_code === 200) {
          setLoader(false);
          var data = response.data[0];

          setFormValue((prevState) => ({
            ...prevState,
            billingAddress1: data.billing_address1 ?? "",
            billingAddress2: data.billing_address2 ?? "",
            billingCity: data.billing_city ?? "",
            billingState: data.billing_state,
            billingCountry: "1",
            billingPincode: data.billing_pincode ?? "",
            billingEmail: data.billing_email ?? "",
            billingMobile: data.billing_mobile ?? "",
            shipingAddress1: data.shipping_address1 ?? "",
            shipingAddress2: data.shipping_address2 ?? "",
            shipingCity: data.shipping_city ?? "",
            shipingState: data.shipping_state,
            shipingCountry: "1",
            shipingPincode: data.shipping_pincode ?? "",
            shipingEmail: data.shipping_email ?? "",
            shipingMobile: data.shipping_mobile ?? "",
          }));
          setShipingSameAddress(data.bill_ship_same);
        } else {
          setLoader(false);
        }
      });
  };

  const getPaymentMethod = () => {
    api
      .get("/superadmin/subscription/payment_type_listing/")
      .then((response) => {
        if (response.status_code === 200) {
          setPaymentMethodData(response.data);
        } else {
        }
      });
  };

  const billingList = () => {
    api
      .get(`/superadmin/subscription/billing_type_listing/`)
      .then((response) => {
        if (response.status_code === 200) {
          var data = response.data;
          setBillingData(data);
        } else {
        }
      });
  };
  const planList = () => {
    api.get(`/superadmin/subscription/plan_subs_listing/`).then((response) => {
      if (response.status_code === 200) {
        var data = response.data;
        setplanData(data);
      } else {
      }
    });
  };

  const country = () => {
    api.post(`/superadmin/company/country`).then((response) => {
      if (response.status_code == 200) {
        var data = response.data;
        setsCountryData(data);
        setCountryData(data);
      }
    });
  };

  useEffect(() => {
    if (countryId || scountryId) {
      state();
    }
  }, [countryId, scountryId]);
  const state = () => {
    api
      .put(`/superadmin/company/country`, { country_id: countryId })
      .then((response) => {
        if (response.status_code == 200) {
          var data = response.data;

          setStateData(data);
          setsStateData(data);
        }
      });
  };

  // const setJoinDate = (e) => {
  //   // const value = e.format();
  //   // setBillingDate(Moment(e).format("YYYY-MM-DD"));
  // };

  const setCompany = (e) => {
    setCompanyValue(e.target.value);
    setCompanyName1("");
  };
  const onSearch = (searchTerm) => {
    setCompanyValue(searchTerm.name);
    setCompanyId(searchTerm.company_id);
  };

  // const companyErrorCheck = () => {
  //   if (!companyValue && !companyName1) {
  //     setCompanyValueError("Company Name is required");
  //   } else {
  //     setCompanyValueError("");
  //   }
  // };

  // const isBlank = (data) => {
  //   if (data === "" || data === "" || data == null || data === "null") {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };
  const onSubmit = (values) => {
    setLoader(true);

    const {
      PaymentMethod,
      PaymentRefrenceNumber,
      PaymentStatus,
      billingAddress1,
      billingAddress2,
      billingCity,
      billingCountry,
      billingEmail,
      billingMobile,
      billingPincode,
      billingState,
      billingType,
      companyStatus,
      plan,
      shipingAddress1,
      shipingAddress2,
      shipingCity,
      shipingCountry,
      shipingEmail,
      shipingMobile,
      shipingPincode,
      shipingState,
    } = values;

    // if (isBlank(companyName1 || companyValue)) {
    //   setCompanyValueError("Company Name is required");
    // }
    {
      let formData = new FormData();
      formData.append("id", subscriptionId ? subscriptionId : "");
      formData.append("company_id", companyId);
      formData.append("billing_type_id", billingType);
      formData.append("plan_price_map_id", plan);
      formData.append(
        "billing_date",
        (billingDate ? new Date(billingDate) : new Date()).toISOString()
      );
      formData.append("billing_address1", billingAddress1);
      formData.append("billing_address2", billingAddress2);
      formData.append("billing_email", billingEmail);
      formData.append("billing_mobile", billingMobile);
      formData.append("billing_country_id", billingCountry);
      formData.append("billing_state_id:", billingState);
      formData.append("billing_city", billingCity);
      formData.append("billing_pincode", billingPincode);
      formData.append(
        "bill_ship_same",
        samShipingAddress ? samShipingAddress : "N"
      );
      formData.append("shipping_address1", shipingAddress1);
      formData.append("shipping_address2", shipingAddress2);
      formData.append("shipping_email", shipingEmail);
      formData.append("shipping_mobile", shipingMobile);
      formData.append("shipping_country_id", shipingCountry);
      formData.append("shipping_state_id:", shipingState);
      formData.append("shipping_city", shipingCity);
      formData.append("shipping_pincode", shipingPincode);
      formData.append("payment_type_id", PaymentMethod ? PaymentMethod : "");
      formData.append(
        "reference_code",
        PaymentRefrenceNumber ? PaymentRefrenceNumber : ""
      );
      formData.append("status", companyStatus);
      formData.append("payment_status", PaymentStatus ? PaymentStatus : "");

      {
        (subscriptionId
          ? api.put(`/superadmin/subscription/update/`, formData)
          : api.post(`/superadmin/subscription/create/`, formData)
        ).then(function (response) {
          if (response.status_code === 200) {
            navigate("/subscription");
            setLoader(false);
          } else if (response.status_code === 400) {
            Swal.fire({
              position: "top-end",
              title: response.message
                ? response.message
                : "Fill all *Required field",
              showConfirmButton: false,
              backdrop: false,
              timer: 3000,
            });
            setLoader(false);
          }
        });
      }
    }
  };
  // console.log(billingDate, "sssssss");

  // console.log(todayDate, "todayDate");

  const billingTypeChangeHandler = (selectedValue) => {
    setBillingType(selectedValue);
    if (selectedValue == "1") {
      setBillingDate(dayjs());
    }
  };

  return (
    <>
      <DefaultPage />
      <div className="page-wrapper supar-admin-page-wrapper">
        <div className="content container-fluid">
          <Formik
            initialValues={formValue}
            validationSchema={subscriptionSchema}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {({
              errors,
              values,
              touched,
              handleChange,
              handleBlur,
              setFieldValue,
            }) => (
              <Form autoComplete="off">
                <div className="card">
                  <AddUpdateCardHeader
                    PageName="Subscription"
                    icon="s"
                    PageIcon="money"
                    cancel={cancel}
                    PageId={subscriptionId}
                  />
                </div>
                <div className="card">
                  <div className="card-header py-2">
                    <h4 className="card-title mb-0">Basic Information</h4>
                  </div>
                  <div className="card-body">
                    <div className="card-form-row">
                      <div className="column-swa">
                        <div className="form-group">
                          <label className="col-form-label ">
                            Company Name<sup className="text-danger">*</sup>
                          </label>
                          <input
                            // placeholder="10/1, Park Street"
                            className={`form-control ${
                              touched.companyName && errors.companyName
                                ? "error-input"
                                : ""
                            }`}
                            type="text"
                            value={companyValue ? companyValue : companyName1}
                            onChange={(event) => {
                              handleChange(event);
                              setCompany(event);
                            }}
                            onBlur={handleBlur}
                            name="companyName"
                            autoComplete="none"
                          />
                          <span className="input-error">
                            {touched.companyName && errors.companyName
                              ? errors.companyName
                              : null}
                          </span>
                          <span className="input-icon">
                            {" "}
                            <i className="la la-search"></i>
                          </span>
                          <ul className="dropdown-autoComplete">
                            {companyData
                              .filter((item) => {
                                const searchTerm = companyValue
                                  .toString()
                                  .toLowerCase();
                                const Name = item.name.toString().toLowerCase();
                                return (
                                  searchTerm &&
                                  Name.startsWith(searchTerm) &&
                                  Name !== searchTerm
                                );
                              })
                              .map((item) => (
                                <li
                                  key={item.company_id}
                                  onClick={() => {
                                    onSearch(item);
                                    setFieldValue("companyName", item.name);
                                  }}
                                  value={item.company_id}
                                  style={{ cursor: "pointer" }}
                                  className="dropdown-row"
                                >
                                  {item.name}
                                </li>
                              ))}
                          </ul>
                        </div>
                      </div>
                      <div className="column-swa">
                        <div className="form-group">
                          <label className="col-form-label">
                            Plan<sup className="text-danger">*</sup>
                          </label>
                          <select
                            className={`form-control ${
                              touched.plan && errors.plan ? "error-input" : ""
                            }`}
                            name="plan"
                            value={values.plan || ""}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          >
                            <option>Select Plan</option>
                            {planData
                              ? planData.map((data) => (
                                  <option value={data.plan_price_map_id}>
                                    {data.external_name}
                                  </option>
                                ))
                              : ""}
                          </select>
                          <span className="input-error">
                            {touched.plan && errors.plan ? errors.plan : null}
                          </span>
                        </div>
                      </div>
                      <div className="column-swa">
                        <div className="form-group">
                          <label className="col-form-label">
                            Billing Type<sup className="text-danger">*</sup>
                          </label>
                          <select
                            className={`form-control ${
                              touched.billingType && errors.billingType
                                ? "error-input"
                                : ""
                            }`}
                            name="billingType"
                            value={values.billingType || ""}
                            onBlur={handleBlur}
                            onChange={(event) => {
                              handleChange(event);

                              billingTypeChangeHandler(event.target.value);
                            }}
                          >
                            <option>Select Billing Type</option>
                            {billingData
                              ? billingData.map((data) => (
                                  <option value={data.billing_type_id}>
                                    {data.billing_type_name}
                                  </option>
                                ))
                              : ""}
                          </select>
                          <span className="input-error">
                            {touched.billingType && errors.billingType
                              ? errors.billingType
                              : ""}
                          </span>
                        </div>
                      </div>

                      <div className="column-swa">
                        <div className="form-group">
                          <label className="col-form-label">Date</label>
                          <span className="input-icon">
                            <i className="icon icon-calender"></i>
                          </span>
                          <div>
                            <DatePicker
                              // defaultValue={dayjs()}
                              value={billingDate}
                              // format={dateFormatList}
                              onChange={(date, dateString) => {
                                setBillingDate(dayjs(dateString));
                              }}
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="column-swa">
                        <div className="form-group">
                          <label className="col-form-label">
                            Status<sup className="text-danger">*</sup>
                          </label>
                          <select
                            className={`form-control ${
                              touched.companyStatus && errors.companyStatus
                                ? "error-input"
                                : ""
                            }`}
                            name="companyStatus"
                            value={values.companyStatus || ""}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          >
                            <option value="">Select Status</option>
                            <option value="A">Active</option>
                            <option value="I">Inactive</option>
                          </select>
                          <span className="input-error">
                            {touched.companyStatus && errors.companyStatus
                              ? errors.companyStatus
                              : null}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header py-2">
                    <h4 className="card-title mb-0">Address Information</h4>
                  </div>
                  <div className="card-body">
                    <div className="mb-2">
                      <label className="form-c-label">Billing Address</label>
                    </div>

                    <div className="card-form-row">
                      <div className="column-swa">
                        <div className="form-group">
                          <label className="col-form-label">Address 1</label>
                          <input
                            // placeholder="10/1, Park Street"
                            className={`form-control ${
                              touched.billingAddress1 && errors.billingAddress1
                                ? "error-input"
                                : ""
                            }`}
                            type="text"
                            name="billingAddress1"
                            value={values.billingAddress1 || ""}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            autoComplete="none"
                          />
                          <span className="input-error">
                            {touched.billingAddress1 && errors.billingAddress1
                              ? errors.billingAddress1
                              : null}
                          </span>
                        </div>
                      </div>
                      <div className="column-swa">
                        <div className="form-group">
                          <label className="col-form-label">
                            Address 2 (Optional)
                          </label>
                          <input
                            // placeholder="10/1, Park Street"
                            className={`form-control ${
                              touched.billingAddress2 && errors.billingAddress2
                                ? "error-input"
                                : ""
                            }`}
                            type="text"
                            name="billingAddress2"
                            value={values.billingAddress2 || ""}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            autoComplete="none"
                          />
                          <span className="input-error">
                            {touched.billingAddress2 && errors.billingAddress2
                              ? errors.billingAddress2
                              : null}
                          </span>
                        </div>
                      </div>

                      <div className="column-swa">
                        <div className="form-group">
                          <label className="col-form-label">City</label>
                          <input
                            className={`form-control ${
                              touched.billingCity && errors.billingCity
                                ? "error-input"
                                : ""
                            }`}
                            // placeholder="Kolkata"
                            type="text"
                            value={values.billingCity || ""}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            name="billingCity"
                            autoComplete="none"
                          />
                          <span className="input-error">
                            {touched.billingCity && errors.billingCity
                              ? errors.billingCity
                              : null}
                          </span>
                        </div>
                      </div>
                      <div className="column-swa">
                        <div className="form-group">
                          <label className="col-form-label">State</label>
                          <select
                            className=" form-control"
                            value={values.billingState || ""}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            name="billingState"
                          >
                            <option>Select State</option>
                            {stateData
                              ? stateData.map((state) => (
                                  <option value={state.id}>{state.name}</option>
                                ))
                              : ""}
                          </select>
                        </div>
                      </div>
                      <div className="column-swa">
                        <div className="form-group">
                          <label className="col-form-label">Country</label>
                          <select
                            className=" form-control"
                            value={values.billingCountry || ""}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            name="billingCountry"
                          >
                            <option>Select Country</option>
                            {countryData
                              ? countryData.map((country) => (
                                  <option value={country.id}>
                                    {country.name}
                                  </option>
                                ))
                              : ""}
                          </select>
                        </div>
                      </div>

                      <div className="column-swa">
                        <div className="form-group">
                          <label className="col-form-label">Pincode</label>
                          <input
                            // placeholder="700016"
                            className={`form-control ${
                              touched.billingPincode && errors.billingPincode
                                ? "error-input"
                                : ""
                            }`}
                            type="text"
                            name="billingPincode"
                            value={values.billingPincode || ""}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            autoComplete="none"
                          />
                          <span className="input-error">
                            {touched.billingPincode && errors.billingPincode
                              ? errors.billingPincode
                              : null}
                          </span>
                        </div>
                      </div>
                      <div className="column-swa">
                        <div className="form-group">
                          <label className="col-form-label">
                            Email Address
                          </label>
                          <input
                            // placeholder="info@tata.in"
                            className={`form-control ${
                              touched.billingEmail && errors.billingEmail
                                ? "error-input"
                                : ""
                            }`}
                            type="text"
                            name="billingEmail"
                            value={values.billingEmail || ""}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            autoComplete="none"
                          />
                          <span className="input-error">
                            {touched.billingEmail && errors.billingEmail
                              ? errors.billingEmail
                              : null}
                          </span>
                        </div>
                      </div>
                      <div className="column-swa">
                        <div className="form-group">
                          <label className="col-form-label">
                            Mobile Number
                          </label>
                          <input
                            // placeholder="+91 9876787656"
                            className={`form-control ${
                              touched.billingMobile && errors.billingMobile
                                ? "error-input"
                                : ""
                            }`}
                            type="text"
                            name="billingMobile"
                            value={values.billingMobile || ""}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            autoComplete="none"
                          />
                          <span className="input-error">
                            {touched.billingMobile && errors.billingMobile
                              ? errors.billingMobile
                              : null}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="form-check d-flex align-items-center form-group">
                      <input
                        className="messageCheckbox form-check-input"
                        checked={samShipingAddress === "Y" ? true : false}
                        type="checkbox"
                        value="Y"
                        onChange={setSameAddress}
                        id="shippingaddress"
                      />

                      <label
                        className="form-check-label"
                        htmlFor="shippingaddress"
                      >
                        Same As Shipping Address
                      </label>
                    </div>

                    {samShipingAddress === "Y" ? (
                      ""
                    ) : (
                      <div className="card-form-row">
                        <div className="column-swa">
                          <div className="form-group">
                            <label className="col-form-label">Address 1</label>
                            <input
                              // placeholder="10/1, Park Street"
                              className={`form-control ${
                                touched.shipingAddress1 &&
                                errors.shipingAddress1
                                  ? "error-input"
                                  : ""
                              }`}
                              type="text"
                              name="shipingAddress1"
                              value={values.shipingAddress1 || ""}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              autoComplete="none"
                            />
                            <span className="input-error">
                              {touched.shipingAddress1 && errors.shipingAddress1
                                ? errors.shipingAddress1
                                : null}
                            </span>
                          </div>
                        </div>
                        <div className="column-swa">
                          <div className="form-group">
                            <label className="col-form-label">
                              Address 2 (Optional)
                            </label>
                            <input
                              className={`form-control ${
                                touched.shipingAddress2 &&
                                errors.shipingAddress2
                                  ? "error-input"
                                  : ""
                              }`}
                              // placeholder="10/1, Park Street"
                              type="text"
                              value={values.shipingAddress2 || ""}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              name="shipingAddress2"
                              autoComplete="none"
                            />
                            <span className="input-error">
                              {touched.shipingAddress2 && errors.shipingAddress2
                                ? errors.shipingAddress2
                                : null}
                            </span>
                          </div>
                        </div>

                        <div className="column-swa">
                          <div className="form-group">
                            <label className="col-form-label">City</label>
                            <input
                              className={`form-control ${
                                touched.shipingCity && errors.shipingCity
                                  ? "error-input"
                                  : ""
                              }`}
                              //placeholder="Kolkata"
                              type="text"
                              value={values.shipingCity || ""}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              name="shipingCity"
                              autoComplete="none"
                            />
                            <span className="input-error">
                              {touched.shipingCity && errors.shipingCity
                                ? errors.shipingCity
                                : null}
                            </span>
                          </div>
                        </div>

                        <div className="column-swa">
                          <div className="form-group">
                            <label className="col-form-label">State</label>
                            <select
                              className=" form-control"
                              value={values.shipingState || ""}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              name="shipingState"
                            >
                              <option>Select State</option>
                              {sstateData
                                ? sstateData.map((state) => (
                                    <option value={state.id}>
                                      {state.name}
                                    </option>
                                  ))
                                : ""}
                            </select>
                          </div>
                        </div>

                        <div className="column-swa">
                          <div className="form-group">
                            <label className="col-form-label">Country</label>
                            <select
                              className=" form-control"
                              value={values.shipingCountry || ""}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              name="shipingCountry"
                            >
                              <option>Select Country</option>
                              {scountryData
                                ? scountryData.map((country) => (
                                    <option value={country.id}>
                                      {country.name}
                                    </option>
                                  ))
                                : ""}
                            </select>
                          </div>
                        </div>

                        <div className="column-swa">
                          <div className="form-group">
                            <label className="col-form-label">Pincode</label>
                            <input
                              // placeholder="700016"
                              className={`form-control ${
                                touched.shipingPincode && errors.shipingPincode
                                  ? "error-input"
                                  : ""
                              }`}
                              type="text"
                              name="shipingPincode"
                              value={values.shipingPincode || ""}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              autoComplete="none"
                            />
                            <span className="input-error">
                              {touched.shipingPincode && errors.shipingPincode
                                ? errors.shipingPincode
                                : null}
                            </span>
                          </div>
                        </div>
                        <div className="column-swa">
                          <div className="form-group">
                            <label className="col-form-label">
                              Email Address
                            </label>
                            <input
                              // placeholder="info@tata.in"
                              className={`form-control ${
                                touched.shipingEmail && errors.shipingEmail
                                  ? "error-input"
                                  : ""
                              }`}
                              type="text"
                              name="shipingEmail"
                              value={values.shipingEmail || ""}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              autoComplete="none"
                            />
                            <span className="input-error">
                              {touched.shipingEmail && errors.shipingEmail
                                ? errors.shipingEmail
                                : null}
                            </span>
                          </div>
                        </div>
                        <div className="column-swa">
                          <div className="form-group">
                            <label className="col-form-label">
                              Mobile Number
                            </label>
                            <input
                              // placeholder="+91 9876787656"
                              className={`form-control ${
                                touched.shipingMobile && errors.shipingMobile
                                  ? "error-input"
                                  : ""
                              }`}
                              type="text"
                              name="shipingMobile"
                              value={values.shipingMobile || ""}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              autoComplete="none"
                            />
                            <span className="input-error">
                              {touched.shipingMobile && errors.shipingMobile
                                ? errors.shipingMobile
                                : null}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {values.companyStatus === "A" ? (
                  <div className="card">
                    <div className="card-header py-2">
                      <h4 className="card-title mb-0">Payment Information</h4>
                    </div>
                    <div className="card-body">
                      <div className="card-form-row four-column">
                        <div className="column-swa">
                          <div className="form-group">
                            <label className="col-form-label">
                              Payment Method
                            </label>
                            <select
                              className=" form-control"
                              name="PaymentMethod"
                              value={values.PaymentMethod || ""}
                              onBlur={handleBlur}
                              onChange={handleChange}
                            >
                              <option>Select Payment Method</option>
                              {paymentMethodData
                                ? paymentMethodData.map((data) => (
                                    <option value={data.payment_type_id}>
                                      {data.name}
                                    </option>
                                  ))
                                : ""}
                            </select>
                          </div>
                        </div>
                        <div className="column-swa">
                          <div className="form-group">
                            <label className="col-form-label">
                              Payment Reference Number
                            </label>
                            <input
                              className="form-control"
                              // placeholder="PRN0001234456"
                              type="text"
                              name="PaymentRefrenceNumber"
                              value={values.PaymentRefrenceNumber || ""}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              autoComplete="none"
                            />
                          </div>
                        </div>
                        <div className="column-swa">
                          <div className="form-group">
                            <label className="col-form-label">
                              Payment Status
                            </label>
                            <select
                              className=" form-control"
                              name="PaymentStatus"
                              value={values.PaymentStatus || ""}
                              onBlur={handleBlur}
                              onChange={handleChange}
                            >
                              <option>Select Payment Status</option>
                              <option value="S">Successfull</option>
                              <option value="U">Unsuccessfull</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <AddUpdateCardFooter cancel={cancel} PageId={subscriptionId} />
              </Form>
            )}
          </Formik>
        </div>
      </div>
      {loader && <Loader />}
    </>
  );
};

export default SubscriptionAddEdit;
