import { configureStore } from '@reduxjs/toolkit';
import RootReducers from './features/RootReducers';
import companySaga from './features/Saga/CompanySaga';
import createSagaMiddleware from 'redux-saga';

const sagaMiddleware=createSagaMiddleware();


export default configureStore({
  reducer: RootReducers,
  middleware:()=>[sagaMiddleware]
})


sagaMiddleware.run(companySaga)