import { SETUSER,USERLOGOUT } from "../Constant";

export const UserData = (data = {}, action) => {
  switch (action.type) {
    case SETUSER:
//console.warn("reducer user called", action);
      return {
        ...data,
        user: action.data,
        loggedIn: true
    };
    case USERLOGOUT:
     // console.warn("reducer logout called", action);
      return {
        ...data,
        user: {},
        loggedIn: false
    }
   
    default:
      //console.warn("nodata", action);

      return data;
  }
 
};
