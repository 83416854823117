import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
export const TableSkeltonPlan = () => {
  return (
    <div className="table-swagatham">
      <table className="w-100" >
        <tbody className="ant-table-tbody" >
          <tr>
            <td width='50'  ><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>

          </tr>
          <tr>
            <td width='50'  ><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>

          </tr> <tr>
            <td width='50'  ><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>

          </tr> <tr>
            <td width='50'  ><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>

          </tr> <tr>
            <td width='50'  ><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>

          </tr> <tr>
            <td width='50'  ><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>

          </tr> <tr>
            <td width='50'  ><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>

          </tr> <tr>
            <td width='50'  ><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>

          </tr> <tr>
            <td width='50'  ><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>

          </tr> <tr>
            <td width='50'  ><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>

          </tr> <tr>
            <td width='50'  ><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>

          </tr> <tr>
            <td width='50'  ><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>

          </tr> <tr>
            <td width='50'  ><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>

          </tr> <tr>
            <td width='50'  ><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>

          </tr> <tr>
            <td width='50'  ><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>

          </tr> <tr>
            <td width='50'  ><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>

          </tr> <tr>
            <td width='50'  ><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>

          </tr> <tr>
            <td width='50'  ><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>

          </tr> <tr>
            <td width='50'  ><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>

          </tr> <tr>
            <td width='50'  ><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>

          </tr> <tr>
            <td width='50'  ><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>

          </tr> <tr>
            <td width='50'  ><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>

          </tr>
          <tr>
            <td width='50'  ><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>

          </tr> <tr>
            <td width='50'  ><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>

          </tr> <tr>
            <td width='50'  ><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>

          </tr> <tr>
            <td width='50'  ><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>

          </tr> <tr>
            <td width='50'  ><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>

          </tr>





        </tbody>
      </table>
    </div>
  )
}
export const TableSkeltonProduct = () => {
  return (
    <div className="table-swagatham">
      <table className="w-100" >
        <tbody className="ant-table-tbody" >
          <tr>
            <td width='50'  ><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>

          </tr>
          <tr>
            <td width='50'  ><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>

          </tr> <tr>
            <td width='50'  ><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>

          </tr> <tr>
            <td width='50'  ><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>

          </tr> <tr>
            <td width='50'  ><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>

          </tr> <tr>
            <td width='50'  ><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>

          </tr> <tr>
            <td width='50'  ><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>

          </tr> <tr>
            <td width='50'  ><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>

          </tr> <tr>
            <td width='50'  ><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>

          </tr> <tr>
            <td width='50'  ><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>

          </tr> <tr>
            <td width='50'  ><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>

          </tr> <tr>
            <td width='50'  ><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>

          </tr> <tr>
            <td width='50'  ><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>

          </tr> <tr>
            <td width='50'  ><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>

          </tr> <tr>
            <td width='50'  ><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>

          </tr> <tr>
            <td width='50'  ><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>

          </tr> <tr>
            <td width='50'  ><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>

          </tr> <tr>
            <td width='50'  ><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>

          </tr> <tr>
            <td width='50'  ><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>

          </tr> <tr>
            <td width='50'  ><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>

          </tr> <tr>
            <td width='50'  ><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>

          </tr> <tr>
            <td width='50'  ><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>

          </tr> <tr>
            <td width='50'  ><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>

          </tr> <tr>
            <td width='50'  ><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>

          </tr> <tr>
            <td width='50'  ><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>

          </tr> <tr>
            <td width='50'  ><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>

          </tr> <tr>
            <td width='50'  ><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>
            <td className="ant-table-cell"><Skeleton /></td>

          </tr>






        </tbody>
      </table>
    </div>
  )
}

