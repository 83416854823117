import React, { useState, useEffect } from "react";
import { api } from "../helper/Api";
import { Link, useNavigate } from "react-router-dom";
import DefaultPage from "./DefaultPage";
import Loader from "../components/loader";
import Swal from "sweetalert2";

import {
  ActiveButton,
  DeleteButton,
  DeleteModal,
  EditButton,
  InactiveButton,
  ListingCardHeader,
  ListingTable,
  SearchBox,
} from "../components/UtilsAndModal";
const Companies = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  const [ButtonLoader, setButtonLoader] = useState(false);
  const [leadData, setLeadData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [listingSearchValue, setListingSearchValue] = useState("");

  const [modalIsOpen, setModalIsOpen] = useState({
    deleteModal: false,
    urlModal: false,
  });

  useEffect(() => {
    getLeadList();
  }, [listingSearchValue]);

  const Logout = () => {
    localStorage.clear();
    navigate("/superadminlogin");
  };

  const getLeadList = () => {
    api
      .get(`/superadmin/listingcontact/?search=${listingSearchValue}`)
      .then((response) => {
        setLoader(false);

        if (response.status_code === 200) {
          var data = response.data;

          setLeadData(data);
        } else {
          Swal.fire({
            position: "top-end",
            title: response.message ? response.message : "Somthing went wrong",
            showConfirmButton: false,
            backdrop: false,
            timer: 5000,
          });

          Logout();
        }
      })
      .catch((error) => {
        setLoader(false);
        Swal.fire({
          position: "top-end",
          title: `Error: ${error}`,
          showConfirmButton: false,
          backdrop: false,
          timer: 5000,
        });

        Logout();
      });
  };

  const columns = [
    {
      title: "Status",

      render: (a) => (
        <>
          <span
            className={`status-label ${
              a.status === "A"
                ? "active"
                : a.status === "P"
                ? "inactive"
                : "inactive"
            }`}
          >
            {a.status === "A"
              ? "Active"
              : a.status === "P"
              ? "Pending"
              : a.status === "I"
              ? "Inactive"
              : a.status === "Ap"
              ? "Approved"
              : a.status === "R"
              ? "Reject"
              : a.status === "T"
              ? "Trial"
              : a.status === "SE"
              ? "S.Expired"
              : a.status === "SP"
              ? "S.Pending"
              : ""}
          </span>
        </>
      ),
    },
    {
      title: "Company Status",
      // dataIndex: "company_status",
      render: (a) => (
        <>
          <span style={a.company_status ? { color: "red" } : {}}>
            {a.company_status ? "Deleted" : ""}
          </span>
        </>
      ),
    },
    {
      title: "First Name",
      dataIndex: "fname",
    },

    {
      dataIndex: "lname",
      title: "Last Name",
    },
    {
      dataIndex: "email",
      title: (titleProps) => {
        const sortedColumn = titleProps.sortColumns?.find(
          ({ column }) => column.key === "name"
        );

        return (
          <div className="d-flex table-filter">
            <span>Email Address</span>
            <div className="filter-arrow">
              {sortedColumn?.order === "ascend" ? (
                <i className="la la-arrow-up"></i>
              ) : (
                <i className="la la-arrow-down"></i>
              )}
            </div>
          </div>
        );
      },
    },
    {
      title: "Phone No.",
      dataIndex: "phone_number",
    },

    {
      dataIndex: "company_name",
      title: (titleProps) => {
        const sortedColumn = titleProps.sortColumns?.find(
          ({ column }) => column.key === "name"
        );

        return (
          <div className="d-flex table-filter">
            <span>Company</span>
            <div className="filter-arrow">
              {sortedColumn?.order === "ascend" ? (
                <i className="la la-arrow-up"></i>
              ) : (
                <i className="la la-arrow-down"></i>
              )}
            </div>
          </div>
        );
      },
    },
    {
      title: "Description ",
      dataIndex: "message",
    },

    {
      title: "Created Date ",
      dataIndex: "created_date",
    },

    {
      dataIndex: "modified_date",
      title: (titleProps) => {
        const sortedColumn = titleProps.sortColumns?.find(
          ({ column }) => column.key === "name"
        );

        return (
          <div className="d-flex table-filter">
            <span>Modified Date </span>
            <div className="filter-arrow">
              {sortedColumn?.order === "ascend" ? (
                <i className="la la-arrow-up"></i>
              ) : (
                <i className="la la-arrow-down"></i>
              )}
            </div>
          </div>
        );
      },
    },
  ];
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",

      name: record.name,
    }),
  };
  const edit = () => {
    navigate(`/company-add-edit/${selectedRowKeys}`);
  };
  const deleteLead = () => {
    setModalIsOpen({ deleteModal: true, urlModal: false });
  };
  const confirmDelete = () => {
    setButtonLoader(true);
    api
      .post(`/superadmin/contactdelete/`, { lead_id: selectedRowKeys })
      .then((response) => {
        if (response.status_code === 200) {
          setButtonLoader(false);
          getLeadList();
          setSelectedRowKeys("");
          setModalIsOpen({ deleteModal: false });
          navigate("/lead");
        } else {
          Swal.fire({
            position: "top-end",
            title: response.message ? response.message : "Somthing went wrong",
            showConfirmButton: false,
            backdrop: false,
            timer: 5000,
          });
          setModalIsOpen({ deleteModal: false });
          setButtonLoader(false);
        }
      });
  };
  const closeModal = () => {
    setModalIsOpen({ deleteModal: false, urlModal: false });
  };
  const setSearchValue = (event) => {
    setListingSearchValue(event.target.value);
  };

  return (
    <>
      <DefaultPage />

      <div className="page-wrapper supar-admin-page-wrapper">
        <div className="content container-fluid">
          <div className="card">
            <div className="card-header">
              <div className="d-flex align-items-center justify-content-between">
                <div className="company-header-wrap d-flex align-items-center ">
                  <div className="c-icon " style={{ background: "#C96B4E" }}>
                    <span className="icon icon-Group"></span>
                  </div>
                  <div className="pl-3">
                    <small>Leads</small>
                    <h3 className="page-title">Lead Listing</h3>
                  </div>
                </div>
              </div>
            </div>

            <div className="card-action-wrap d-flex align-items-center justify-content-between pt-3 pb-2">
              <div className="card-actions pr-4">
                {/* <EditButton selectedRowKeys={selectedRowKeys} editB={edit} /> */}
                {/* <EditButton selectedRowKeys={[]} editB={edit} /> */}

                <DeleteButton
                  selectedRowKeys={selectedRowKeys}
                  deleteB={deleteLead}
                />
                <div className="dropdown status-dropdown">
                  {/* <button
                    disabled={true}
                    className="btn btn-light-outlined dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Change Status
                  </button> */}

                  {/* <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <a className="dropdown-item" href="#">Active</a>
                      <a className="dropdown-item" href="#">Inactive</a>
                      <a className="dropdown-item active" href="#">Pending</a>
                      <a className="dropdown-item" href="#">Rejected</a>
                      <a className="dropdown-item" href="#">Approved</a>
                    </div> */}
                </div>
              </div>

              <div className="d-flex ml-auto">
                {selectedRowKeys.length === 1 &&
                  selectedRows[0].status === "P" && (
                    <div className="add-comp mr-2">
                      <Link
                        to={`/company-lead-add/${selectedRowKeys}`}
                        className="btn btn-primary rounded btn-small"
                      >
                        <i className="fa fa-plus" /> Create Company
                      </Link>
                    </div>
                  )}

                <SearchBox setSearchValue={setSearchValue} />
              </div>
            </div>
            <ListingTable
              Data={leadData}
              columns={columns}
              loader={loader}
              rowSelection={rowSelection}
              id="id"
            />
          </div>
        </div>

        {modalIsOpen.deleteModal && (
          <DeleteModal
            modalIsOpen={modalIsOpen.deleteModal}
            closeModal={closeModal}
            content="Lead"
            confirmDelete={confirmDelete}
          />
        )}
      </div>
      {ButtonLoader && <Loader />}
    </>
  );
};

export default Companies;
