export const ADDTOCART='Add_to_cart';
export const REMOVE='Remove_from_cart';
export const EMPTY='Empty_Cart';
export const COMPANYLIST='Company_List';
export const SETUSER='Set_User';
export const USERLOGOUT='LogOut_User';
export const SETCOMPANYLIST='Set_company';






