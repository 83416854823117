import { combineReducers } from "redux";
import {CartData} from './Reducer/Reducers';
import {CompanyData} from './Reducer/CompanyReducer';
import {UserData} from './Reducer/UserReducer';



export default combineReducers({
    UserData,
    CartData,
    CompanyData
})