import React, { useEffect, useState } from "react";
import DefaultPage from "./DefaultPage";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { api } from "../helper/Api";
import Loader from "../components/loader";
const CompanyInfo = () => {
  const navigate = useNavigate();
  let { CompanyId } = useParams();
  const [loader, setLoader] = useState(false);

  const [formValue, setFormValue] = useState({
    companyName: "",
    branchName: "",
    companyWebsite: "",
    Subdomain: "",
    GstNo: "",
    DatabaseName: "",
    Databasetype: "",
    HostName: "",
    DatabaseUserName: "",
    IpAddress: "",
    NoOfEmployee: "",
    companyStatus: "",
    billingAddress1: "",
    billingAddress2: "",
    billingCity: "",
    billingState: "",
    billingCountry: "",
    billingPincode: "",
    billingEmail: "",
    billingMobile: "",
    sameAddress: "",
    shipingAddress1: "",
    shipingAddress2: "",
    shipingCity: "",
    shipingState: "",
    shipingCountry: "",
    shipingPincode: "",
    shipingEmail: "",
    shipingMobile: "",
    POCName: "",
    POCMobileNo: "",
    POCEmail: "",
    POCStatus: "",
    LoginEmailAddress: "",
    LoginPassword: "",
    PlanName: "",
    ActivationDate: "",
    PlanStatus: "",
    PaymentMethod: "",
    PaymentRefrenceNumber: "",
    PaymentStatus: "",
    BankName: "",
    AccountHolderName: "",
    AccountNumber: "",
    IFSCCode: "",
  });
  const [samShipingAddress, setShipingSameAddress] = useState("");

  useEffect(() => {
    if (CompanyId) {
      loadData();
    }
  }, []);

  const loadData = () => {
    setLoader(true);
    Promise.all([getCountryList(), getCompanyList()])
      .then(async (response) => {
        let countryData = [];
        let billingCountry = {};
        let shippingCountry = {};

        let stateData = [];
        let billingState = {};
        let shippingState = {};
        if (response[0].status_code == 200) {
          countryData = response[0].data;

          billingCountry = countryData.find(
            (country) => country.id === response[1].data[0].billing_country
          );

          stateData = await getStateList(billingCountry?.id);
          stateData = stateData.data;

          billingState = stateData?.find(
            (state) => state.id === response[1].data[0]?.billing_state
          );
        }

        if (response[1].status_code === 200) {
          var data = response[1].data[0];

          const isSameAsBillingAddress =
            data.bill_ship_same?.toUpperCase() === "Y";

          if (isSameAsBillingAddress) {
            shippingCountry = billingCountry;
            shippingState = billingState;
          } else {
            shippingCountry = countryData.find(
              (country) => country.id === response[1].data[0].shipping_country
            );

            stateData = await getStateList(shippingCountry?.id);
            stateData = stateData.data;
            shippingState = stateData?.find(
              (state) => state.id === response[1].data[0]?.shipping_state
            );
          }

          setFormValue({
            companyName: data.name,
            branchName: data.branch,
            companyWebsite: data.website_url,
            Subdomain: data.subdomain,
            GstNo: data.gst_number,
            DatabaseName: "",
            Databasetype: "",
            HostName: "",
            DatabaseUserName: "",
            IpAddress: "",
            NoOfEmployee: data.employee_total,
            companyStatus: data.status,
            billingAddress1: data.billing_address1,
            billingAddress2: data.billing_address2,
            billingCity: data.billing_city,
            billingState: billingState?.name,
            billingCountry: billingCountry?.name,
            billingPincode: data.billing_pincode,
            billingEmail: data.billing_email,
            billingMobile: data.billing_mobile,
            sameAddress: data.bill_ship_same,
            shipingAddress1: isSameAsBillingAddress
              ? data.billing_address1
              : data.shipping_address1,
            shipingAddress2: isSameAsBillingAddress
              ? data.billing_address2
              : data.shipping_address2,
            shipingCity: isSameAsBillingAddress
              ? data.billing_city
              : data.shipping_city,
            shipingState: shippingState?.name ?? "",
            shipingCountry: shippingCountry?.name ?? "",
            shipingPincode: isSameAsBillingAddress
              ? data.billing_pincode
              : data.shipping_pincode,
            shipingEmail: isSameAsBillingAddress
              ? data.billing_email
              : data.shipping_email,
            shipingMobile: isSameAsBillingAddress
              ? data.billing_mobile
              : data.shipping_mobile,
            POCName: data.poc_name,
            POCMobileNo: data.poc_mobile,
            POCEmail: data.poc_email,
            POCStatus: data.poc_status,
            LoginEmailAddress: data.login_email,
            LoginPassword: data.login_password,
            PlanName: "",
            ActivationDate: "",
            PlanStatus: "",
            PaymentMethod: "",
            PaymentRefrenceNumber: "",
            PaymentStatus: "",
            BankName: data.bank_name,
            AccountHolderName: data.account_holder,
            AccountNumber: data.bank_account,
            IFSCCode: data.ifsc_code,
          });
          setShipingSameAddress(data.bill_ship_same);
        }
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const getCompanyList = async () => {
    return await api.get(
      `/superadmin/company/listing/?company_id=${CompanyId}`
    );
  };

  const getCountryList = async () => {
    return await api.post(`/superadmin/company/country`);
  };

  const getStateList = async (countryId) => {
    return await api.put(`/superadmin/company/country`, {
      country_id: countryId,
    });
  };

  const edit = () => {
    navigate(`/company-edit/${CompanyId}`);
  };
  const cancel = () => {
    navigate("/company");
  };

  return (
    <>
      <DefaultPage />
      <div className="page-wrapper supar-admin-page-wrapper">
        <div className="content container-fluid">
          <div className="card">
            <div className="card-body p-2">
              <div className="d-flex align-items-center justify-content-between">
                <div className="company-header-wrap d-flex align-items-center ">
                  <div className="c-icon c">
                    <span className="icon icon-company"></span>
                  </div>
                  <div className="pl-3">
                    <small>company</small>
                    <h3 className="page-title">{formValue.companyName}</h3>
                  </div>
                </div>
                <button
                  type="button"
                  onClick={() => edit()}
                  className="btn-light-outlined btn rounded"
                  disabled={false}
                >
                  <i className="icon icon-pencil"></i>
                  Edit
                </button>
              </div>
            </div>
            <div className="card-footer">
              <div className="ctab-links">
                <Link to={`/companyInfo/${CompanyId}`} className="active">
                  <span>Company Details</span>
                </Link>
                <Link to={`/companyInvoice/${CompanyId}`}>
                  <span>Invoices</span>
                </Link>
                {/* <Link to={`#`}>
                  <span>Invoices</span>
                </Link> */}
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-header py-2">
              <h4 className="card-title mb-0">Basic Information</h4>
            </div>
            <div className="card-body">
              <div className="card-form-row four-column">
                <div className="column-swa">
                  <div className="form-group">
                    <label className="col-form-label view-cel">
                      <span className="view-label">Company Name</span>
                      <span className="view-value">
                        {formValue.companyName}
                      </span>
                    </label>
                  </div>
                  <div className="form-group">
                    <label className="col-form-label view-cel">
                      <span className="view-label">Company Website</span>
                      <span className="view-value">
                        {formValue.companyWebsite}
                      </span>
                    </label>
                  </div>
                  <div className="form-group">
                    <label className="col-form-label view-cel">
                      <span className="view-label">Company Sub Domain URL</span>
                      <span className="view-value"> {formValue.Subdomain}</span>
                    </label>
                  </div>
                  {/* <div className="form-group">
                    <label className="col-form-label view-cel">
                      <span className="view-label">GST No.</span>
                      <span className="view-value">: {formValue.GstNo}</span>
                    </label>
                  </div> */}
                </div>
                <div className="column-swa">
                  <div className="form-group">
                    <label className="col-form-label view-cel">
                      <span className="view-label">Branch Name</span>
                      <span className="view-value">{formValue.branchName}</span>
                    </label>
                  </div>
                  <div className="form-group">
                    <label className="col-form-label view-cel">
                      <span className="view-label">GST Number</span>
                      <span className="view-value">{formValue.GstNo}</span>
                    </label>
                  </div>
                  <div className="form-group">
                    <label className="col-form-label view-cel">
                      <span className="view-label">Sample Database Name</span>
                      <span className="view-value">
                        {formValue.DatabaseName}
                      </span>
                    </label>
                  </div>
                </div>
                <div className="column-swa">
                  <div className="form-group">
                    <label className="col-form-label view-cel">
                      <span className="view-label">Database Type</span>
                      <span className="view-value">
                        {formValue.Databasetype}
                      </span>
                    </label>
                  </div>
                  <div className="form-group">
                    <label className="col-form-label view-cel">
                      <span className="view-label">Host Name</span>
                      <span className="view-value">{formValue.HostName}</span>
                    </label>
                  </div>
                  <div className="form-group">
                    <label className="col-form-label view-cel">
                      <span className="view-label">Database User Name</span>
                      <span className="view-value">
                        {formValue.DatabaseUserName}
                      </span>
                    </label>
                  </div>
                </div>
                <div className="column-swa">
                  <div className="form-group clickEventUrlCheck">
                    <label className="col-form-label view-cel">
                      <span className="view-label">IP Address</span>
                      <span className="view-value">{formValue.IpAddress}</span>
                    </label>
                  </div>
                  <div className="form-group clickEventUrlCheck">
                    <label className="col-form-label view-cel">
                      <span className="view-label">No. Of Employee</span>
                      <span className="view-value">
                        {formValue.NoOfEmployee}
                      </span>
                    </label>
                  </div>
                  <div className="form-group clickEventUrlCheck">
                    <label className="col-form-label view-cel">
                      <span className="view-label">Status</span>
                      <span className="view-value">
                        {formValue.companyStatus === "A"
                          ? "Active"
                          : "Inactive"}
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header py-2">
              <h4 className="card-title mb-0">Address Information</h4>
            </div>
            <div className="card-body">
              <div className="row ship-row">
                <div className="col-md-6 col-12">
                  <div className="bill-side">
                    <span>Billing Address</span>
                    <div className="card-form-row two-column">
                      <div className="column-swa">
                        <div className="form-group">
                          <label className="col-form-label view-cel">
                            <span className="view-label"> Address 1</span>
                            <span className="view-value">
                              {formValue.billingAddress1}
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className="column-swa">
                        <div className="form-group">
                          <label className="col-form-label view-cel">
                            <span className="view-label">
                              Address 2 (Optional)
                            </span>
                            <span className="view-value">
                              {formValue.billingAddress2}
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className="column-swa">
                        <div className="form-group">
                          <label className="col-form-label view-cel">
                            <span className="view-label"> City</span>
                            <span className="view-value">
                              {formValue.billingCity}
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className="column-swa">
                        <div className="form-group">
                          <label className="col-form-label view-cel">
                            <span className="view-label"> State</span>
                            <span className="view-value">
                              {formValue.billingState}
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className="column-swa">
                        <div className="form-group">
                          <label className="col-form-label view-cel">
                            <span className="view-label"> Country</span>
                            <span className="view-value">
                              {formValue.billingCountry}
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className="column-swa">
                        <div className="form-group">
                          <label className="col-form-label view-cel">
                            <span className="view-label"> Pincode</span>
                            <span className="view-value">
                              {formValue.billingPincode}
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className="column-swa">
                        <div className="form-group">
                          <label className="col-form-label view-cel">
                            <span className="view-label"> Email Address</span>
                            <span className="view-value">
                              {formValue.billingEmail}
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className="column-swa">
                        <div className="form-group">
                          <label className="col-form-label view-cel">
                            <span className="view-label"> Mobile Number</span>
                            <span className="view-value">
                              {formValue.billingMobile}
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-12">
                  <div className="ship-side">
                    <span>Shiping Address</span>

                    <div className="card-form-row two-column">
                      <div className="column-swa">
                        <div className="form-group">
                          <label className="col-form-label view-cel">
                            <span className="view-label"> Address 1</span>
                            <span className="view-value">
                              {formValue.shipingAddress1}
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className="column-swa">
                        <div className="form-group">
                          <label className="col-form-label view-cel">
                            <span className="view-label">
                              Address 2 (Optional)
                            </span>
                            <span className="view-value">
                              {formValue.shipingAddress2}
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className="column-swa">
                        <div className="form-group">
                          <label className="col-form-label view-cel">
                            <span className="view-label"> City</span>
                            <span className="view-value">
                              {formValue.shipingCity}
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className="column-swa">
                        <div className="form-group">
                          <label className="col-form-label view-cel">
                            <span className="view-label"> State</span>
                            <span className="view-value">
                              {formValue.shipingState}
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className="column-swa">
                        <div className="form-group">
                          <label className="col-form-label view-cel">
                            <span className="view-label"> Country</span>
                            <span className="view-value">
                              {formValue.shipingCountry}
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className="column-swa">
                        <div className="form-group">
                          <label className="col-form-label view-cel">
                            <span className="view-label"> Pincode</span>
                            <span className="view-value">
                              {formValue.shipingPincode}
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className="column-swa">
                        <div className="form-group">
                          <label className="col-form-label view-cel">
                            <span className="view-label"> Email Address</span>
                            <span className="view-value">
                              {formValue.shipingEmail}
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className="column-swa">
                        <div className="form-group">
                          <label className="col-form-label view-cel">
                            <span className="view-label"> Mobile Number</span>
                            <span className="view-value">
                              {formValue.shipingMobile}
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row sm-row">
            <div className="col-md-6 col-12">
              <div className="card">
                <div className="card-header py-2">
                  <h4 className="card-title mb-0">Contact Information</h4>
                </div>
                <div className="card-body">
                  <div className="card-form-row two-column">
                    <div className="column-swa">
                      <div className="form-group">
                        <label className="col-form-label view-cel">
                          <span className="view-label"> POC Name</span>
                          <span className="view-value">
                            {formValue.POCName}
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className="column-swa">
                      <div className="form-group">
                        <label className="col-form-label view-cel">
                          <span className="view-label"> POC Phone Number</span>
                          <span className="view-value">
                            {formValue.POCMobileNo}
                          </span>
                        </label>
                      </div>
                    </div>

                    <div className="column-swa">
                      <div className="form-group">
                        <label className="col-form-label view-cel">
                          <span className="view-label"> POC Email Address</span>
                          <span className="view-value">
                            {formValue.POCEmail}
                          </span>
                        </label>
                      </div>
                    </div>

                    <div className="column-swa">
                      <div className="form-group">
                        <label className="col-form-label view-cel">
                          <span className="view-label"> Status</span>
                          <span className="view-value">
                            {formValue.POCStatus === "A"
                              ? "Active"
                              : "Inactive"}
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="card">
                <div className="card-header py-2">
                  <h4 className="card-title mb-0">Login Information</h4>
                </div>
                <div className="card-body">
                  <div className="card-form-row two-column">
                    <div className="column-swa">
                      <div className="form-group">
                        <label className="col-form-label view-cel">
                          <span className="view-label">
                            Login Email Address
                          </span>
                          <span className="view-value">
                            {formValue.LoginEmailAddress}
                          </span>
                        </label>
                      </div>
                      <div className="form-group">
                        <label className="col-form-label view-cel">
                          <span className="view-label">Send Welcome Email</span>
                          <span className="view-value">Yes</span>
                        </label>
                      </div>
                    </div>
                    <div className="column-swa">
                      <div className="form-group">
                        <label className="col-form-label view-cel">
                          <span className="view-label"> Password</span>
                          <span className="view-value"> ******</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="card">
                <div className="card-header py-2">
                  <h4 className="card-title mb-0">Subscription Information</h4>
                </div>
                <div className="card-body">
                  <div className="card-form-row two-column">
                    <div className="column-swa">
                      <div className="form-group">
                        <label className="col-form-label view-cel">
                          <span className="view-label">Plan Name</span>
                          <span className="view-value">Enterprise</span>
                        </label>
                      </div>
                      <div className="form-group">
                        <label className="col-form-label view-cel">
                          <span className="view-label">Status</span>
                          <span className="view-value">Active</span>
                        </label>
                      </div>
                    </div>
                    <div className="column-swa">
                      <div className="form-group">
                        <label className="col-form-label view-cel">
                          <span className="view-label"> Activation Date</span>
                          <span className="view-value">
                            02-05-2023 12:00 AM
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="card">
                <div className="card-header py-2">
                  <h4 className="card-title mb-0">Bank Information</h4>
                </div>
                <div className="card-body">
                  <div className="card-form-row two-column">
                    <div className="column-swa">
                      <div className="form-group">
                        <label className="col-form-label view-cel">
                          <span className="view-label"> Bank Name</span>
                          <span className="view-value">
                            {formValue.BankName}
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className="column-swa">
                      <div className="form-group">
                        <label className="col-form-label view-cel">
                          <span className="view-label">
                            Account Holder Name
                          </span>
                          <span className="view-value">
                            {formValue.AccountHolderName}
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className="column-swa">
                      <div className="form-group">
                        <label className="col-form-label view-cel">
                          <span className="view-label">Account Number</span>
                          <span className="view-value">
                            {formValue.AccountNumber}
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className="column-swa">
                      <div className="form-group">
                        <label className="col-form-label view-cel">
                          <span className="view-label">IFSC Code</span>
                          <span className="view-value">
                            {formValue.IFSCCode}
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="card">
                <div className="card-header py-2">
                  <h4 className="card-title mb-0">Payment Information</h4>
                </div>
                <div className="card-body">
                  <div className="card-form-row two-column">
                    <div className="column-swa">
                      <div className="form-group">
                        <label className="col-form-label view-cel">
                          <span className="view-label">Payment Method</span>
                          <span className="view-value">Net Banking</span>
                        </label>
                      </div>
                      <div className="form-group">
                        <label className="col-form-label view-cel">
                          <span className="view-label">Status</span>
                          <span className="view-value">Successful</span>
                        </label>
                      </div>
                    </div>
                    <div className="column-swa">
                      <div className="form-group">
                        <label className="col-form-label view-cel">
                          <span className="view-label">
                            Payment Reference Number
                          </span>
                          <span className="view-value"> PRN00009976544</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loader && <Loader />}
    </>
  );
};

export default CompanyInfo;
