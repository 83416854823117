import { takeEvery, put } from "redux-saga/effects";
import { COMPANYLIST, SETCOMPANYLIST } from "../Constant";
import { api } from "../../helper/Api";

function* getCompanies() {
  let data = yield api.get("/superadmin/company/listing/?company_id=0");

  yield put({ type: SETCOMPANYLIST, data: data.data });
}

function* companySaga() {
  yield takeEvery(COMPANYLIST, getCompanies);
}

export default companySaga;
