import React, { useEffect, useState, useRef, useCallback } from "react";
import DefaultPage from "./DefaultPage";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "../helper/Api";
import {
  AddUpdateCardFooter,
  AddUpdateCardHeader,
} from "../components/UtilsAndModal";
import { Form, Formik } from "formik";
import { companySchema } from "../components/FormValidation";
import Swal from "sweetalert2";
import toast, { Toaster } from "react-hot-toast";
import Loader from "../components/loader";

const debounce = (func, delay) => {
  let timer;
  return function (...args) {
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      func.apply(context, args);
    }, delay ?? 500);
  };
};

const CompanyAddEdit = () => {
  const inputRef = useRef(null);
  console.log(inputRef, "inputRefinputRef");
  const navigate = useNavigate();
  let { CompanyId } = useParams();
  let { LeadId } = useParams();
  const [loader, setLoader] = useState(false);
  const [samShipingAddress, setShipingSameAddress] = useState("");
  const [sendEmail, setSendEmail] = useState("");
  const [countryData, setCountryData] = useState("");
  const [stateData, setStateData] = useState("");
  const [scountryData, setsCountryData] = useState("");
  const [sstateData, setsStateData] = useState("");
  const [countryId, setCountryId] = useState("1");
  const [scountryId, setsCountryId] = useState("1");
  const [passwordShowIcon, setPasswordShowIcon] = useState(false);
  const [subDomainMessage, setSubDomainMessage] = useState("");
  const [subErrorDomainMessage, setErrorSubDomainMessage] = useState("");

  const [formValue, setFormValue] = useState({
    companyName: "",
    branchName: "",
    companyWebsite: "",
    Subdomain: "",
    GstNo: "",
    DatabaseName: "",
    Databasetype: "",
    HostName: "",
    DatabaseUserName: "",
    IpAddress: "",
    CompanyEmailAddress: "",
    NoOfEmployee: "",
    companyStatus: "",
    billingAddress1: "",
    billingAddress2: "",
    billingCity: "",
    billingState: "",
    billingCountry: "1",
    billingPincode: "",
    billingEmail: "",
    billingMobile: "",
    sameAddress: "",
    shipingAddress1: "",
    shipingAddress2: "",
    shipingCity: "",
    shipingState: "",
    shipingCountry: "1",
    shipingPincode: "",
    shipingEmail: "",
    shipingMobile: "",
    POCName: "",
    POCMobileNo: "",
    POCEmail: "",
    POCStatus: "",
    LoginEmailAddress: "",
    LoginPassword: "",
    welcome_email_status: "",
    PlanName: "",
    ActivationDate: "",
    PlanStatus: "",
    PaymentMethod: "",
    PaymentRefrenceNumber: "",
    PaymentStatus: "",
    BankName: "",
    AccountHolderName: "",
    AccountNumber: "",
    IFSCCode: "",
  });

  useEffect(() => {
    if (LeadId) {
      getLeadDetails();
    }
    country();
    if (CompanyId) {
      getCompanyList();
    }
  }, [formValue.billingCountry, formValue.shipingCountry]);
  const getCompanyList = () => {
    setLoader(true);
    api
      .get(`/superadmin/company/listing/?company_id=${CompanyId}`)
      .then((response) => {
        if (response.status_code === 200) {
          setLoader(false);

          var data = response.data[0];

          setFormValue({
            companyName: data.name,
            branchName: data.branch,
            companyWebsite: data.website_url,
            Subdomain: data.subdomain,
            GstNo: data.gst_number,
            CompanyEmailAddress: data.email,
            NoOfEmployee: data.employee_total,
            companyStatus: data.status,
            billingAddress1: data.billing_address1,
            billingAddress2: data.billing_address2,
            billingCity: data.billing_city,
            billingState: data.billing_state,
            billingCountry: data.billing_country,
            billingPincode: data.billing_pincode,
            billingEmail: data.billing_email,
            billingMobile: data.billing_mobile,
            sameAddress: data.bill_ship_same,
            shipingAddress1: data.shipping_address1,
            shipingAddress2: data.shipping_address2,
            shipingCity: data.shipping_city,
            shipingState: data.shipping_state,
            shipingCountry: data.shipping_country,
            shipingPincode: data.shipping_pincode,
            shipingEmail: data.shipping_email,
            shipingMobile: data.shipping_mobile,
            POCName: data.poc_name,
            POCMobileNo: data.poc_mobile,
            POCEmail: data.poc_email,
            POCStatus: data.poc_status,
            LoginEmailAddress: data.login_email,
            LoginPassword: data.login_password,
            BankName: data.bank_name,
            AccountHolderName: data.account_holder,
            AccountNumber: data.bank_account,
            IFSCCode: data.ifsc_code,
          });
          setSendEmail(data.welcome_email_status);
          setShipingSameAddress(data.bill_ship_same);
        } else {
          setLoader(false);
        }
      });
  };
  const getLeadDetails = () => {
    setLoader(true);
    api
      .get(`/superadmin/listingcontact/?lead_id=${LeadId}`)
      .then((response) => {
        if (response.status_code === 200) {
          setLoader(false);

          var data = response.data[0];
          console.log(data);
          setFormValue({
            companyName: data.company_name,
            POCName: `${data.fname} ${data.lname}`,
            POCMobileNo: data.phone_number,
            POCEmail: data.email,
            branchName: "",
            companyWebsite: "",
            Subdomain: "",
            GstNo: "",
            DatabaseName: "",
            Databasetype: "",
            HostName: "",
            DatabaseUserName: "",
            IpAddress: "",
            CompanyEmailAddress: data.email,
            NoOfEmployee: "",
            companyStatus: "",
            billingAddress1: "",
            billingAddress2: "",
            billingCity: "",
            billingState: "",
            billingCountry: "1",
            billingPincode: "",
            billingEmail: "",
            billingMobile: "",
            sameAddress: "",
            shipingAddress1: "",
            shipingAddress2: "",
            shipingCity: "",
            shipingState: "",
            shipingCountry: "1",
            shipingPincode: "",
            shipingEmail: "",
            shipingMobile: "",
            POCStatus: "",
            LoginEmailAddress: "",
            LoginPassword: "",
            welcome_email_status: "",
            PlanName: "",
            ActivationDate: "",
            PlanStatus: "",
            PaymentMethod: "",
            PaymentRefrenceNumber: "",
            PaymentStatus: "",
            BankName: "",
            AccountHolderName: "",
            AccountNumber: "",
            IFSCCode: "",
          });
        } else {
          setLoader(false);
        }
      });
  };
  const country = () => {
    api.post(`/superadmin/company/country`).then((response) => {
      if (response.status_code == 200) {
        var data = response.data;
        setsCountryData(data);
        setCountryData(data);
      }
    });
  };

  useEffect(() => {
    if (countryId || scountryId) {
      state();
    }
  }, [countryId, scountryId]);

  const state = () => {
    api
      .put(`/superadmin/company/country`, { country_id: countryId })
      .then((response) => {
        if (response.status_code == 200) {
          var data = response.data;

          setStateData(data);
          setsStateData(data);
        }
      });
  };
  const cancel = () => {
    navigate("/company");
  };
  const setSameAddress = (e) => {
    if (e.target.checked) {
      setShipingSameAddress("Y");
    } else {
      setShipingSameAddress("N");
    }
  };
  const setEmailChange = (e) => {
    if (e.target.checked) {
      setSendEmail(true);
    } else {
      setSendEmail(false);
    }
  };
  const passwordShow = () => {
    if (!passwordShowIcon) {
      setPasswordShowIcon(true);
    } else {
      setPasswordShowIcon(false);
    }
  };

  const subDomainChecker = (value, call) => {
    if (!CompanyId && value !== "" && value !== null) {
      api
        .post(`/superadmin/company/sub_domain_check/`, { subdomain: value })
        .then((response) => {
          if (response.status === 200) {
            setSubDomainMessage(response.message);
            setErrorSubDomainMessage("");
          } else {
            setErrorSubDomainMessage("This sub-domain already used.");
            setSubDomainMessage("");
          }
        });
    }
  };

  const debouncedSubDomainChecker = useCallback(
    debounce(subDomainChecker, 500),
    []
  );

  const onSubmit = (values) => {
    setLoader(true);
    const {
      companyName,
      branchName,
      companyWebsite,
      Subdomain,
      GstNo,
      NoOfEmployee,
      CompanyEmailAddress,
      companyStatus,
      billingAddress1,
      billingAddress2,
      billingCity,
      billingState,
      billingCountry,
      billingPincode,
      billingEmail,
      billingMobile,
      shipingAddress1,
      shipingAddress2,
      shipingCity,
      shipingState,
      shipingCountry,
      shipingPincode,
      shipingEmail,
      shipingMobile,
      POCName,
      POCMobileNo,
      POCEmail,
      POCStatus,
      LoginEmailAddress,
      LoginPassword,
      BankName,
      AccountHolderName,
      AccountNumber,
      IFSCCode,
    } = values;

    let formData = new FormData();
    formData.append("company_id", CompanyId ? CompanyId : "");
    formData.append("lead_id", LeadId ? LeadId : "");
    formData.append("name", companyName);
    formData.append("branch", branchName);
    formData.append("subdomain", Subdomain);
    formData.append("website_url", companyWebsite);
    formData.append("gst_number", GstNo);
    formData.append("employee_total", NoOfEmployee);
    formData.append("email", CompanyEmailAddress);

    formData.append("status", companyStatus);
    formData.append("billing_address_1", billingAddress1);
    formData.append("billing_address_2", billingAddress2);
    formData.append("billing_email", billingEmail);
    formData.append("billing_mobile", billingMobile);
    formData.append("billing_country", billingCountry);
    formData.append("billing_state:", billingState);
    formData.append("billing_city", billingCity);
    formData.append("billing_pincode", billingPincode);
    formData.append(
      "bill_ship_same",
      samShipingAddress ? samShipingAddress : "N"
    );
    formData.append("shipping_address_1", shipingAddress1);
    formData.append("shipping_address_2", shipingAddress2);
    formData.append("shipping_email", shipingEmail);
    formData.append("shipping_mobile", shipingMobile);
    formData.append("shipping_country", shipingCountry);
    formData.append("shipping_state:", shipingState);
    formData.append("shipping_city", shipingCity);
    formData.append("shipping_pincode", shipingPincode);
    formData.append("poc_name", POCName);
    formData.append("poc_mobile", POCMobileNo);
    formData.append("poc_email", POCEmail);
    formData.append("poc_status", POCStatus);
    formData.append("login_email", LoginEmailAddress);
    formData.append("login_password", LoginPassword);
    formData.append("welcome_email_status", sendEmail ? true : false);
    formData.append("bank_name", BankName);
    formData.append("ifsc_code", IFSCCode);
    formData.append("bank_account", AccountNumber);
    formData.append("account_holder", AccountHolderName);

    {
      (CompanyId
        ? api.put(`/superadmin/company/update/`, formData)
        : api.post(`/superadmin/company/create/`, formData)
      ).then(function (response) {
        if (response.status_code === 200) {
          //toast.success(response.message)
          navigate("/company");
          setLoader(false);
        } else if (response.status_code === 400) {
          Swal.fire({
            position: "top-end",
            title: response.message
              ? response.message
              : "Fill all *Required field",
            showConfirmButton: false,
            backdrop: false,
            timer: 3000,
          });
          setLoader(false);
        }
      });
    }
  };
  const clickToResendMail = () => {
    setLoader(true);
    let formData = new FormData();
    formData.append("company_id", CompanyId ? CompanyId : "");
    api
      .post(`superadmin/company/company_login_resend_email/`, formData)
      .then((response) => {
        if (response.status_code === 200) {
          setLoader(false);
         
           toast.success("Email Resend Successfully!");
          
        } else if (response.status_code === 400) {
          setLoader(false);
         
          
        }
        
      });
  };
  return (
    <>
      <DefaultPage />
      <div className="page-wrapper supar-admin-page-wrapper">
        <div className="content container-fluid">
          <Formik
            initialValues={formValue}
            validationSchema={companySchema}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {({ errors, values, touched, handleChange, handleBlur }) => (
              <Form autoComplete="off">
                <div className="card">
                  <AddUpdateCardHeader
                    PageName="Company"
                    icon="c"
                    PageIcon="company"
                    cancel={cancel}
                    PageId={CompanyId}
                  />
                </div>
                <div className="card">
                  <div className="card-header py-2">
                    <h4 className="card-title mb-0">Basic Information</h4>
                  </div>
                  <div className="card-body">
                    <div className="card-form-row">
                      <div className="column-swa">
                        <div className="form-group">
                          <label className="col-form-label">
                            Company Name<sup className="text-danger">*</sup>
                          </label>
                          <input
                            // placeholder="Tata Group"
                            className={`form-control ${
                              touched.companyName && errors.companyName
                                ? "error-input"
                                : ""
                            }`}
                            type="text"
                            name="companyName"
                            value={values.companyName || ""}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            autoComplete="none"
                          />
                          <span className="input-error">
                            {touched.companyName && errors.companyName
                              ? errors.companyName
                              : null}
                          </span>
                        </div>
                      </div>
                      <div className="column-swa">
                        <div className="form-group">
                          <label className="col-form-label">Branch Name</label>
                          <input
                            // placeholder="Tata Kolkata"
                            className={`form-control ${
                              touched.branchName && errors.branchName
                                ? "error-input"
                                : ""
                            }`}
                            type="text"
                            name="branchName"
                            value={values.branchName || ""}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            autoComplete="none"
                          />
                          <span className="input-error">
                            {touched.branchName && errors.branchName
                              ? errors.branchName
                              : null}
                          </span>
                        </div>
                      </div>
                      <div className="column-swa">
                        <div className="form-group">
                          <label className="col-form-label">
                            Company Website
                          </label>
                          <input
                            // placeholder="Tatagroup"
                            className={`form-control ${
                              touched.companyWebsite && errors.companyWebsite
                                ? "error-input"
                                : ""
                            }`}
                            type="text"
                            name="companyWebsite"
                            value={values.companyWebsite || ""}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            autoComplete="none"
                          />
                          <span className="input-error">
                            {touched.companyWebsite && errors.companyWebsite
                              ? errors.companyWebsite
                              : null}
                          </span>
                        </div>
                      </div>
                      <div className="column-swa">
                        <div className="form-group clickEventUrlCheck">
                          <label className="col-form-label">
                            Company Sub Domain URL{" "}
                            <sup className="text-danger">*</sup>
                          </label>
                          <input
                            //placeholder="tata"
                            className={`form-control ${
                              touched.Subdomain && errors.Subdomain
                                ? "error-input"
                                : ""
                            }`}
                            type="text"
                            name="Subdomain"
                            ref={inputRef}
                            value={values.Subdomain || ""}
                            onBlur={handleBlur}
                            onChange={(event) => {
                              handleChange(event);

                              debouncedSubDomainChecker(event.target.value);
                            }}
                            autoComplete="none"
                            readOnly={CompanyId ? true : false}
                          />

                          {/* {touched.Subdomain &&
                            values.Subdomain &&
                            !errors.Subdomain &&
                            subDomainChecker(values.Subdomain, values)} */}

                          <span className="domain-mask input-icon">
                            .swagatham.co.in
                          </span>
                          {CompanyId ? (
                            ""
                          ) : (
                            <>
                              {" "}
                              <span className="input-error">
                                {touched.Subdomain && errors.Subdomain
                                  ? errors.Subdomain
                                  : null}
                              </span>
                              <span className={`input-error`}>
                                {!errors.Subdomain && subErrorDomainMessage}
                              </span>
                              <span className={`input-msg`}>
                                {!errors.Subdomain && subDomainMessage}
                              </span>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="column-swa">
                        <div className="form-group">
                          <label className="col-form-label">GST Number</label>
                          <input
                            // placeholder="GSTIN123456789"
                            className="form-control"
                            type="text"
                            name="GstNo"
                            value={values.GstNo || ""}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            autoComplete="none"
                          />
                          <span className="input-error">
                            {touched.GstNo && errors.GstNo
                              ? errors.GstNo
                              : null}
                          </span>
                        </div>
                      </div>
                      {/* <div className="column-swa">
                        <div className="form-group">
                          <label className="col-form-label">
                            Sample Database Name
                          </label>
                          <input
                            placeholder="BookStoreDb"
                            className="form-control"
                            type="text"
                            name="DatabaseName"
                            value={values.DatabaseName || ""}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            readOnly
                          />
                        </div>
                      </div>

                      <div className="column-swa">
                        <div className="form-group">
                          <label className="col-form-label">Host Name</label>
                          <input
                            placeholder="tatagroup"
                            className="form-control"
                            type="text"
                            name="HostName"
                            value={values.HostName || ""}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="column-swa">
                        <div className="form-group">
                          <label className="col-form-label">
                            Database User Name
                          </label>
                          <input
                            placeholder="postgress"
                            className="form-control"
                            type="text"
                            name="DatabaseUserName"
                            value={values.DatabaseUserName || ""}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="column-swa">
                        <div className="form-group">
                          <label className="col-form-label">IP Address</label>
                          <input
                            placeholder="24.190.194.169"
                            className="form-control"
                            type="text"
                            name="IpAddress"
                            value={values.IpAddress || ""}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            readOnly
                          />
                        </div>
                      </div> */}

                      <div className="column-swa">
                        <div className="form-group">
                          <label className="col-form-label">
                            No. Of Employee
                          </label>
                          <input
                            // placeholder="10,000"
                            className={`form-control ${
                              touched.NoOfEmployee && errors.NoOfEmployee
                                ? "error-input"
                                : ""
                            }`}
                            type="text"
                            name="NoOfEmployee"
                            value={values.NoOfEmployee || ""}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            autoComplete="none"
                          />
                          <span className="input-error">
                            {touched.NoOfEmployee && errors.NoOfEmployee
                              ? errors.NoOfEmployee
                              : null}
                          </span>
                        </div>
                      </div>
                      <div className="column-swa">
                        <div className="form-group">
                          <label className="col-form-label">
                            Company Email Address
                            <sup className="text-danger">*</sup>
                          </label>
                          <input
                            // placeholder="info@tatagroup.com"
                            className={`form-control ${
                              touched.CompanyEmailAddress &&
                              errors.CompanyEmailAddress
                                ? "error-input"
                                : ""
                            }`}
                            type="text"
                            name="CompanyEmailAddress"
                            value={values.CompanyEmailAddress || ""}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            autoComplete="none"
                            readOnly={CompanyId ? true : false}
                          />
                          <span className="input-error">
                            {touched.CompanyEmailAddress &&
                            errors.CompanyEmailAddress
                              ? errors.CompanyEmailAddress
                              : null}
                          </span>
                        </div>
                      </div>
                      {/* <div className="column-swa">
                        <div className="form-group">
                          <label className="col-form-label">
                            Status <sup className="text-danger">*</sup>
                          </label>
                          <select
                            className={`form-control ${(touched.companyStatus && errors.companyStatus)
                              ? "error-input"
                              : ""
                              }`}
                            name="companyStatus"
                            value={values.companyStatus || ""}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          >
                            <option value="">Select Status</option>
                            <option value="A">Active</option>
                            <option value="I">Inactive</option>
                          </select>
                          <span className="input-error">
                            {touched.companyStatus && errors.companyStatus ? errors.companyStatus : null}
                          </span>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header py-2">
                    <h4 className="card-title mb-0">Address Information</h4>
                  </div>
                  <div className="card-body">
                    <div className="mb-2">
                      <label className="form-c-label">Billing Address</label>
                    </div>

                    <div className="card-form-row">
                      <div className="column-swa">
                        <div className="form-group">
                          <label className="col-form-label">Address 1</label>
                          <input
                            //placeholder="10/1, Park Street"
                            className={`form-control ${
                              touched.billingAddress1 && errors.billingAddress1
                                ? "error-input"
                                : ""
                            }`}
                            type="text"
                            name="billingAddress1"
                            value={values.billingAddress1 || ""}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            autoComplete="none"
                          />
                          <span className="input-error">
                            {touched.billingAddress1 && errors.billingAddress1
                              ? errors.billingAddress1
                              : null}
                          </span>
                        </div>
                      </div>
                      <div className="column-swa">
                        <div className="form-group">
                          <label className="col-form-label">
                            Address 2 (Optional)
                          </label>
                          <input
                            // placeholder="10/1, Park Street"
                            className={`form-control ${
                              touched.billingAddress2 && errors.billingAddress2
                                ? "error-input"
                                : ""
                            }`}
                            type="text"
                            name="billingAddress2"
                            value={values.billingAddress2 || ""}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            autoComplete="none"
                          />
                          <span className="input-error">
                            {touched.billingAddress2 && errors.billingAddress2
                              ? errors.billingAddress2
                              : null}
                          </span>
                        </div>
                      </div>

                      <div className="column-swa">
                        <div className="form-group">
                          <label className="col-form-label">City</label>
                          <input
                            className={`form-control ${
                              touched.billingCity && errors.billingCity
                                ? "error-input"
                                : ""
                            }`}
                            // placeholder="Kolkata"
                            type="text"
                            value={values.billingCity || ""}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            name="billingCity"
                            autoComplete="none"
                          />
                          <span className="input-error">
                            {touched.billingCity && errors.billingCity
                              ? errors.billingCity
                              : null}
                          </span>
                        </div>
                      </div>
                      <div className="column-swa">
                        <div className="form-group">
                          <label className="col-form-label">State</label>
                          <select
                            className=" form-control"
                            value={values.billingState || ""}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            name="billingState"
                          >
                            <option>Select State</option>
                            {stateData
                              ? stateData.map((state) => (
                                  <option value={state.id}>{state.name}</option>
                                ))
                              : ""}
                          </select>
                        </div>
                      </div>
                      <div className="column-swa">
                        <div className="form-group">
                          <label className="col-form-label">Country</label>
                          <select
                            className=" form-control"
                            value={values.billingCountry || ""}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            name="billingCountry"
                          >
                            <option>Select Country</option>
                            {countryData
                              ? countryData.map((country) => (
                                  <option value={country.id}>
                                    {country.name}
                                  </option>
                                ))
                              : ""}
                          </select>
                        </div>
                      </div>

                      <div className="column-swa">
                        <div className="form-group">
                          <label className="col-form-label">Pincode</label>
                          <input
                            // placeholder="700016"
                            className={`form-control ${
                              touched.billingPincode && errors.billingPincode
                                ? "error-input"
                                : ""
                            }`}
                            type="text"
                            name="billingPincode"
                            value={values.billingPincode || ""}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            autoComplete="none"
                          />
                          <span className="input-error">
                            {touched.billingPincode && errors.billingPincode
                              ? errors.billingPincode
                              : null}
                          </span>
                        </div>
                      </div>
                      <div className="column-swa">
                        <div className="form-group">
                          <label className="col-form-label">
                            Email Address
                          </label>
                          <input
                            // placeholder="info@tata.in"
                            className={`form-control ${
                              touched.billingEmail && errors.billingEmail
                                ? "error-input"
                                : ""
                            }`}
                            type="text"
                            name="billingEmail"
                            value={values.billingEmail || ""}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            autoComplete="none"
                          />
                          <span className="input-error">
                            {touched.billingEmail && errors.billingEmail
                              ? errors.billingEmail
                              : null}
                          </span>
                        </div>
                      </div>
                      <div className="column-swa">
                        <div className="form-group">
                          <label className="col-form-label">
                            Mobile Number
                          </label>
                          <input
                            //placeholder="+91 9876787656"
                            className={`form-control ${
                              touched.billingMobile && errors.billingMobile
                                ? "error-input"
                                : ""
                            }`}
                            type="text"
                            name="billingMobile"
                            value={values.billingMobile || ""}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            autoComplete="none"
                          />
                          <span className="input-error">
                            {touched.billingMobile && errors.billingMobile
                              ? errors.billingMobile
                              : null}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="form-check d-flex align-items-center form-group">
                      <input
                        className="messageCheckbox form-check-input"
                        type="checkbox"
                        checked={samShipingAddress === "Y" ? true : false}
                        value="Y"
                        onChange={setSameAddress}
                        id="shippingaddress"
                      />

                      <label
                        className="form-check-label"
                        htmlFor="shippingaddress"
                      >
                        Same As Shipping Address
                      </label>
                    </div>

                    {samShipingAddress === "Y" ? (
                      ""
                    ) : (
                      <div className="card-form-row">
                        <div className="column-swa">
                          <div className="form-group">
                            <label className="col-form-label">Address 1</label>
                            <input
                              // placeholder="10/1, Park Street"
                              className={`form-control ${
                                touched.shipingAddress1 &&
                                errors.shipingAddress1
                                  ? "error-input"
                                  : ""
                              }`}
                              type="text"
                              name="shipingAddress1"
                              value={values.shipingAddress1 || ""}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              autoComplete="none"
                            />
                            <span className="input-error">
                              {touched.shipingAddress1 && errors.shipingAddress1
                                ? errors.shipingAddress1
                                : null}
                            </span>
                          </div>
                        </div>
                        <div className="column-swa">
                          <div className="form-group">
                            <label className="col-form-label">
                              Address 2 (Optional)
                            </label>
                            <input
                              className={`form-control ${
                                touched.shipingAddress2 &&
                                errors.shipingAddress2
                                  ? "error-input"
                                  : ""
                              }`}
                              // placeholder="10/1, Park Street"
                              type="text"
                              value={values.shipingAddress2 || ""}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              name="shipingAddress2"
                              autoComplete="none"
                            />
                            <span className="input-error">
                              {touched.shipingAddress2 && errors.shipingAddress2
                                ? errors.shipingAddress2
                                : null}
                            </span>
                          </div>
                        </div>

                        <div className="column-swa">
                          <div className="form-group">
                            <label className="col-form-label">City</label>
                            <input
                              className={`form-control ${
                                touched.shipingCity && errors.shipingCity
                                  ? "error-input"
                                  : ""
                              }`}
                              // placeholder="Kolkata"
                              type="text"
                              value={values.shipingCity || ""}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              name="shipingCity"
                              autoComplete="none"
                            />
                            <span className="input-error">
                              {touched.shipingCity && errors.shipingCity
                                ? errors.shipingCity
                                : null}
                            </span>
                          </div>
                        </div>

                        <div className="column-swa">
                          <div className="form-group">
                            <label className="col-form-label">State</label>
                            <select
                              className=" form-control"
                              value={values.shipingState || ""}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              name="shipingState"
                            >
                              <option>Select State</option>
                              {sstateData
                                ? sstateData.map((state) => (
                                    <option value={state.id}>
                                      {state.name}
                                    </option>
                                  ))
                                : ""}
                            </select>
                          </div>
                        </div>

                        <div className="column-swa">
                          <div className="form-group">
                            <label className="col-form-label">Country</label>
                            <select
                              className=" form-control"
                              value={values.shipingCountry || ""}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              name="shipingCountry"
                            >
                              <option>Select Country</option>
                              {scountryData
                                ? scountryData.map((country) => (
                                    <option value={country.id}>
                                      {country.name}
                                    </option>
                                  ))
                                : ""}
                            </select>
                          </div>
                        </div>

                        <div className="column-swa">
                          <div className="form-group">
                            <label className="col-form-label">Pincode</label>
                            <input
                              // placeholder="700016"
                              className={`form-control ${
                                touched.shipingPincode && errors.shipingPincode
                                  ? "error-input"
                                  : ""
                              }`}
                              type="text"
                              name="shipingPincode"
                              value={values.shipingPincode || ""}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              autoComplete="none"
                            />
                            <span className="input-error">
                              {touched.shipingPincode && errors.shipingPincode
                                ? errors.shipingPincode
                                : null}
                            </span>
                          </div>
                        </div>
                        <div className="column-swa">
                          <div className="form-group">
                            <label className="col-form-label">
                              Email Address
                            </label>
                            <input
                              //placeholder="info@tata.in"
                              className={`form-control ${
                                touched.shipingEmail && errors.shipingEmail
                                  ? "error-input"
                                  : ""
                              }`}
                              type="text"
                              name="shipingEmail"
                              value={values.shipingEmail || ""}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              autoComplete="none"
                            />
                            <span className="input-error">
                              {touched.shipingEmail && errors.shipingEmail
                                ? errors.shipingEmail
                                : null}
                            </span>
                          </div>
                        </div>
                        <div className="column-swa">
                          <div className="form-group">
                            <label className="col-form-label">
                              Mobile Number
                            </label>
                            <input
                              // placeholder="+91 9876787656"
                              className={`form-control ${
                                touched.shipingMobile && errors.shipingMobile
                                  ? "error-input"
                                  : ""
                              }`}
                              type="text"
                              name="shipingMobile"
                              value={values.shipingMobile || ""}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              autoComplete="none"
                            />
                            <span className="input-error">
                              {touched.shipingMobile && errors.shipingMobile
                                ? errors.shipingMobile
                                : null}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="card">
                  <div className="card-header py-2">
                    <h4 className="card-title mb-0">Contact Information</h4>
                  </div>
                  <div className="card-body">
                    <div className="card-form-row four-column">
                      <div className="column-swa">
                        <div className="form-group">
                          <label className="col-form-label">POC Name</label>
                          <input
                            // placeholder="Rahul Sharma"
                            className={`form-control ${
                              touched.POCName && errors.POCName
                                ? "error-input"
                                : ""
                            }`}
                            type="text"
                            name="POCName"
                            value={values.POCName || ""}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            autoComplete="none"
                          />
                          <span className="input-error">
                            {touched.POCName && errors.POCName
                              ? errors.POCName
                              : null}
                          </span>
                        </div>
                      </div>
                      <div className="column-swa">
                        <div className="form-group">
                          <label className="col-form-label">
                            POC Phone Number
                          </label>
                          <input
                            //  placeholder="+91 9876787656"
                            className={`form-control ${
                              touched.POCMobileNo && errors.POCMobileNo
                                ? "error-input"
                                : ""
                            }`}
                            type="text"
                            name="POCMobileNo"
                            value={values.POCMobileNo || ""}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            autoComplete="none"
                          />
                          <span className="input-error">
                            {touched.POCMobileNo && errors.POCMobileNo
                              ? errors.POCMobileNo
                              : null}
                          </span>
                        </div>
                      </div>

                      <div className="column-swa">
                        <div className="form-group">
                          <label className="col-form-label">
                            POC Email Address
                          </label>
                          <input
                            // placeholder="contact@tata.in"
                            className={`form-control ${
                              touched.POCEmail && errors.POCEmail
                                ? "error-input"
                                : ""
                            }`}
                            type="text"
                            name="POCEmail"
                            value={values.POCEmail || ""}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            autoComplete="none"
                          />
                          <span className="input-error">
                            {touched.POCEmail && errors.POCEmail
                              ? errors.POCEmail
                              : null}
                          </span>
                        </div>
                      </div>

                      <div className="column-swa">
                        <div className="form-group">
                          <label className="col-form-label">POC Status </label>
                          <select
                            className=" form-control"
                            name="POCStatus"
                            value={values.POCStatus || ""}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          >
                            <option value="">Select Status</option>
                            <option value="A">Active</option>
                            <option value="I">Inactive</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header py-2">
                    <h4 className="card-title mb-0">Login Information</h4>
                  </div>
                  <div className="card-body">
                    <div className="card-form-row four-column">
                      <div className="column-swa">
                        <div className="form-group">
                          <label className="col-form-label">
                            Login Email Address
                            <sup className="text-danger">*</sup>
                          </label>
                          <input
                            // placeholder="login@tata.in"
                            className={`form-control ${
                              touched.LoginEmailAddress &&
                              errors.LoginEmailAddress
                                ? "error-input"
                                : ""
                            }`}
                            type="text"
                            name="LoginEmailAddress"
                            value={values.LoginEmailAddress || ""}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            readOnly={CompanyId ? true : false}
                            autoComplete="none"
                          />
                          <span className="input-error">
                            {touched.LoginEmailAddress &&
                            errors.LoginEmailAddress
                              ? errors.LoginEmailAddress
                              : null}
                          </span>
                        </div>
                      </div>
                      <div className="column-swa">
                        <div className="form-group">
                          <label className="col-form-label">
                            Password <sup className="text-danger">*</sup>
                          </label>
                          <span onClick={passwordShow} className="input-icon">
                            <i
                              className={`icon ${
                                passwordShowIcon
                                  ? "icon-eye-open"
                                  : "icon-eye-close"
                              }`}
                            ></i>
                          </span>
                          <input
                            className={`form-control ${
                              touched.LoginPassword && errors.LoginPassword
                                ? "error-input"
                                : ""
                            }`}
                            // placeholder="*********"
                            type={passwordShowIcon ? "text" : "password"}
                            name="LoginPassword"
                            value={values.LoginPassword || ""}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            autoComplete="none"
                            readOnly={CompanyId ? true : false}
                          />
                          <span className="input-error">
                            {touched.LoginPassword && errors.LoginPassword
                              ? errors.LoginPassword
                              : null}
                          </span>
                        </div>
                      </div>
                      <div className="column-swa d-flex align-items-end pb-1">
                        <div className="form-check form-group d-flex align-items-center">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="mail"
                            checked={sendEmail ? true : false}
                            value={true}
                            onChange={setEmailChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="mail"
                            style={{ color: "#212B36" }}
                          >
                            Send Welcome Email
                          </label>
                          <button
      type="button"
      onClick={clickToResendMail}
      className="btn-light-outlined btn rounded"
      style={{marginLeft:'10px'}}
     // disabled={selectedRowKeys.length === 1 ? false : true}
    >
      {/* <i className="icon icon-pencil"></i> */}
      Resend Welcome Email
    </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header py-2">
                    <h4 className="card-title mb-0">Bank Information</h4>
                  </div>
                  <div className="card-body">
                    <div className="card-form-row four-column">
                      <div className="column-swa">
                        <div className="form-group">
                          <label className="col-form-label">Bank Name</label>
                          <input
                            // placeholder="State Bank Of India"
                            className={`form-control ${
                              touched.BankName && errors.BankName
                                ? "error-input"
                                : ""
                            }`}
                            type="text"
                            name="BankName"
                            value={values.BankName || ""}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            autoComplete="none"
                          />
                          <span className="input-error">
                            {touched.BankName && errors.BankName
                              ? errors.BankName
                              : null}
                          </span>
                        </div>
                      </div>
                      <div className="column-swa">
                        <div className="form-group">
                          <label className="col-form-label">
                            Account Holder Name
                          </label>
                          <input
                            //  placeholder="Tata Group"
                            className={`form-control ${
                              touched.AccountHolderName &&
                              errors.AccountHolderName
                                ? "error-input"
                                : ""
                            }`}
                            type="text"
                            name="AccountHolderName"
                            value={values.AccountHolderName || ""}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            autoComplete="none"
                          />
                          <span className="input-error">
                            {touched.AccountHolderName &&
                            errors.AccountHolderName
                              ? errors.AccountHolderName
                              : null}
                          </span>
                        </div>
                      </div>
                      <div className="column-swa">
                        <div className="form-group">
                          <label className="col-form-label">
                            Account Number
                          </label>
                          <input
                            //placeholder="004856476t4785"
                            className={`form-control ${
                              touched.AccountNumber && errors.AccountNumber
                                ? "error-input"
                                : ""
                            }`}
                            type="text"
                            name="AccountNumber"
                            value={values.AccountNumber || ""}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            autoComplete="none"
                          />
                          <span className="input-error">
                            {touched.AccountNumber && errors.AccountNumber
                              ? errors.AccountNumber
                              : null}
                          </span>
                        </div>
                      </div>
                      <div className="column-swa">
                        <div className="form-group">
                          <label className="col-form-label">IFSC Code</label>
                          <input
                            className={`form-control ${
                              touched.IFSCCode && errors.IFSCCode
                                ? "error-input"
                                : ""
                            }`}
                            // placeholder="SBIN767667876"
                            type="text"
                            name="IFSCCode"
                            value={values.IFSCCode || ""}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            autoComplete="none"
                          />
                          <span className="input-error">
                            {touched.IFSCCode && errors.IFSCCode
                              ? errors.IFSCCode
                              : null}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <AddUpdateCardFooter cancel={cancel} PageId={CompanyId} />
              </Form>
            )}
          </Formik>
        </div>
      </div>
      {loader && <Loader />}
      <Toaster />
    </>
  );
};

export default CompanyAddEdit;
