import { ADDTOCART, REMOVE, EMPTY } from "../Constant";

export const CartData = (data = [], action) => {
  switch (action.type) {
    case ADDTOCART:
     // console.warn("reducer called", action);
      return [action.data, ...data];
    case REMOVE:
     // console.warn("reducer called", action);
      data.length = data.length ?data.length - 1 :[];
      return [...data];
    case EMPTY:
      //console.warn("reducer called", action);
      data = [];
      return [...data];
    default:
     // console.warn("nodata", action);

      return data;
  }
 
};
