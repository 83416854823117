import * as Yup from "yup";
export const loginSchema = Yup.object().shape({
  password: Yup.string().required("Password is required"),
  email: Yup.string()
    .required("Email is required")
    .email("Email format is Invalid"),
});

export const productSchema = Yup.object().shape({
  productFamilyName: Yup.string()
    .required("Product Family Name is required")
    .max(40, "Please enter valid name")
    .min(3, "Product Name at least 3 character"),
  status: Yup.string().required("Status is required"),
});
export const subscriptionSchema = Yup.object().shape({
  companyName: Yup.string().required("Company Name is required"),
  plan: Yup.string().required("Plan is required"),
  billingType: Yup.string().required("BillingType is required"),
  companyStatus: Yup.string().required("Status is required"),
  shipingEmail: Yup.string()
    .email("Email format is Invalid")
    .max(40, "Please enter valid email"),
  billingEmail: Yup.string()
    .email("Email format is Invalid")
    .max(40, "Please enter valid email"),
  billingAddress1: Yup.string().max(40, "Please enter valid address"),
  billingAddress2: Yup.string().max(40, "Please enter valid address"),
  billingCity: Yup.string().max(40, "Please enter valid city"),
  billingPincode: Yup.string()
    .max(6, "Please enter valid pincode")
    .matches(/^[0-9]*$/, "Please enter valid pincode"),
  billingMobile: Yup.string()
    .max(10, "Please enter valid number")
    .matches(/^[0-9]*$/, "Please enter valid number"),
  shipingAddress1: Yup.string().max(40, "Please enter valid address"),
  shipingAddress2: Yup.string().max(40, "Please enter valid address"),
  shipingCity: Yup.string().max(40, "Please enter valid city"),
  shipingPincode: Yup.string()
    .max(6, "Please enter valid pincode")
    .matches(/^[0-9]*$/, "Please enter valid pincode"),
  shipingMobile: Yup.string()
    .max(10, "Please enter valid number")
    .matches(/^[0-9]*$/, "Please enter valid number"),
});

export const companySchema = Yup.object().shape({
  companyName: Yup.string()
    .required("Company Name is required")
    .max(40, "Please enter valid name"),
  Subdomain: Yup.string()
    .required("Subdomain is required")
    .matches("^[a-zA-Z]+[a-zA-Z0-9]*$", "Subdomain must be valid Pattern")
    .max(40, "Please enter valid domain"),
  //companyStatus: Yup.string().required("Status is required"),
  LoginEmailAddress: Yup.string()
    .required("Login Email is required")
    .email("Email (Invalid format)")
    .max(40, "Please enter valid email Address"),
  LoginPassword: Yup.string().required("Login Password is required"),
  CompanyEmailAddress: Yup.string()
    .required("Company Email is required")
    .email("Email (Invalid format)")
    .max(40, "Please enter valid email Address"),
  companyWebsite: Yup.string()
    .matches(
      "(https?://[\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?",
      "Company URL must be Valid format!"
    )
    .max(40, "URL Must be valid Pattern"),
  GstNo: Yup.string()
    .matches(
      "^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$",
      "Company GST No. must be Valid format!"
    )
    .max(40, "Gst Must be valid Pattern"),
  branchName: Yup.string().max(40, "Branch Name Must be valid"),
  NoOfEmployee: Yup.string()
    .max(20, "Please enter valid number")
    .matches(/^[0-9]*$/, "Please enter valid number"),
  POCEmail: Yup.string()
    .email("Email format is Invalid")
    .max(40, "Please enter valid email"),
  shipingEmail: Yup.string()
    .email("Email format is Invalid")
    .max(40, "Please enter valid email"),
  billingEmail: Yup.string()
    .email("Email format is Invalid")
    .max(40, "Please enter valid email"),
  billingAddress1: Yup.string().max(40, "Please enter valid address"),
  billingAddress2: Yup.string().max(40, "Please enter valid address"),
  billingCity: Yup.string().max(40, "Please enter valid city"),
  billingPincode: Yup.string()
    .max(6, "Please enter valid pincode")
    .matches(/^[0-9]*$/, "Please enter valid pincode"),
  billingMobile: Yup.string()
    .max(10, "Please enter valid number")
    .matches(/^[0-9]*$/, "Please enter valid number"),
  shipingAddress1: Yup.string().max(40, "Please enter valid address"),
  shipingAddress2: Yup.string().max(40, "Please enter valid address"),
  shipingCity: Yup.string().max(40, "Please enter valid city"),
  shipingPincode: Yup.string()
    .max(6, "Please enter valid pincode")
    .matches(/^[0-9]*$/, "Please enter valid pincode"),
  shipingMobile: Yup.string()
    .max(10, "Please enter valid number")
    .matches(/^[0-9]*$/, "Please enter valid number"),
  POCMobileNo: Yup.string()
    .max(10, "Please enter valid number")
    .matches(/^[0-9]*$/, "Please enter valid number"),
  POCName: Yup.string().max(40, "Please enter valid name"),
  BankName: Yup.string().max(40, "Please enter valid name"),
  AccountHolderName: Yup.string().max(40, "Please enter valid name"),
  AccountNumber: Yup.string()
    .max(20, "Please enter valid number")
    .matches(/^[0-9]*$/, "Please enter valid number"),
  IFSCCode: Yup.string().max(10, "Please enter valid code"),
});

export const planSchema = Yup.object().shape({
  productFamily: Yup.string().required("Product Family Name is required"),
  planExternal: Yup.string()
    .required("Plan External Name is required")
    .max(40, "Please enter valid name")
    .min(3, "Plan Name at least 3 character"),
  planInternal: Yup.string()
    .required("Plan Internal Name is required")
    .max(40, "Please enter valid name")
    .min(3, "Plan Name at least 3 character"),
  pricingModule: Yup.string().required("Pricing Module is required"),
  price: Yup.string()
    .required("Price is required")
    .matches(/^[0-9]*$/, "Please enter valid Price")
    .max(6, "Please enter valid Price"),
  billingCycle: Yup.string().required("Billing Cycle is required"),
  frequency: Yup.string().required("Frequency is required"),
  status: Yup.string().required("Status is required"),
});

export const contactSchema = Yup.object().shape({
  fName: Yup.string()
    .required("First Name is required")
    .max(40, "Please enter valid name"),
  lName: Yup.string().max(40, "Please enter valid name"),
  email: Yup.string()
    .required("Email is required")
    .email("Email (Invalid format)")
    .max(40, "Please enter valid email Address"),
  mobileNo: Yup.string()
    .required("Mobile no. is required")
    .max(10, "Please enter valid number")
    .matches(/^[0-9]*$/, "Please enter valid number"),
  companyName: Yup.string()
    .required("Company Name is required")
    .max(40, "Please enter valid name"),
});
