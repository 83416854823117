import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
//const PageNotFound = lazy(() => import("../components/PageNotFound"));

const Auth = (props) => {
    const {Component}=props
    const navigate=useNavigate();
    useEffect(()=>{
if(!localStorage.getItem('rememberme')){
    navigate('/page-not-found')
}
    },[])
  return (
    <div>
        <Component/>
    </div>
  )
}

export default Auth