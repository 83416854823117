import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ImagePath } from "../ImagePath";
import "../assets/css/landing.css";
import { contactSchema } from "../components/FormValidation";
import { Formik, Form } from "formik";
import { api } from "../helper/Api";
import Loader from "../components/loader";
import toast, { Toaster } from 'react-hot-toast';
import { landingPageFormValue } from "../helper/InitialFormValue";
import axios from "axios";

const LandingPage = () => {
  const [loader, setLoader] = useState(false);
  const onSubmit = (values, { resetForm }) => {
   // setLoader(true)
    const { fName, lName, email, mobileNo, companyName, message } = values;
    console.log("Valuessss",values);
    let formData = new FormData();
    console.log("FormData", formData);
    formData.append("lead_id", '');
    formData.append("fname", fName);
    formData.append("lname", lName);
    formData.append("email", email);
    formData.append("phone_number", mobileNo);
    formData.append("company_name", companyName);
    formData.append("designation", '');
    formData.append("subject", '');
    formData.append("message", message);
    formData.append("status", '');

    {
      // api.post(`/superadmin/contact/`, formData)
     
      var config = {
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}/superadmin/contact/`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: formData
    };
    axios(config)
        .then(function (response) {
          if (response.data.status_code === 200) {
           // setLoader(false);
            resetForm({})
            toast.success('Thank you for Contacting us , our Team will contact you soon! ')
          } else if (response.data.status_code === 400) {
            toast.error(response.message ? response.message : 'Fill all *Required field')
            setLoader(false)
          }
        })
        .catch((error) => {         
          //setLoader(false);
          toast.error(error ? error : 'Connection Fail')
        });
    }
  };
  return (
    <>
      <header className="main-header">
        <nav className="navbar navbar-expand-md">
          <div className="container-fluid c-padd">
            <Link to="/" className="navbar-brand">
              <img src={ImagePath.logo} alt="Logo" />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarCollapse"
              aria-controls="navbarCollapse"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <i className="lni-menu"></i>
            </button>
            <div className="collapse navbar-collapse" id="navbarCollapse">
              <ul className="navbar-nav mr-auto w-100 justify-content-end clearfix">
                <li className="nav-item ">
                  <Link
                    className="nav-link"
                    activeclassname="active"
                    onClick={() => window.location.replace("#about")}
                    to="/#about"
                    offset={-70}
                    duration={500}
                  >
                    About Us
                  </Link>
                </li>

                {/* <li className="nav-item">
                  <Link
                    className="nav-link"
                    activeclassname="active"
                  
                    to="/#blog"
                    offset={-70}
                    duration={500}
                  >
                    Blog
                  </Link>
                </li> */}

                <li className="nav-item">
                  <Link
                    className="nav-link"
                    activeclassname="active"
                    onClick={() => window.location.replace("#contact")}
                    to="/#contact"
                    offset={-70}
                    duration={500}
                  >
                    Contact Us
                  </Link>
                </li>
                <li className="nav-item">
                  <button onClick={() => window.location.replace("#contact")} type="button" className="btn theme-btn">
                    Request Demo
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
      {/* end of header */}

      <section className="page-banner ">
        <div className="inner-page-banner grid_50">
          <div className="page-banner-content pl_64">
            <h1>Ignite Business with Powerful Solutions</h1>
            <h5>
              Streamline processes and boost productivity with our intelligent
              automation solutions. Experience enhanced efficiency and unlock
              new opportunities for your business
            </h5>
            <button  onClick={() => window.location.replace("#contact")} type="button" className="btn theme-btn lg">
              Get Started Now
            </button>
          </div>
          <div className="page-banner-image">
            <figure className="imgbox">
              <img
                src={ImagePath.BannerImage}
                alt="banner-image"
                className="img-fluid"
              />
            </figure>
          </div>
        </div>
      </section>
      {/* end of banner */}

      <section className="section_padding sec_1">
        <div className="container-fluid c-padd">
          <div className="page-heading text-center">
            <h2>Discover The Key Features That Set Us Apart</h2>
            <p>
              A desire to help and empower others between community contributors
              in technology began to grow in 2023.
            </p>
          </div>

          <div className="image_row grid_50">
            <div className="column_text">
              <h3>Simplify Document Collection</h3>
              <p>
                Our software allows you to request and collect all necessary
                employee documents in one place, reducing the time and effort
                required to complete the onboarding process
              </p>
              <ul className="bullet_list">
                <li>Centralized Document Request</li>
                <li>Secure Document Upload</li>
                <li>Automated Reminders and Notifications</li>
              </ul>
            </div>
            <div className="column_image">
              <figure className="imgbox-wrap">
                <img
                  src={ImagePath.LandingImage1}
                  alt="banner-image"
                  className="img-fluid"
                />
              </figure>
            </div>
          </div>
        </div>
      </section>
      {/* end of sec_1 */}
      <section className="section_padding sec_2 section_gradient">
        <div className="container-fluid c-padd">
          <div className="image_row grid_50">
            <div className="column_text">
              <h3>Ensure Compliance</h3>
              <p>
                Our software helps ensure compliance with all necessary
                regulations by providing a secure platform for storing sensitive
                employee information required as per statutory compliances
              </p>
              <ul className="bullet_list">
                <li>Secure Data Storage</li>
                <li>Document Retention and Archiving</li>
                <li>Compliance Reporting and Auditing</li>
              </ul>
            </div>
            <div className="column_image">
              <figure className="imgbox-wrap">
                <img
                  src={ImagePath.LandingImage2}
                  alt="banner-image"
                  className="img-fluid"
                />
              </figure>
            </div>
          </div>
        </div>
      </section>
      {/* end of sec_2 */}
      <section className="section_padding sec_3">
        <div className="container-fluid c-padd">
          <div className="image_row grid_50">
            <div className="column_text">
              <h3>Reduce Errors</h3>
              <p>
                With our software, you can minimize errors and ensure accuracy
                by automating document collection and reducing the need for
                manual data entry.
              </p>
              <ul className="bullet_list">
                <li>Automated Data Capture</li>
                <li>Validation and Error Checks</li>
                <li>Integration with Existing Systems</li>
              </ul>
            </div>
            <div className="column_image">
              <figure className="imgbox-wrap">
                <img
                  src={ImagePath.LandingImage3}
                  alt="banner-image"
                  className="img-fluid"
                />
              </figure>
            </div>
          </div>
        </div>
      </section>
      {/* end of sec_3 */}
      <section className="section_padding sec_4 section_gradient">
        <div className="container-fluid c-padd">
          <div className="image_row grid_50">
            <div className="column_text">
              <h3>Save Time and Resources</h3>
              <p>
                Our software helps you save time and resources by eliminating
                the need for paper-based document collection and manual data
                entry.
              </p>
              <ul className="bullet_list">
                <li>Paperless Document Collection</li>
                <li>Automated Data Extraction</li>
                <li>Streamlined Workflow</li>
              </ul>
            </div>
            <div className="column_image">
              <figure className="imgbox-wrap">
                <img
                  src={ImagePath.LandingImage4}
                  alt="banner-image"
                  className="img-fluid"
                />
              </figure>
            </div>
          </div>
        </div>
      </section>
      {/* end of sec_4 */}
      <section className="section_padding sec_5">
        <div className="container-fluid c-padd">
          <div className="image_row grid_50">
            <div className="column_text">
              <h3>Secure Document Storage</h3>
              <p>
                Our software provides a secure platform for storing sensitive
                employee information, ensuring compliance with all necessary
                regulations.
              </p>
              <ul className="bullet_list">
                <li>Advanced Encryption and Security Measures</li>
                <li>Role-Based Access Controls</li>
                <li>Audit Trail and Document Versioning</li>
              </ul>
            </div>
            <div className="column_image">
              <figure className="imgbox-wrap">
                <img
                  src={ImagePath.LandingImage5}
                  alt="banner-image"
                  className="img-fluid"
                />
              </figure>
            </div>
          </div>
        </div>
      </section>
      {/* end of sec_5 */}
      <section className="section_padding sec_6 section_gradient">
        <div className="container-fluid c-padd">
          <div className="image_row grid_50">
            <div className="column_text">
              <h3>Document Tracking and Reporting</h3>
              <p>
                Our software includes tools for tracking the status of document
                collection and generating reports on employee onboarding
                progress.
              </p>
              <ul className="bullet_list">
                <li>Real-time Document Status Tracking</li>
                <li>Automated Reminders and Notifications</li>
                <li>Comprehensive Reporting and Analytics</li>
              </ul>
            </div>
            <div className="column_image">
              <figure className="imgbox-wrap">
                <img
                  src={ImagePath.LandingImage6}
                  alt="banner-image"
                  className="img-fluid"
                />
              </figure>
            </div>
          </div>
        </div>
      </section>
      {/* end of sec_6 */}
      <section id="about" className="section_padding section_about">
        <div  className="container-fluid c-padd">
          <div className="about_row grid_50 align-items-center">
            <div className="about_swa">
              <small>About Us</small>
              <h2>Make your customers happy by giving services.</h2>
              <p>
                With Swagatham, we are dedicated to providing innovative HR
                solutions to businesses of all sizes. With more than a decade of
                experience in the HR staffing industry, we understand the
                challenges that businesses face when it comes to managing their
                human resources. That's why we developed our cutting-edge
                software solution, designed to transform the way businesses
                onboard and manage their employees.
              </p>
              <p>
                With "Swagatham", you can trust that you're getting a software
                solution that is backed by years of experience and expertise in
                the HR staffing industry. Our commitment to innovation and
                excellence means that we are always striving to improve and
                enhance our software to meet the evolving needs of businesses in
                today's fast-paced world.
              </p>
              <button type="button" className="btn theme-btn lg">
                Read More
              </button>
            </div>
            <div className="about_banner">
              <figure className="m-0">
                <img
                  src={ImagePath.LandingImage7}
                  alt="banner-image"
                  className="img-fluid"
                />
              </figure>
            </div>
          </div>
        </div>
      </section>
      {/* end of about */}
      <section className="demo_section">
        <div className="container-fluid c-padd text-center">
          <h2>What Are You Looking For? Get Started Now</h2>
          <h5>
            There are many variations of passages of Lorem Ipsum but the
            majority have suffered in some form.
          </h5>
          <button onClick={() => window.location.replace("#contact")} type="button" className="btn theme-btn">
            Request Demo
          </button>
        </div>
      </section>
      {/* end of demo section */}
      <section  className="section_connect section_padding">
        <div id="contact" className="container-fluid c-padd">
          <div className="grid_50 contact_row">
            <div className="contact_info">
              <small>Contact Us</small>
              <h3>Get In Touch With Us</h3>
              <p>
                We create projects for companies and startups with a passion for
                quality
              </p>
              <ul className="contact_ul">
                <li>
                  <span className="c_icon">
                    <i className="la la-map-marker"></i>
                  </span>
                  <div className="c_info">
                    <span>Our Location</span>
                    <small>712, Time square Arcade, Thaltej, Ahmedabad ,India</small>
                  </div>
                </li>
                <li>
                  <span className="c_icon">
                    <i className="la la-phone"></i>
                  </span>
                  <div className="c_info">
                    <span>Phone Number</span>
                    <small>7573007389</small>
                  </div>
                </li>
                <li>
                  <span className="c_icon">
                    <i className="la la-envelope"></i>
                  </span>
                  <div className="c_info">
                    <span>Email Address</span>
                    <small>info@swagatham.co.in</small>
                  </div>
                </li>
              </ul>
            </div>
            <div className="support_form_box">
              <Formik
                initialValues={landingPageFormValue}
                validationSchema={contactSchema}
                onSubmit={onSubmit}
                enableReinitialize
              >
                {({ errors, values, touched, handleChange, handleBlur }) => (
                  <Form id="support_form" className="support_form">
                    <div className="half_circle"></div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <input
                            type="text"
                            className={`form-control ${(touched.fName && errors.fName)
                              ? "error-input"
                              : ""
                              }`}
                            id="firstName"
                            name="fName"
                            placeholder="First Name"
                            value={values.fName || ""}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          //  required
                          //  data-error="Please enter your first name"
                          />
                          <span className="input-error">
                            {touched.fName && errors.fName ? errors.fName : null}
                          </span>
                          {/* <div className="help-block with-errors"> {touched.fName && errors.fName ? errors.fName : null}</div> */}

                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <input
                            type="text"
                            placeholder="Last Name"
                            id="lastName"
                            className="form-control"
                            name="lName"
                            value={values.lName || ""}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          //  required
                          //  data-error="Please enter your first name"
                          />

                          <div className="help-block with-errors"></div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <input type="text" placeholder="Enter Email" id="email" className={`form-control ${(touched.email && errors.email)
                            ? "error-input"
                            : ""
                            }`} name="email" value={values.email || ""}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          //  required
                          //  data-error="Please enter your first name" 
                          />
                           <span className="input-error">
                            {touched.email && errors.email ? errors.email : null}
                          </span>
                          <div className="help-block with-errors"></div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className={`form-control ${(touched.mobileNo && errors.mobileNo)
                              ? "error-input"
                              : ""
                              }`}
                            id="phone"
                            name="mobileNo"
                            placeholder="Your Phone."
                            value={values.mobileNo || ""}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          //  required
                          //  data-error="Please enter your first name"
                          />
                           <span className="input-error">
                            {touched.mobileNo && errors.mobileNo ? errors.mobileNo : null}
                          </span>
                          <div className="help-block with-errors"></div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <input
                            type="text"
                            placeholder="Company Name"
                            id="company"
                            className={`form-control ${(touched.companyName && errors.companyName)
                              ? "error-input"
                              : ""
                              }`}
                            name="companyName"
                            value={values.companyName || ""}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                           <span className="input-error">
                            {touched.companyName && errors.companyName ? errors.companyName : null}
                          </span>
                          <div className="help-block with-errors"></div>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group">
                          <textarea
                            className="form-control"
                            id="message"
                            name="message"
                            placeholder="Your Message"
                            rows="5"
                            value={values.message || ""}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            data-error="Write your message"

                          ></textarea>
                          <div className="help-block with-errors"></div>
                        </div>

                      </div>
                      <div className="submit-button col-12">
                        <button
                          className="btn theme-btn w-100"
                          id="form-submit"
                          type="submit"
                        >
                          Send Message
                        </button>

                      </div>
                    </div>
                    {/* { errors && toast.error('Fill all required field')} */}
                  </Form>

                )}
              </Formik>
              {loader && <Loader />}
              <Toaster />
            </div>
          </div>
        </div>
      </section>
      {/* end of support section */}
      <footer className="small_footer section_padding">
        <div className="container-fluid c-padd">
          <div className="d-flex justify-content-between footer-line">
            <div className="fo-logo">
              <Link to="/" className="f-brand">
                <img src={ImagePath.logo} alt="Logo" />
              </Link>
            </div>
            <div className="social_icon  text-center">
              <a className="facebook" href="#">
                <i className="lni-facebook-filled"></i>
              </a>
              <a className="twitter" href="#">
                <i className="lni-twitter-filled"></i>
              </a>
              <a className="instagram" href="#">
                <i className="la la-instagram"></i>
              </a>
              <a className="linkedin" href="#">
                <i className="lni-linkedin-filled"></i>
              </a>
            </div>
          </div>
          <div className="footer_copy">
            <div className="copy_link">
              <Link to="/">Privacy Policy</Link>
              <Link to="/">Use of Cookies</Link>
              <Link to="/">Terms and Conditions</Link>
            </div>
            <div className="copy_text">
              <span>Copyright © 2023 Swagatham All Rights Reserved.</span>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default LandingPage;
