import { createContext } from "react";

//import { getItem } from "./CommonService";

const authContext = createContext({
  authenticated: localStorage.getItem('itemId'),
  setAuthenticated: (auth) => { },
 
});

export default authContext;
