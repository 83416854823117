import React, { useState, useEffect } from "react";
import { api } from "../helper/Api";
import Modal from "react-modal";
import { Link, useNavigate, useParams } from "react-router-dom";
import DefaultPage from "./DefaultPage";
import { Table } from "antd";
import { itemRender, onShowSizeChange } from "../components/Pagination";
import { Circles } from "react-loader-spinner";
import { customStyles } from "../components/ModalStyle";
import Loader from "../components/loader";
import { TableSkeltonPlan } from "../components/TableSkelton";
import EmptyBox from "../assets/Images/empty-box.svg";

const Companies = () => {
  const navigate = useNavigate();
  let { CompanyId } = useParams();

  const [loader, setLoader] = useState(true);
  const [companyInvoiceData, setCompanyInvoiceData] = useState([]);
  const [listingSearchValue, setListingSearchValue] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState({
    deleteModal: false,
    urlModal: false,
  });

  useEffect(() => {
    getCompanyInvoiceList();
  }, []);
  const getCompanyInvoiceList = () => {
    // api.get("/superadmin/company").then((response) => {
    api
      .get(
        `/superadmin/invoice/list/?search=${listingSearchValue}&company_id=${CompanyId}`
      )
      .then((response) => {
        setLoader(false);

        if (response.status_code === 200) {
          var data = response.data;

          setCompanyInvoiceData(data);
        } else {
        }
      });
  };

  const columns = [
    {
      title: "Status",

      render: (a) => (
        <>
          <span
            className={`status-label ${
              a.status === "A"
                ? "active"
                : a.status === "I"
                ? "inactive"
                : "cancel"
            }`}
          >
            {a.status === "A"
              ? "Active"
              : a.status === "I"
              ? "Inactive"
              : "Cancel"}{" "}
          </span>
        </>
      ),
    },
    {
      title: "Date & Time",
      render: (a) => (
        <>
          <span className={`text-nowrap`}>{a.created_date}</span>
        </>
      ),
    },
    {
      render: (record) => (
        <div>
          <Link
            // className={`text-nowrap`}
            // to={`/InvoiceInfo/${record.invoice_id}`}
          >
            {record.company_name}{" "}
          </Link>
        </div>
      ),
      title: "Company Name",
    },
    {
      render: (a) => (
        <>
          <span className={`text-nowrap`}>{a.plan_name}</span>
        </>
      ),

      title: (titleProps) => {
        const sortedColumn = titleProps.sortColumns?.find(
          ({ column }) => column.key === "name"
        );

        return (
          <div className="d-flex align-items-center justify-content-between">
            <span>Plan Name</span>
          </div>
        );
      },
    },
    {
      title: "Product Group",
      render: (a) => (
        <>
          <span className={`text-nowrap`}>{a.product_name}</span>
        </>
      ),
    },

    {
      dataIndex: "email",
      title: (titleProps) => {
        const sortedColumn = titleProps.sortColumns?.find(
          ({ column }) => column.key === "name"
        );

        return (
          <div className="d-flex align-items-center justify-content-between">
            <span>Email Id</span>
            {/* {sortedColumn?.order === "ascend" ? (
              <i className="la la-arrow-up"></i>
            ) : (
              <i className="la la-arrow-down"></i>
            )} */}
          </div>
        );
      },
    },
    {
      title: "Payment Mode ",
      dataIndex: "payment_mode",
    },
    {
      title: "Transaction ID ",
      dataIndex: "reference_code",
    },
    {
      title: "Issued on ",

      render: (a) => (
        <>
          <span className={`text-nowrap`}>{a.issue_date}</span>
        </>
      ),
    },

    {
      title: "Amount ",
      render: (a) => (
        <>
          <span className={`text-nowrap`}>₹ {a.amount}</span>
        </>
      ),
    },
    {
      render: (a) => (
        <>
          <span className={`text-nowrap`}>{a.paid_date}</span>
        </>
      ),
      title: (titleProps) => {
        const sortedColumn = titleProps.sortColumns?.find(
          ({ column }) => column.key === "name"
        );

        return (
          <div className="d-flex align-items-center justify-content-between">
            <span>Paid On</span>
            {/* {sortedColumn?.order === "ascend" ? (
              <i className="la la-arrow-up"></i>
            ) : (
              <i className="la la-arrow-down"></i>
            )} */}
          </div>
        );
      },
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",

      name: record.name,
    }),
  };
  const edit = () => {
    navigate(`/company-edit/${CompanyId}`);
  };
  const deleteCompany = () => {
    setModalIsOpen({ deleteModal: true, urlModal: false });
  };

  const closeModal = () => {
    setModalIsOpen({ deleteModal: false, urlModal: false });
  };
  return (
    <>
      <DefaultPage />

      <div className="page-wrapper supar-admin-page-wrapper">
        <div className="content container-fluid">
          <div className="card">
            <div className="card-body p-2">
              <div className="d-flex align-items-center justify-content-between">
                <div className="company-header-wrap d-flex align-items-center ">
                  <div className="c-icon c">
                    <span className="icon icon-company"></span>
                  </div>
                  <div className="pl-3">
                    <small>company</small>
                    {/* <h3 className="page-title">Tata Group</h3> */}
                  </div>
                </div>
                {/* <button
                  type="button"
                  onClick={() => edit()}
                  className="btn-light-outlined btn rounded"
                  disabled={false}
                >
                  <i className="icon icon-pencil"></i>
                  Edit
                </button> */}
              </div>
            </div>
            <div className="card-footer">
              <div className="ctab-links">
                <Link to={`/companyInfo/${CompanyId}`}>
                  <span>Company Details</span>
                </Link>
                <Link to={`/companyInvoice/${CompanyId}`} className="active">
                  <span>Invoices</span>
                </Link>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-action-wrap d-flex align-items-center justify-content-between pt-3 pb-2">
              <div className="card-actions pr-4">
                {/* <button
                  type="button"
                  className="btn-light-outlined btn rounded"
                  disabled={true}
                >
                  Cancel Invoice
                </button> */}
                {/* <div
                  className="dropdown status-dropdown"
                  disabled={selectedRowKeys.length > 0 ? false : true}
                >
                  <button
                    className="btn btn-light-outlined dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Change Status
                  </button>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <a className="dropdown-item" href="#">
                      Active
                    </a>
                    <a className="dropdown-item" href="#">
                      Inactive
                    </a>
                    <a className="dropdown-item active" href="#">
                      Pending
                    </a>
                    <a className="dropdown-item" href="#">
                      Rejected
                    </a>
                    <a className="dropdown-item" href="#">
                      Approved
                    </a>
                  </div>
                </div> */}
              </div>
              {/* <div className="form-group  mb-0 abs-searchbox">
                <input
                  type="text"
                  className="form-control mb-0"
                  placeholder="search"
                />
                <i className="la la-search"></i>
              </div> */}
            </div>
            <div className="card-body pt-1">
              <div className="table-responsive">
                <Table
                  className="table-swagatham"
                  pagination={{
                    total: companyInvoiceData.length,
                    showTotal: (total, range) =>
                      `${range[0]} - ${range[1]} of ${total} `,
                    defaultPageSize: 20,
                    showSizeChanger: false,
                    onShowSizeChange: onShowSizeChange,
                    itemRender: itemRender,
                  }}
                  style={{ overflowX: "auto" }}
                  columns={columns}
                  locale={{
                    emptyText: loader ? (
                      <TableSkeltonPlan />
                    ) : (
                      // <Circles
                      //   height="100"
                      //   width="100"
                      //   color="#00c5fb"
                      //   ariaLabel="circles-loading"
                      //   wrapperStyle={{
                      //     marginLeft: "45%",
                      //   }}
                      //   wrapperclassName=""
                      //   visible={true}
                      // />
                      <div className="nodata-intable">
                        <figure>
                          <img src={EmptyBox} alt="box" className="img-fluid" />
                        </figure>
                        <h4>No Data Found</h4>
                      </div>
                    ),
                  }}
                  bordered
                  dataSource={companyInvoiceData}
                  rowKey={(record) => record.id}
                  // onChange={this.handleTableChange}
                  rowSelection={{ ...rowSelection }}
                />
              </div>
            </div>
          </div>
        </div>

        {modalIsOpen.deleteModal && (
          <div>
            <Modal isOpen={modalIsOpen.deleteModal} style={customStyles}>
              <div style={{ padding: "15px" }}>
                <h3> `Are you sure want to delete` </h3>
                <h2 style={{ textAlign: "center", color: "#00c5fb" }}>
                  'companyName'
                </h2>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "33px",
                  }}
                >
                  <button
                    className="btn btn-primary"
                    style={{ marginRight: "10px" }}
                    onClick={closeModal}
                  >
                    Cancel
                  </button>
                  <button className="btn btn-danger">Delete</button>
                </div>
              </div>
            </Modal>
          </div>
        )}
      </div>
    </>
  );
};

export default Companies;
