const REGEX = {
    email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    password: /(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
    firstName: /^[a-zA-Z '!"/()&*]{3,}$/,
    lastName: /^[a-zA-Z '!"/()&*]{3,}$/,
    cellPhone: /^[0-9]{0,15}$/,
    totp: /^[0-9]{6}$/,
    customerName: /^[a-zA-Z '!"/()&*0-9]{3,}$/,
    espName: /^[a-zA-Z '!"/()&*0-9]{3,}$/,
    brandEspName: /^[a-zA-Z '!"/()&*0-9]{3,}$/,
    brandName: /^[a-zA-Z '!"/()&*0-9]{3,}$/,
    item: /^[a-zA-Z '!"/()&*0-9]{3,}$/,
    sectionName: /^[a-zA-Z '!"/()&*0-9]{3,}$/,
    field: /^[a-zA-Z '!"/()&*0-9]{3,}$/,
    campaignName: /^[a-zA-Z '!"/()&*0-9]{3,}$/,
    emailName: /^[a-zA-Z '!"/()&*0-9]{3,}$/,
}

const ERROR_MESSAGE = {
    emailRequired: 'Email is required.',
    emailInvalid: 'Please enter a valid email address.',
    passwordRequired: 'Password is required.',
    passwordInvalid: 'Password must contain at least one number, upper case, lower case, special character & 8 characters.',
    confirmPasswordRequired: 'Confirm password is required.',
    confirmPasswordInvalid: `Password and confirm password doesn't match.`,
    firstNameRequired: 'First name is required.',
    firstNameInvalid: 'Please enter a valid first name.',
    lastNameRequired: 'Last name is required.',
    lastNameInvalid: 'Please enter a valid last name.',
    defaultCommunicationPreferenceRequired: 'Please select a default communication preference.',
    slackUserNameRequired: 'Slack user name is required.',
    slackUserNameInvalid: 'Please enter a valid slack user name.',
    cellPhoneRequired: 'Cell phone number is required.',
    cellPhoneInvalid: 'Please enter a valid cell phone number.',
    locationRequired: 'Location is required.',
    locationInvalid: 'Please enter a valid location.',
    workingTimeZoneRequired: 'Working time zone is required.',
    workingTimeZoneInvalid: 'Please enter a valid working time zone.',
    noInternetConnection: 'Please check your internet connection.',
    somethingWentWrong: 'Something went wrong.',
    totpRequired: 'TOTP is required.',
    totpInvalid: 'Please enter a valid TOTP.',
    roleRequired: 'Please select a role.',
    positionRequired: 'Please select a position.',
    supervisorRequired: 'Please select a supervisor.',
    espNameRequired: 'ESP name is required.',
    espNameInvalid: 'Please enter a valid ESP.',
    customerNameRequired: 'Customer name is required.',
    customerNameInvalid: 'Please enter a valid customer name.',
    xrfRequired: 'Please select a XRF.',
    departmentRequired: 'Please select a department.',
    brandEspNameRequired: 'Brand ESP name is required.',
    brandEspNameInvalid: 'Please enter a valid Brand ESP.',
    espRequired: "Please select a ESP.",
    brandRequired: "Please select a Brand.",
    customerRequired: "Please select a customer.",
    brandNameRequired: 'Brand name is required.',
    brandNameInvalid: 'Please enter a valid brand name.',
    commentRequired: "Please enter a comment.",
    healthRequired: "Please select a health",
    itemRequired: "Item name is required",
    itemInvalid: "Please enter a valid item",
    fieldTypeRequired: "Please select a field type",
    itemNotAssigned: "This brand dosen't have any assigned item",
    sectionNameRequired: "Section name is required.",
    sectionNameInvalid: "Please enter a valid section name.",
    fieldRequired: "Field name is required",
    fieldInvalid: "Please enter a valid field name",
    campaignNameRequired: "Please enter a campaign name.",
    campaignNameInvalid: "Please enter a valid campaign name.",
    emailNameRequired: "Please enter a campaign name.",
    emailNameInvalid: "Please enter a valid campaign name.",
    campaignRequired: "Please select a campaign.",
    emailsRequired: "Please select a email.",
    tagetEndDayRequired: "Target end day is required.",
    tragetEndDateRequired: "Target end date is required",
    tragetEndTimeRequired: "Target end time is required",
    primaryResourceRequired: "Please select a primary resource",
    noTemplates: "No templates available to display",
    tokenExpired: "Token expired",
    fromNameRequired: 'From name is required.',
    fromEmailRequired: 'From email is required.',
    toEmailRequired: 'To email is required.',
    endDayHourMinuteRequired: 'Please provide atleast traget end day or hour or minutes',
    approvalPositionsRequired: "Please select atleast a position who can apprve",
    segmentIsRequired: 'Please provide atleast one segment details',
    slackGroupName: 'Please enter a slack group name',
    distributionEmail: 'Please enter a distribution email address',
    campaignTypeRequired: "Please select Campaign Type",
}

const GENERAL_MESSAGES = {
    pleaseWait: "Please wait...",
    noRecords: "No Records Found."
}

const PAGES = {
    dashoard: 1,
    userList: 2,
    addUser: 3,
    espList: 6,
    addEsp: 8,
    editEsp: 9,
    brandEspList: 7,
    addBrandEsp: 10,
    editBrandEsp: 11,
    customerList: 12,
    addCustomer: 13,
    editCustomer: 14,
    brandList: 15,
    cheatSheetList: 16,
    brandItemList: 18,
    brnadItemValue: 19,
    defaultSectionList: 20,
    campaignList: 21,
    addCampaign: 22,
    editCampaign: 23,
    campaignEmailSettings: 24,
    setupVendorCheatSheet: 25,
    customerDashboard: 26,
    emailValue: 27,
    operationDashBoard: 28
}

const ELEMENTS = {
    userList: {
        addOutsideUserButton: 1
    },
    addOutsideUser: {},
    espList: {
        addEspButton: 4,
        editEspButton: 5,
        deleteEspButton: 5,
    },
    customerList: {
        addCustomerButton: 10,
        editCustomerButton: 11,
        deleteCustomerButton: 12,
    },
    addCustomer: {
        endDate: 13
    },
    editCustomer: {
        endDate: 15
    },
    brandEspList: {
        addBrandEspButton: 7,
        editBrandEspButton: 8,
        deleteBrandEspButton: 9
    },
    brandList: {
        addBrandButton: 16,
        editBrandButton: 17,
        deleteBrandButton: 18,
        assignItemButton: 24,
        storeItemValueButton: 25,
        saveTemplate: 34
    },
    cheatSheetList: {
        addCheatSheetButton: 19,
        editCheatSheetButton: 20,
        deleteCheatSheetButton: 21,
    },
    brandItemList: {
        storeItemValueButton: 26
    },
    defaultSectionList: {
        addSectionButton: 27,
        editSectionButton: 28,
        deleteSectionButton: 29,
    },
    campaignList: {
        addCampaignButton: 30,
        editCampaignButton: 31,
        deleteCampaignButton: 33
    },
    operationDashBoard: {
        addCampaignButton: 35,
        editCampaignButton: 36,
        editCustomer: 37,
        editBrand: 38
    }
}

const PAGE_SIZE = [10, 25, 50, 100];
// const PAGE_SIZE = [1, 2, 5, 10];

const SORTING_ORDER = {
    ascending: "asc",
    descending: "desc"
}

const CUSTOM_DELETE_MODAL_STYLES = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

const FIELD_TYPES = {
    textbox: 1,
    textarea: 2,
    select: 3,
    file: 4
}

const COLOR_FILTERS = [{ name: 'Red', value: "color-red-light" }, { name: 'green', value: "color-grn-light" }, { name: 'Yellow', value: "color-yellow-light" }];

const FOLDERS = {
    profile: "profile",
};

const MODULES = {
    SECTION_MANAGEMENT: 1,
    CAMPAIGN_MANAGEMENT: 2,
    EMAIL_MANAGEMENT: 3,
    FIELD_MANAGEMENT: 4,
    BRAND_TEMPLATE_SETTINGS: 5,
    EMAIL_VALUE: 6
}

const FILTER_ICONS = [
    { icon: 'icon-contains', type: 'string', name: 'Contains', value: 'contain' },
    { icon: 'icon-not-contains', type: 'string', name: 'Not Contains', value: 'notContain' },
    { icon: 'icon-start-contains', type: 'string', name: 'Starts With', value: 'startWith' },
    { icon: 'icon-end-contains', type: 'string', name: 'Ends With', value: 'endsWith' },
    { icon: 'icon-less-than', type: 'number', name: 'Less Than', value: 'less' },
    { icon: 'icon-greater-than', type: 'number', name: 'Greater Than', value: 'greater' },
    { icon: 'icon-less-equal', type: 'number', name: 'Less Than Equal To', value: 'leq' },
    { icon: 'icon-greater-equal', type: 'number', name: 'Greater Than Equal To', value: 'geq' },
    { icon: 'icon-equals', type: 'number', name: 'Equal', value: 'eq' },
    { icon: 'icon-not-equals', type: 'number', name: 'Not Equal', value: 'notEq' },
    { icon: 'icon-equals', type: 'date', name: 'Equal', value: 'equal' },
    { icon: 'icon-not-equals', type: 'date', name: 'Not Equal', value: 'notequal' },
    { icon: 'icon-less-than', type: 'date', name: 'Before', value: 'before' },
    { icon: 'icon-greater-than', type: 'date', name: 'After', value: 'after' },
    { icon: 'icon-equals', type: 'color', name: 'Equal', value: 'equal' },
    { icon: 'icon-not-equals', type: 'color', name: 'Not Equal', value: 'notequal' }
];

const CUSTOMER_DASHBOARD_FILTER_FIELDS = [
    
    { field: "Department", type: 'string' },
    { field: "Survey", type: 'number' },
    { field: "Email", type: 'number' },
    { field: "RCA", type: 'number' },
    { field: "Date", type: 'date' },
    { field: "Color", type: 'color' },
];

export { FILTER_ICONS, CUSTOMER_DASHBOARD_FILTER_FIELDS, REGEX, ERROR_MESSAGE, PAGE_SIZE, PAGES, ELEMENTS, GENERAL_MESSAGES, SORTING_ORDER, CUSTOM_DELETE_MODAL_STYLES, COLOR_FILTERS, FIELD_TYPES, FOLDERS, MODULES };