import React, { useEffect, useState } from "react";
import DefaultPage from "./DefaultPage";
import { api } from "../helper/Api";
import { useNavigate, useParams } from "react-router-dom";
import { AddUpdateCardFooter, AddUpdateCardHeader } from "../components/UtilsAndModal";
import { Form, Formik } from "formik";
import { planSchema } from "../components/FormValidation";
import Swal from "sweetalert2";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Loader from "../components/loader";



const PlanAddEdit = () => {
  const navigate = useNavigate();
  let { PlanId } = useParams();
  const [loader, setLoader] = useState(false);


  const [billingCycleData, setBillingCycleData] = useState([]);
  const [frequencyData, setFrequencyData] = useState([]);
  const [priceModuleData, setPriceModuleData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [content, setContent] = useState('');
  const [formValue, setFormValue] = useState({
    productFamily: "",
    planExternal: "",
    planInternal: "",
    pricingModule: "",
    price: "",
    billingCycle: "",
    frequency: "",
    status: "",
    dec: "",
  });


  useEffect(() => {
    getProductList();
    getPlanFrequency();
    getBillingCycle();
    getPriceModule();

    if (PlanId) {
      getPlanDetails();
    }
  }, []);

  const getPlanDetails = () => {
    setLoader(true)
    api.get(`/superadmin/plan/listing/?plan_id=${PlanId}`).then((response) => {


      if (response.status_code === 200) {
        setLoader(false)
        var data = response.data[0];
        setFormValue({
          productFamily: data.product_id,
          planExternal: data.external_name,
          planInternal: data.internal_name,
          pricingModule: data.price_module_id,
          price: data.price,
          billingCycle: data.billing_cycle_id,
          frequency: data.plan_frequency_id,
          status: data.plan_status,
          dec: data.plan_description,
        });
        setContent(data.plan_description)
      } else {
        setLoader(false)

      }
    });
  };

  const getProductList = () => {
    api.get("/superadmin/product/listing/?product_id=0").then((response) => {
      if (response.status_code === 200) {
        setProductData(response.data);
      } else {
      }
    });
  };
  const getPlanFrequency = () => {
    api.get(`/superadmin/plan/plan_frequency_listing`).then((response) => {
      if (response.status_code === 200) {
        setFrequencyData(response.data);
      } else {
      }
    });
  };
  const getBillingCycle = () => {
    api.get(`/superadmin/plan/billing_cycle_listing/`).then((response) => {
      if (response.status_code === 200) {
        setBillingCycleData(response.data);
      } else {
      }
    });
  };
  const getPriceModule = () => {
    api.get(`/superadmin/plan/price_module_listing`).then((response) => {
      if (response.status_code === 200) {
        setPriceModuleData(response.data);
      } else {
      }
    });
  };

  const cancel = () => {
    navigate("/plan");
  };






  const onSubmit = (values) => {
    setLoader(true)
    const { productFamily,
      planExternal,
      planInternal,
      pricingModule,
      price,
      billingCycle,
      frequency,
      status,
      dec } = values;
    let formData = new FormData();
    formData.append("plan_price_map_id", PlanId ? PlanId : 0);
    formData.append("product_id", productFamily);
    formData.append("external_name", planExternal);
    formData.append("internal_name", planInternal);
    formData.append("price_module_id", pricingModule);
    formData.append("amount", price);
    formData.append("billing_cycle_id", billingCycle);
    formData.append("plan_frequency", frequency);
    formData.append("status", status);
    formData.append("plan_description", content);
    {
      (PlanId
        ? api.put(`/superadmin/plan/update/`, formData)
        : api.post(`/superadmin/plan/create/`, formData)
      ).then(function (response) {
        if (response.status_code === 200) {
          navigate("/plan");
          setLoader(false)
        } else if (response.status_code === 400) {
          Swal.fire({
            position: 'top-end',
            title: response.message ? response.message : 'Fill all *Required field',
            showConfirmButton: false,
            backdrop: false,
            timer: 3000
          })
          setLoader(false)
        }
      });
    }

  };
  return (
    <>
      <DefaultPage />
      <div className="page-wrapper supar-admin-page-wrapper">
        <div className="content container-fluid">
          <Formik
            initialValues={formValue}
            validationSchema={planSchema}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {({ errors, values, touched, handleChange, handleBlur }) => (
              <Form autocomplete="off">
                <div className="card">
                  <AddUpdateCardHeader PageName='Plan' icon='pl' PageIcon='plan' cancel={cancel} PageId={PlanId} />
                </div>
                <div className="card">
                  <div className="card-header py-2">
                    <h4 className="card-title mb-0">Basic Information</h4>
                  </div>
                  <div className="card-body">
                    <div className="card-form-row">
                      <div className="column-swa">
                        <div className="form-group">
                          <label className="col-form-label">Product Family<sup className="text-danger">*</sup></label>
                          <select
                            name="productFamily"
                            value={values.productFamily || ""}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            className={`form-control ${(touched.productFamily && errors.productFamily)
                              ? "error-input"
                              : ""
                              }`}
                          >
                            <option value="">Select Family<sup className="text-danger">*</sup></option>
                            {productData
                              ? productData.map((data) => (
                                <option value={data.product_id}>
                                  {data.name}
                                </option>
                              ))
                              : ""}
                          </select>
                          <span className="input-error">
                            {touched.productFamily && errors.productFamily ? errors.productFamily : null}
                          </span>
                        </div>
                      </div>
                      <div className="column-swa">
                        <div className="form-group">
                          <label className="col-form-label">
                            Plan External Name<sup className="text-danger">*</sup>
                          </label>
                          <input
                            name="planExternal"
                            value={values.planExternal || ""}
                            onBlur={handleBlur}
                            onChange={handleChange}
                           // placeholder="Name"
                            className={`form-control ${(touched.planExternal && errors.planExternal)
                              ? "error-input"
                              : ""
                              }`}
                            type="text"
                            autocomplete="none"
                          />
                          <span className="input-error">
                            {touched.planExternal && errors.planExternal ? errors.planExternal : null}
                          </span>
                        </div>
                      </div>
                      <div className="column-swa">
                        <div className="form-group">
                          <label className="col-form-label">
                            Plan Internal Name<sup className="text-danger">*</sup>
                          </label>
                          <input
                            name="planInternal"
                            value={values.planInternal || ""}
                            onBlur={handleBlur}
                            onChange={handleChange}
                           // placeholder="Name"
                            className={`form-control ${(touched.planInternal && errors.planInternal)
                              ? "error-input"
                              : ""
                              }`}
                            type="text"
                            autocomplete="none"
                          />
                          <span className="input-error">
                            {touched.planInternal && errors.planInternal ? errors.planInternal : null}
                          </span>
                        </div>
                      </div>
                      <div className="column-swa">
                        <div className="form-group">
                          <label className="col-form-label">Pricing Module<sup className="text-danger">*</sup></label>
                          <select
                            name="pricingModule"
                            value={values.pricingModule || ""}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            className={`form-control ${(touched.pricingModule && errors.pricingModule)
                              ? "error-input"
                              : ""
                              }`}
                          >
                            <option>Select Price Module<sup className="text-danger">*</sup></option>

                            {priceModuleData
                              ? priceModuleData.map((data) => (
                                <option value={data.price_module_id}>
                                  {data.price_module_name}
                                </option>
                              ))
                              : ""}
                          </select>
                          <span className="input-error">
                            {touched.pricingModule && errors.pricingModule ? errors.pricingModule : null}
                          </span>
                        </div>
                      </div>
                      <div className="column-swa">
                        <div className="form-group">
                          <label className="col-form-label">Price<sup className="text-danger">*</sup></label>
                          <input
                            name="price"
                            value={values.price || ""}
                            onBlur={handleBlur}
                            onChange={handleChange}
                           // placeholder="Price"
                            className={`form-control ${(touched.price && errors.price)
                              ? "error-input"
                              : ""
                              }`}
                            type="text"
                            autocomplete="none"
                          />
                          <span className="input-error">
                            {touched.price && errors.price ? errors.price : null}
                          </span>
                        </div>
                      </div>
                      <div className="column-swa">
                        <div className="form-group">
                          <label className="col-form-label">Billing Cycle<sup className="text-danger">*</sup></label>
                          <select
                            name="billingCycle"
                            value={values.billingCycle || ""}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            className={`form-control ${(touched.billingCycle && errors.billingCycle)
                              ? "error-input"
                              : ""
                              }`}
                          >
                            <option>Select Type</option>

                            {billingCycleData
                              ? billingCycleData.map((data) => (
                                <option value={data.billing_cycle_id}>
                                  {data.billing_cycle_name}
                                </option>
                              ))
                              : ""}
                          </select>
                          <span className="input-error">
                            {touched.billingCycle && errors.billingCycle ? errors.billingCycle : null}
                          </span>
                        </div>
                      </div>
                      <div className="column-swa">
                        <div className="form-group">
                          <label className="col-form-label">Frequency<sup className="text-danger">*</sup></label>
                          <select
                            name="frequency"
                            value={values.frequency || ""}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            className={`form-control ${(touched.frequency && errors.frequency)
                              ? "error-input"
                              : ""
                              }`}
                          >
                            <option>Select Type</option>
                            {frequencyData
                              ? frequencyData.map((data) => (
                                <option value={data.plan_frequency_id}>
                                  {data.plan_frequency_name}
                                </option>
                              ))
                              : ""}

                          </select>
                          <span className="input-error">
                            {touched.frequency && errors.frequency ? errors.frequency : null}
                          </span>
                        </div>
                      </div>
                      <div className="column-swa">
                        <div className="form-group">
                          <label className="col-form-label">Status<sup className="text-danger">*</sup></label>
                          <select
                            value={values.status || ""}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            name="status"
                            className={`form-control ${(touched.status && errors.status)
                              ? "error-input"
                              : ""
                              }`}
                          >
                            <option>Select Status</option>
                            <option value="A">Active</option>
                            <option value="I">Inactive</option>
                          </select>
                          <span className="input-error">
                            {touched.status && errors.status ? errors.status : null}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="editor-box" style={{ minHeight: '200px' }}  >

                      <label className="col-form-label">Description</label>
                      <ReactQuill theme="snow" value={content} onChange={setContent} style={{ height: '110px' }} />


                    </div>
                  </div>
                </div>
                <AddUpdateCardFooter cancel={cancel} PageId={PlanId} />
              </Form>
            )}
          </Formik>



        </div>
      </div>
      {loader && <Loader />}
    </>
  );
};

export default PlanAddEdit;
